import React, { useMemo } from 'react';
import { Box, Button, Card, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../app/router';
import { AppActionTypes, AppContext, QuoteContext } from '@coverright/shared/contexts';
import { useMedicarePlansAggregationByTypes } from '@coverright/data-access/medicare';
import { getAgeByBirthdate, getQuoteId, getStoredUser, isPersonEligible } from '@coverright/utils';
import { MedicarePlanType, MedicareQuoteFilterExtraBenefit } from '@coverright/data-access/types/medicare';
import { useMedigapPremiumRanges } from '@coverright/data-access/medigap';
import { CsgGender, MedigapPlanName } from '@coverright/data-access/types/medigap';
import {
  calculateRecommended,
  PlanType,
  calculateExplanations,
  planNameByType,
  ExplanationMessage
} from '../../components/quiz/utils';
import SearchLoader from '../../components/animations/SearchLoader';
import QuizResultsPresenter from '../../components/quiz/quiz-results-presenter';
import { getQuizPlanData, PlanData } from '../../components/quiz/quiz-results-plan-data';
import { ArrowLeft, Check, X } from '@phosphor-icons/react';
import * as _ from 'lodash';
import { TakeQuizButton } from '../../features';
import { SideLayout, useAppSelector } from '../../shared';
import {medicareQuote} from "../../entities";
import {useSaveMedicareQuoteMutation} from "../../shared/api/medicare";
import {routes} from "../../app/router-new";
import {profile as profileSelector} from "../../entities/profile";

export default function QuizResults() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const quote = useAppSelector(medicareQuote);
  const profile = useAppSelector(profileSelector);
  const [save, { isLoading }] = useSaveMedicareQuoteMutation()
  const [{state}, dispatch] = React.useContext(AppContext);
  const medicarePlanData = useMedicarePlansAggregationByTypes([MedicarePlanType.MmpHmo, MedicarePlanType. Ppo,MedicarePlanType.Hmo], profile?.zip || undefined, profile?.countyName || undefined);
  const [getMedigapPremiumRanges, {data: medigapPremiumRangesData}] = useMedigapPremiumRanges();

  const [showRecommendedOnly, setShowRecommendedOnly] = React.useState(true);
  const [OMCompare, setOMCompare] = React.useState(false);

  const [currentPlans, setCurrentPlans] = React.useState<PlanData[]>([]);

  const customAnswers = React.useMemo(() => {
    return quote?.customAnswers ? JSON.parse(quote?.customAnswers) : {};
  }, [quote])

  const medigapPremiumRanges = React.useMemo(() => {
    if (medigapPremiumRangesData?.medigapPremiumRanges) {
      return medigapPremiumRangesData?.medigapPremiumRanges
        .filter(r => [MedigapPlanName.F, MedigapPlanName.G, MedigapPlanName.N, MedigapPlanName.Hdg, MedigapPlanName.Hdf].includes(r.planName))
        .filter(r => isPersonEligible() ? true : ![MedigapPlanName.F, MedigapPlanName.C, MedigapPlanName.Hdf].includes(r.planName))
    }
    return []
  }, [medigapPremiumRangesData]);

  const plansData = React.useMemo(() => {
    return {medicarePlanData, medigapPremiumRanges}
  }, [medicarePlanData, medigapPremiumRanges]);

  React.useEffect(() => {
    if (!!medicarePlanData && !!medigapPremiumRanges)
      setLoading(false)
  }, [medicarePlanData, medigapPremiumRanges]);

  React.useEffect(() => {
    if (getStoredUser()?.zip) {
      getMedigapPremiumRanges({
        variables: {
          zip: getStoredUser()?.zip as string,
          age: getAgeByBirthdate(getStoredUser()?.birthDate),
          tobacco: getStoredUser()?.tobacco === 'true',
          gender: getStoredUser()?.gender as CsgGender,
        }
      });
    }
  }, [getStoredUser()?.zip]);

  const plans = React.useMemo(() => {
    if (plansData.medicarePlanData && plansData.medigapPremiumRanges) {
      const recommended = calculateRecommended(customAnswers?.prepay?.value, customAnswers?.networkFlexibility?.value, customAnswers?.extraBenefits?.value, customAnswers?.receiveMedicaid?.value, plansData)[0]
      return recommended.map(type => getQuizPlanData(type, plansData))
    }
    return undefined
  }, [customAnswers, plansData]);

  React.useEffect(() => {
    if (plans) {
      setCurrentPlans(showRecommendedOnly ? [plans[0]] : plans)
    }
  }, [plans, showRecommendedOnly])

  const onSelectClick = (type: PlanType) => {
    const isMG = [PlanType.MG, PlanType.HDMG].includes(type);

    dispatch({
      type: AppActionTypes.SAVE_STATE,
      payload:[{
        stateKey: 'marketplaceRedirect',
        value: isMG ? 'MG' : 'MA',
      }, {
        stateKey: 'quizSelected',
        value: type
      }],
    });
    if (isMG) {
      navigate(routes.planFinderMG.value);
    } else {
      const planTypes: any = [];
      const SNPTypes: any = [];
      if (type === PlanType.DSNP || type === PlanType.MMP) {
        SNPTypes.push('D_SNP');
      } else if (type === PlanType.PPO) {
        planTypes.push('PPO');
      } else if (type === PlanType.HMO) {
        planTypes.push('HMO');
      }

      save({
        data: {
          customAnswers: JSON.stringify({...customAnswers, selectedPlan: {
              value: type,
              answerText: planNameByType(type),
              questionText: 'Selected plan',
            }
          }),
          id: getQuoteId(),
          zip: getStoredUser()?.zip,
          county: getStoredUser()?.countyName,
          filters: {
            extraBenefits: _.uniq([MedicareQuoteFilterExtraBenefit.DrugCoverage, ...(quote?.filters?.extraBenefits || [])]),
            planTypes,
            SNPTypes,
          }
        },
      }).then(() => navigate(routes.planFinderMA.value));
    }
  }


  React.useEffect(() => {
   dispatch({
      type: AppActionTypes.SAVE_STATE,
      payload:[{
        stateKey: 'quizCompleted',
        value: true,
      }],
    });
  }, [dispatch]);

  const onActionButtonClick = React.useCallback((plan: PlanType) => {
    if (showRecommendedOnly) {
      setShowRecommendedOnly(false)
    } else {
      setCurrentPlans(plans!.filter(p => p.type === plan || p.type === PlanType.OM))
      setOMCompare(true)
    }
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [showRecommendedOnly, plans])

  const actionButtonLabel = React.useMemo(() => {
    if (showRecommendedOnly) {
      return 'See other coverage options'
    }
    return 'Compare with Original Medicare Plan'
  }, [showRecommendedOnly])

  const title = React.useMemo(() => {
    if (loading) {
      return `Thanks, ${getStoredUser()?.firstName}! Sit tight.`
    }
    if (showRecommendedOnly) {
      return `${getStoredUser()?.firstName}, You would be best suited to`
    }
    return 'Other coverage options'
  }, [showRecommendedOnly, loading])

  const hereIsWhy = React.useMemo(() => {
    return <Stack spacing={2}>
      {calculateExplanations(customAnswers?.prepay?.value, customAnswers?.networkFlexibility?.value, customAnswers?.extraBenefits?.value, customAnswers?.receiveMedicaid?.value, plansData)
        .map((v, i) => (
          <HereIsWhy key={i} message={<ExplanationMessage exp={v[1]} />} value={v[0]}/>
        ))}
    </Stack>
  }, [customAnswers, plansData])

  const subtitle = useMemo(() => {
    if (loading) {
      return <Typography>We’re preparing your recommendation...</Typography>
    }
    return OMCompare ? (
      <BackButton onClick={() => {
        setCurrentPlans(plans!)
        setOMCompare(false)
      }} />
    ) : <TakeQuizButton />
  }, [loading, OMCompare]);

  return <SideLayout title={title}
                     subtitle={subtitle}>
    <Card>
      {loading && <SearchLoader width={200} height={200}/>}
      {(!loading && plans) && <QuizResultsPresenter plans={currentPlans}
                                                    actionButtonLabel={actionButtonLabel}
                                                    onActionButtonClick={onActionButtonClick}
                                                    onSelectClick={onSelectClick}
                                                    OMCompare={OMCompare}
                                                    hereIsWhy={hereIsWhy}
                                                    redirecting={isLoading}
      />}
    </Card>
  </SideLayout>;
}

const BackButton = ({onClick}: {onClick: () => void}) => (
  <Button onClick={onClick}
          variant={'text'}
          size={'small'}
          startIcon={<ArrowLeft size={16} color="#1C434F" weight="regular" />}
          sx={{ color: '#1C434F', cursor: 'pointer', width: 'fit-content', fontSize: 18 }}>
    Back
  </Button>
)

const HereIsWhy = ({ message, value }: { message: JSX.Element, value: boolean }) => <Box gap={'10px'} display={'flex'} alignItems={'flex-start'}>
  <Box sx={{mt: '4px'}}>
    {value && <Check size={20} color="#0B741C" weight="regular" />}
    {!value && <X size={20} color="#D32C1C" weight="regular" />}
  </Box>
  <div className={'fill-width'}>
    {message}
  </div>
</Box>;



