import { IPlansData, PlanType } from './utils';
import React from 'react';
import {
  getNumbersFromString,
  GetPlansYear,
  getStoredUser,
  isPersonEligible, showRange,
  toCurrency,
  tooltips
} from '@coverright/utils';
import { Box, Typography } from '@mui/material';
import { CRTooltip } from '@coverright/ui/inputs';
import { MedigapPlanName } from '@coverright/data-access/types/medigap';
import { getDeductible } from '@coverright/shared/config';
import * as _ from 'lodash';
import { pdpPlanAggregation, percentOfZeroPremiumMedicarePlans } from '@coverright/data-access/medicare';
import { MedicarePlanType } from '@coverright/data-access/types/medicare';

interface PlanCoverage {
  value: boolean,
  title: string,
  subtitle?: string,
  tooltip: string | JSX.Element,
}

interface OOPCost {
  value: JSX.Element | string | (() => Promise<string | JSX.Element>),
  title: string,
  tooltip: string | JSX.Element,
}

export interface PlanData {
  whatIsIt: string | JSX.Element,
  popularity: string | JSX.Element,
  type: PlanType,
  name: string,
  monthlyPremium: string,
  prescriptionDrugCoveragePremium: JSX.Element | (() => Promise<JSX.Element>),
  zeroPercent?: () => Promise<JSX.Element>,
  coverage: PlanCoverage[],
  OOPCosts: OOPCost[],
}

export const getQuizPlanData = (type: PlanType, input: IPlansData): PlanData => {

  const getData = (type: PlanType, data: IPlansData): any => {
    switch (type) {
      case PlanType.HDMG: return data.medigapPremiumRanges.filter(p => [MedigapPlanName.Hdf, MedigapPlanName.Hdg].includes(p.planName));
      case PlanType.MG: return data.medigapPremiumRanges.filter(p => [MedigapPlanName.F, MedigapPlanName.G, MedigapPlanName.N].includes(p.planName));
      case PlanType.HMO: return data.medicarePlanData.HMO;
      case PlanType.PPO: return data.medicarePlanData.PPO;
      case PlanType.MMP: return data.medicarePlanData.MMP_HMO;
      case PlanType.DSNP: return (data.medicarePlanData as any).DSNP;
      default: return {};
    }
  }

  const data = getData(type, input);

  const plans: PlanData[] = [
    {
      type: PlanType.HMO,
      name: 'Medicare Advantage HMO',
      whatIsIt: 'Medicare Advantage plans are “all-in-one” bundled private alternative that covers the same services as Original Medicare and extra benefits (such as dental, vision and hearing).',
      popularity: <><span className={'semi-bold'}>65%</span> of people who select a private plan choose Medicare Advantage. <span className={'semi-bold'}>60%</span> of people who choose Medicare Advantage pick HMO.</>,
      coverage: [
        {
          value: true,
          title: 'Hospital (Part A)',
          tooltip: tooltips.partAMA,
        },
        {
          value: true,
          title: 'Medical (Part B)',
          tooltip: tooltips.partBMA,
        },
        {
          value: true,
          title: 'Prescription Drugs (Part D)',
          subtitle: 'Included with plan',
          tooltip: tooltips.pdpMA,
        },
        {
          value: true,
          title: 'Extra benefits',
          subtitle: 'Such as dental, vision or hearing',
          tooltip: tooltips.extraBenefitsMA,
        },
        {
          value: false,
          title: 'Use of any doctor or hospital that accepts Medicare',
          tooltip: tooltips.useAnyDoctorMA,
        },
      ],
      OOPCosts: [
        {
          title: 'Doctor visits',
          value: <>
            <Typography>{showRange(data?.doctorInNetworkMin, data?.doctorInNetworkMax, ' copay per visit')}</Typography>
            <Typography className={'fs-14'} sx={{color: '#666', mb: .5}}>in-network</Typography>
            <Typography>N/A</Typography>
            <Typography className={'fs-14'} sx={{color: '#666'}}>out-of-network not covered</Typography>
          </>,
          tooltip: tooltips.doctorVisitsMA,
        },
        {
          title: 'Short-term hospital stay',
          value: <Box sx={{display: 'flex'}}>
            <div>
              <Typography>{data?.shortTermHospitalStayMin || 'N/A'} per day</Typography>
              <CRTooltip arrow title={tooltips.MADays}>
                <Typography sx={{color: '#666'}} className={'underline fs-14'}>first {data?.shortTermHospitalStayMinDays || 'N/A'} days</Typography>
              </CRTooltip>
            </div>
            <div className={'mh-5'}>
              <Typography>to</Typography>
            </div>
            <div>
              <Typography>{data?.shortTermHospitalStayMax || 'N/A'} per day</Typography>
              <CRTooltip arrow title={tooltips.MADays}>
                <Typography sx={{color: '#666'}} className={'underline fs-14'} >first {data?.shortTermHospitalStayMaxDays || 'N/A'} days</Typography>
              </CRTooltip>
            </div>
          </Box>,
          tooltip: tooltips.STHospitalMA,
        },
        {
          title: 'Long-term hospital stay',
          value: <div>
            <Typography>$0 per day</Typography>
            <Typography className={'fs-14'} sx={{color: '#666'}}>Up to 90 days (after short term hospital copays)</Typography>
          </div>,
          tooltip: tooltips.LTHospitalMA,
        },
        {
          title: 'Deductible',
          value: showRange(data.deductibleMin, data.deductibleMax),
          tooltip: tooltips.deductibleMA,
        },
        {
          title: 'Maximum Out-of-pocket',
          value: <div>
            <Typography>{showRange(data.maxOutOfPocketInNetworkMin, data.maxOutOfPocketInNetworkMax)} <span className={'fs-14 lh-17'}>in-network</span></Typography>
            <Typography className={'fs-14'} sx={{color: '#666'}}>Your plans pays 100% of in-network costs after the limit. You must continue to pay your plan premium (if applicable).</Typography>
          </div>,
          tooltip: tooltips.maxOutOfPocket,
        },
      ],
      prescriptionDrugCoveragePremium: <>
        <Typography className={'fs-24 semi-bold'}>$0</Typography>
        <Typography className={'mt-4'}>included with plan</Typography>
      </>,
      monthlyPremium: showRange(data.monthlyPremiumMin, data.monthlyPremiumMax),
      zeroPercent: () => new Promise(resolve => {
        percentOfZeroPremiumMedicarePlans({
          variables: {
            zip: getStoredUser()?.zip as string,
            county: getStoredUser()?.countyName as string,
            planTypes: [MedicarePlanType.Hmo]
          }
        }).then(res => {
          resolve(<Typography sx={{color: '#666'}} className={'fs-12'}>{res}</Typography>)
        })
      })
    },
    {
      type: PlanType.PPO,
      name: 'Medicare Advantage PPO',
      whatIsIt: 'Medicare Advantage plans are “all-in-one” bundled private alternative that covers the same services as Original Medicare and extra benefits (such as dental, vision and hearing).',
      popularity: <><span className={'semi-bold'}>65%</span> of people who select a private plan choose Medicare Advantage. <span className={'semi-bold'}>38%</span> of people who choose Medicare Advantage pick PPO.</>,
      coverage: [
        {
          value: true,
          title: 'Hospital (Part A)',
          tooltip: tooltips.partAMA,
        },
        {
          value: true,
          title: 'Medical (Part B)',
          tooltip: tooltips.partBMA,
        },
        {
          value: true,
          title: 'Prescription Drugs (Part D)',
          subtitle: 'Included with plan',
          tooltip: tooltips.pdpMA,
        },
        {
          value: true,
          title: 'Extra benefits',
          subtitle: 'Such as dental, vision or hearing',
          tooltip: tooltips.extraBenefitsMA,
        },
        {
          value: true,
          title: 'Use of any doctor or hospital that accepts Medicare',
          tooltip: tooltips.useAnyDoctorMA,
        },
      ],
      OOPCosts: [
        {
          title: 'Doctor visits',
          value: <>
            <Typography>{showRange(data?.doctorInNetworkMin, data?.doctorInNetworkMax, ' copay per visit')}</Typography>
            <Typography className={'fs-14'} sx={{color: '#666', mb: .5}}>in-network</Typography>
            <Typography>{showRange(data.doctorOutOfNetworkMin, data.doctorOutOfNetworkMax, ' copay per visit')}</Typography>
            <Typography className={'fs-14'} sx={{color: '#666'}}>out-of-network</Typography>
          </>,
          tooltip: tooltips.doctorVisitsMA,
        },
        {
          title: 'Short-term hospital stay',
          value: <Box sx={{display: 'flex'}}>
            <div>
              <Typography>{data?.shortTermHospitalStayMin || 'N/A'} per day</Typography>
              <CRTooltip arrow title={tooltips.MADays}>
                <Typography sx={{color: '#666'}} className={'underline fs-14'}>first {data?.shortTermHospitalStayMinDays || 'N/A'} days</Typography>
              </CRTooltip>
            </div>
            <div className={'mh-5'}>
              <Typography>to</Typography>
            </div>
            <div>
              <Typography>{data?.shortTermHospitalStayMax || 'N/A'} per day</Typography>
              <CRTooltip arrow title={tooltips.MADays}>
                <Typography sx={{color: '#666'}} className={'underline fs-14'} >first {data?.shortTermHospitalStayMaxDays || 'N/A'} days</Typography>
              </CRTooltip>
            </div>
          </Box>,
          tooltip: tooltips.STHospitalMA,
        },
        {
          title: 'Long-term hospital stay',
          value: <div>
            <Typography>{data.longTermHospitalStayMin || 'N/A'} per day </Typography>
            <Typography className={'fs-14'} sx={{color: '#666'}}>Up to 90 days (after short term hospital copays)</Typography>
          </div>,
          tooltip: tooltips.LTHospitalMA,
        },
        {
          title: 'Deductible',
          value: showRange(data.deductibleMin, data.deductibleMax),
          tooltip: tooltips.deductibleMA,
        },
        {
          title: 'Maximum Out-of-pocket',
          value: <div>
            <Typography>{showRange(data.maxOutOfPocketInNetworkMin, data.maxOutOfPocketInNetworkMax)} <span className={'fs-14 lh-17'}>in-network</span></Typography>
            <Typography>{showRange(data.maxOutOfPocketOutOfNetworkMin, data.maxOutOfPocketOutOfNetworkMax)} <span className={'fs-14 lh-17'}>in-network and out-of-network</span></Typography>
            <Typography className={'fs-14'} sx={{color: '#666'}}>Your plans pays 100% of in-network and out-of-network costs after the respective limits. You must continue to pay your plan premium (if applicable).</Typography>
          </div>,
          tooltip: tooltips.maxOutOfPocket,
        },
      ],
      prescriptionDrugCoveragePremium: <>
        <Typography className={'fs-24 semi-bold'}>$0</Typography>
        <Typography className={'mt-4'}>included with plan</Typography>
      </>,
      monthlyPremium: showRange(data.monthlyPremiumMin, data.monthlyPremiumMax),
      zeroPercent: () => new Promise(resolve => {
        percentOfZeroPremiumMedicarePlans({
          variables: {
            zip: getStoredUser()?.zip as string,
            county: getStoredUser()?.countyName as string,
            planTypes: [MedicarePlanType.Ppo]
          }
        }).then(res => {
          resolve(<Typography sx={{color: '#666'}} className={'fs-12'}>{res}</Typography>)
        })
      })
    },
    {
      type: PlanType.DSNP,
      name: 'Medicare Advantage D-SNP',
      whatIsIt: 'A Dual-Special Needs Plan (D-SNP) is a special plan designed for Medicare-Medicaid ‘dual-eligible’ beneficiaries.',
      popularity: <><span className={'semi-bold'}>79%</span> of all people who are ‘dual-eligible’ enroll in a Medicare Advantage plan that is tailored for dual-eligibles such as a D-SNP.</>,
      coverage: [
        {
          value: true,
          title: 'Hospital (Part A)',
          tooltip: tooltips.partAMA,
        },
        {
          value: true,
          title: 'Medical (Part B)',
          tooltip: tooltips.partBMA,
        },
        {
          value: true,
          title: 'Prescription Drugs (Part D)',
          subtitle: 'Included with plan',
          tooltip: tooltips.pdpMA,
        },
        {
          value: true,
          title: 'Extra benefits',
          subtitle: 'Such as dental, vision or hearing',
          tooltip: tooltips.extraBenefitsMA,
        },
        {
          value: false,
          title: 'Use of any doctor or hospital that accepts Medicare',
          tooltip: tooltips.useAnyDoctorMA,
        },
      ],
      OOPCosts: [
        {
          title: 'Doctor visits',
          value: <>
            <Typography>{showRange(data.doctorInNetworkMin, data.doctorInNetworkMin, ' copay per visit', '$0 copay per visit')}</Typography>
            <Typography className={'fs-14'} sx={{color: '#666', mb: .5}}>in-network</Typography>
            <Typography>N/A</Typography>
            <Typography className={'fs-14'} sx={{color: '#666'}}>out-of-network</Typography>
          </>,
          tooltip: tooltips.doctorVisitsMA,
        },
        {
          title: 'Short-term hospital stay',
          value: <Typography>{showRange(data.shortTermHospitalStayMin, '', ' copay', '$0 copay')}</Typography>,
          tooltip: tooltips.STHospitalMA,
        },
        {
          title: 'Long-term hospital stay',
          value: <Typography>{showRange(data.longTermHospitalStayMin, '', ' copay', '$0 copay')}</Typography>,
          tooltip: tooltips.LTHospitalMA,
        },
        {
          title: 'Deductible',
          value: '$0',
          tooltip: tooltips.deductibleMA,
        },
        {
          title: 'Maximum Out-of-pocket',
          value: <div>
            <Typography>{showRange(data.maxOutOfPocketInNetworkMin, data.maxOutOfPocketInNetworkMax, '', '$0')} <span className={'fs-14 lh-17'}>in-network</span></Typography>
            <Typography className={'fs-14'} sx={{color: '#666'}}>Very few people will ever reach this out-of-pocket maximum as Medicaid helps to pay for most services.</Typography>
          </div>,
          tooltip: tooltips.maxOutOfPocket,
        },
      ],
      prescriptionDrugCoveragePremium: <>
        <Typography className={'fs-24 semi-bold'}>$0</Typography>
        <Typography className={'mt-4'}>included with plan</Typography>
      </>,
      monthlyPremium: showRange(data.monthlyPremiumMin, data.monthlyPremiumMax, '', '$0'),
    },
    {
      type: PlanType.MMP,
      name: 'Medicare Advantage MMP',
      whatIsIt: 'A Medicare-Medicaid Plan (MMP) is a special plan designed for ‘full’ Medicare-Medicaid ‘dual-eligible’ beneficiaries only.',
      popularity: <><span className={'semi-bold'}>79%</span> of all people who are ‘dual-eligible’ enroll in a Medicare Advantage plan that is tailored for dual-eligibles such as a D-SNP.</>,
      coverage: [
        {
          value: true,
          title: 'Hospital (Part A)',
          tooltip: tooltips.partAMA,
        },
        {
          value: true,
          title: 'Medical (Part B)',
          tooltip: tooltips.partBMA,
        },
        {
          value: true,
          title: 'Prescription Drugs (Part D)',
          subtitle: 'Included with plan',
          tooltip: tooltips.pdpMA,
        },
        {
          value: true,
          title: 'Extra benefits',
          subtitle: 'Such as dental, vision or hearing',
          tooltip: tooltips.extraBenefitsMA,
        },
        {
          value: false,
          title: 'Use of any doctor or hospital that accepts Medicare',
          tooltip: tooltips.useAnyDoctorMA,
        },
      ],
      OOPCosts: [
        {
          title: 'Doctor visits',
          value: <>
            <Typography>{showRange(data.doctorInNetworkMin, data.doctorInNetworkMin, ' copay per visit', '$0 copay per visit')}</Typography>
            <Typography className={'fs-14'} sx={{color: '#666', mb: .5}}>in-network</Typography>
            <Typography>N/A</Typography>
            <Typography className={'fs-14'} sx={{color: '#666'}}>out-of-network</Typography>
          </>,
          tooltip: tooltips.doctorVisitsMA,
        },
        {
          title: 'Short-term hospital stay',
          value: <Typography>{showRange(data.shortTermHospitalStayMin, '', ' copay', '$0 copay')}</Typography>,
          tooltip: tooltips.STHospitalMA,
        },
        {
          title: 'Long-term hospital stay',
          value: <Typography>{showRange(data.longTermHospitalStayMin, '', ' copay', '$0 copay')}</Typography>,
          tooltip: tooltips.LTHospitalMA,
        },
        {
          title: 'Deductible',
          value: '$0',
          tooltip: tooltips.deductibleMA,
        },
        {
          title: 'Maximum Out-of-pocket',
          value: <div>
            <Typography>{showRange(data.maxOutOfPocketInNetworkMin, data.maxOutOfPocketInNetworkMax, '', '$0')} <span className={'fs-14 lh-17'}>in-network</span></Typography>
            <Typography className={'fs-14'} sx={{color: '#666'}}>Very few people will ever reach this out-of-pocket maximum as Medicaid helps to pay for most services.</Typography>
          </div>,
          tooltip: tooltips.maxOutOfPocket,
        },
      ],
      prescriptionDrugCoveragePremium: <>
        <Typography className={'fs-24 semi-bold'}>$0</Typography>
        <Typography className={'mt-4'}>included with plan</Typography>
      </>,
      monthlyPremium: showRange(data.monthlyPremiumMin, data.monthlyPremiumMax, '', '$0'),
    },
    {
      type: PlanType.MG,
      name: 'Medicare Supplement',
      whatIsIt: 'Medicare Supplement (or ‘Medigap’) plans are private insurance plans that works alongside default Original Medicare to help cover the out-of-pocket costs (or “gaps”) you’re responsible for.',
      popularity: <><span className={'semi-bold'}>35%</span> all people who select a private plan choose Medicare Supplement instead of Medicare Advantage.</>,
      coverage: [
        {
          value: true,
          title: 'Hospital (Part A)',
          tooltip: tooltips.partAMG,
        },
        {
          value: true,
          title: 'Medical (Part B)',
          tooltip: tooltips.partBMG,
        },
        {
          value: true,
          title: 'Prescription Drugs (Part D)',
          subtitle: 'Included with plan',
          tooltip: tooltips.pdpMG,
        },
        {
          value: false,
          title: 'Extra benefits',
          subtitle: 'Such as dental, vision or hearing',
          tooltip: tooltips.extraBenefitsMG,
        },
        {
          value: true,
          title: 'Use of any doctor or hospital that accepts Medicare',
          tooltip: tooltips.useAnyDoctorMG,
        },
      ],
      OOPCosts: [
        {
          title: 'Doctor visits',
          value: <>
            <Typography>$0 copay per visit</Typography>
            <Typography className={'fs-14'} sx={{color: '#666', mb: .5}}>Any doctor that accepts Medicare</Typography>
          </>,
          tooltip: tooltips.doctorVisitsMG,
        },
        {
          title: 'Short-term hospital stay',
          value: <>
            <Typography>$0 copay</Typography>
            <Typography className={'fs-14'} sx={{color: '#666', mb: .5}}>first 60 days</Typography>
          </>,
          tooltip: tooltips.STHospitalMG,
        },
        {
          title: 'Long-term hospital stay',
          value: <>
            <Typography>$0 copay</Typography>
            <Typography className={'fs-14'} sx={{color: '#666', mb: .5}}>for days 61-90</Typography>
          </>,
          tooltip: tooltips.LTHospitalMG,
        },
        {
          title: 'Deductible',
          value: (isPersonEligible() ? '$0 - ' : '') + getDeductible('annualPartBDeductible'),
          tooltip: tooltips.deductibleMG,
        },
        {
          title: 'Maximum Out-of-pocket',
          value: () => new Promise(resolve => {
            const minPremium = Math.round(_.min(_.flatten(data.map((v: any) => getNumbersFromString(v.rangeDescription)))) as number);
            const maxPremium = Math.round(_.max(_.flatten(data.map((v: any) => getNumbersFromString(v.rangeDescription)))) as number);

            resolve(<div>
              <Typography>{showRange(toCurrency(minPremium * 12 + getNumbersFromString(getDeductible('annualPartBDeductible'))[0]), toCurrency(maxPremium * 12 + getNumbersFromString(getDeductible('annualPartBDeductible'))[0]))}</Typography>
              <Typography className={'fs-14'} sx={{color: '#666'}}>
                Your out-of-pocket costs in catastrophic<br/> scenario are:
                <br/>+ {getDeductible('annualPartBDeductible')} deductible
                <br/>+ {showRange(toCurrency(minPremium * 12), toCurrency(maxPremium * 12))} total annual Medicare Supplement premium
              </Typography>
            </div>)
          }),
          tooltip: tooltips.maxOOPMG,
        },
      ],
      prescriptionDrugCoveragePremium: prescriptionDrugCoveragePremiumMG,
      monthlyPremium: (function(){
        if (data?.map) {
          const minPremium = Math.round(_.min(_.flatten(data.map((v: any) => getNumbersFromString(v.rangeDescription)))) as number);
          const maxPremium = Math.round(_.max(_.flatten(data.map((v: any) => getNumbersFromString(v.rangeDescription)))) as number);

          return showRange(toCurrency(minPremium), toCurrency(maxPremium))
        }
        return '';
      })(),
    },
    {
      type: PlanType.HDMG,
      name: 'High Deductible Medicare Supplement',
      whatIsIt: 'Comprehensive catastrophic coverage with lower premiums than a regular Medicare Supplement plan (also called ‘Medigap’).',
      popularity: <><span className={'semi-bold'}>35%</span> all people who select a private plan choose Medicare Supplement instead of Medicare Advantage.</>,
      coverage: [
        {
          value: true,
          title: 'Hospital (Part A)',
          tooltip: tooltips.partAMG,
        },
        {
          value: true,
          title: 'Medical (Part B)',
          tooltip: tooltips.partBMG,
        },
        {
          value: true,
          title: 'Prescription Drugs (Part D)',
          subtitle: 'Included with plan',
          tooltip: tooltips.pdpMG,
        },
        {
          value: false,
          title: 'Extra benefits',
          subtitle: 'Such as dental, vision or hearing',
          tooltip: tooltips.extraBenefitsMG,
        },
        {
          value: true,
          title: 'Use of any doctor or hospital that accepts Medicare',
          tooltip: tooltips.useAnyDoctorMG,
        },
      ],
      OOPCosts: [
        {
          title: 'Doctor visits',
          value: <>
            <Typography>$0 copay per visit after deductible</Typography>
            <Typography className={'fs-14'} sx={{color: '#666', mb: .5}}>Any doctor that accepts Medicare</Typography>
          </>,
          tooltip: tooltips.doctorVisitsMG,
        },
        {
          title: 'Short-term hospital stay',
          value: <>
            <Typography>$0 copay after deductible</Typography>
            <Typography className={'fs-14'} sx={{color: '#666', mb: .5}}>first 60 days</Typography>
          </>,
          tooltip: tooltips.STHospitalMG,
        },
        {
          title: 'Long-term hospital stay',
          value: <>
            <Typography>$0 copay after deductible</Typography>
            <Typography className={'fs-14'} sx={{color: '#666', mb: .5}}>for days 61-90</Typography>
          </>,
          tooltip: tooltips.LTHospitalMG,
        },
        {
          title: 'Deductible',
          value: getDeductible('deductible'),
          tooltip: tooltips.deductibleMG,
        },
        {
          title: 'Maximum Out-of-pocket',
          value: () => new Promise(resolve => {
            const minPremium = Math.round(_.min(_.flatten(data.map((v: any) => getNumbersFromString(v.rangeDescription)))) as number);
            const maxPremium = Math.round(_.max(_.flatten(data.map((v: any) => getNumbersFromString(v.rangeDescription)))) as number);

            resolve(<div>
              <Typography>{showRange(toCurrency(minPremium * 12 + getNumbersFromString(getDeductible('deductible'))[0]), toCurrency(maxPremium * 12 + getNumbersFromString(getDeductible('deductible'))[0]))}</Typography>
              <Typography className={'fs-14'} sx={{color: '#666'}}>
                Your out-of-pocket costs in catastrophic<br/> scenario are:
                <br/>+ {getDeductible('deductible')} deductible
                <br/>+ {showRange(toCurrency(minPremium * 12), toCurrency(maxPremium * 12))} total annual Medicare Supplement premium
              </Typography>
            </div>)
          }),
          tooltip: tooltips.maxOOPMG,
        },
      ],
      prescriptionDrugCoveragePremium: prescriptionDrugCoveragePremiumMG,
      monthlyPremium: (function(){
        if (data?.map) {
          const minPremium = Math.round(_.min(_.flatten(data.map((v: any) => getNumbersFromString(v.rangeDescription)))) as number);
          const maxPremium = Math.round(_.max(_.flatten(data.map((v: any) => getNumbersFromString(v.rangeDescription)))) as number);

          return showRange(toCurrency(minPremium), toCurrency(maxPremium))
        }
        return '';
      })(),
    },
    {
      type: PlanType.OM,
      name: 'Original Medicare',
      whatIsIt: <>
        The default government program that provides basic health coverage.   You are typically responsible for 20% of all medical costs and there are no limits on the out-of-pocket costs you’re responsible for.<br/><br/>
        It’s typically recommended that you enroll in or additional private coverage to ensure you appropriately covered for health / drug costs.<br/><br/>
      </>,
      popularity: 'Only 1 out of 3 beneficiaries elect to stay in Original Medicare with no private coverage.',
      coverage: [
        {
          value: true,
          title: 'Hospital (Part A)',
          tooltip: tooltips.partAOriginal,
        },
        {
          value: true,
          title: 'Medical (Part B)',
          tooltip: tooltips.partBOriginal,
        },
        {
          value: false,
          title: 'Prescription Drugs (Part D)',
          subtitle: 'Can purchase at additional cost',
          tooltip: tooltips.pdpOriginal,
        },
        {
          value: false,
          title: 'Extra benefits',
          subtitle: 'Such as dental, vision or hearing',
          tooltip: tooltips.extraBenefitsOriginal,
        },
        {
          value: true,
          title: 'Use of any doctor or hospital that accepts Medicare',
          tooltip: tooltips.useAnyDoctorOriginal,
        },
      ],
      OOPCosts: [
        {
          title: 'Doctor visits',
          value: <>
            <Typography>20% of costs</Typography>
            <Typography className={'fs-14'} sx={{color: '#666', mb: .5}}>Any doctor that accepts Medicare</Typography>
          </>,
          tooltip: tooltips.doctorVisitsOriginal,
        },
        {
          title: 'Short-term hospital stay',
          value: <>
            <Typography>{getDeductible('inpatientHospitalDeductible')} copay</Typography>
            <Typography className={'fs-14'} sx={{color: '#666', mb: .5}}>covers first 60 days</Typography>
          </>,
          tooltip: tooltips.STHospitalOriginal,
        },
        {
          title: 'Long-term hospital stay',
          value: <>
            <Typography>{getDeductible('dailyCoinsurance61_90')} per day</Typography>
            <Typography className={'fs-14'} sx={{color: '#666', mb: .5}}>for days 61-90</Typography>
          </>,
          tooltip: tooltips.LTHospitalOriginal,
        },
        {
          title: 'Deductible',
          value: getDeductible('annualPartBDeductible'),
          tooltip: tooltips.deductibleMG,
        },
        {
          title: 'Maximum Out-of-pocket',
          value: <div>
            <Typography>Unlimited</Typography>
            <Typography className={'fs-14'} sx={{color: '#666'}}>
              Your out-of-pocket costs in catastrophic scenario are uncapped
            </Typography>
          </div>,
          tooltip: tooltips.maxOOPOriginal,
        },
      ],
      prescriptionDrugCoveragePremium: prescriptionDrugCoveragePremiumMG,
      monthlyPremium: 'N/A',
    },
  ];

  return plans.find(p => p.type === type)!;
}

const prescriptionDrugCoveragePremiumMG = () => new Promise<JSX.Element>(resolve => {
  pdpPlanAggregation({
    variables: {
      zip: getStoredUser()?.zip as string,
      county: getStoredUser()?.countyName as string,
      planYear: GetPlansYear(),
    }
  }).then(res => {
    resolve(<>
      <Typography className={'fs-24 semi-bold'}>{showRange(res?.monthlyPremiumMin ? toCurrency(res?.monthlyPremiumMin) : null, res?.monthlyPremiumMax ? toCurrency(res?.monthlyPremiumMax) : null)}</Typography>
      <Typography className={'mt-4 fs-14'}><span className={'medium'}>$48</span> on average</Typography>
    </>)
  })
})
