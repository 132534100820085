import { CRModal } from '@coverright/ui/shared';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { X } from '@phosphor-icons/react';
import * as React from 'react';
import { useCalendlyModal } from '@coverright/features';
import { useNavigate } from 'react-router';
import { useLogEvent } from '@coverright/shared/analytics';
import { AppContext } from '@coverright/shared/contexts';

interface BrowsingALotModalProps {
  open: boolean;
  onClose: () => void;
}

export default function BrowsingALotModal(props: BrowsingALotModalProps) {
  const openCalendly = useCalendlyModal();
  const navigate = useNavigate();
  const logEvent = useLogEvent();
  const [{ state }] = React.useContext(AppContext);

  React.useEffect(() => {
    if (props.open) {
      logEvent('modal_open', {
        element_title: 'Do you need help picking a plan?',
        element_id: 'browsing-a-lot-modal',
      });
    }
  }, [props.open]);

  return (
    <CRModal
      BackdropProps={{
        sx: {
          background: 'rgba(0, 0, 0, 0.25)',
        },
      }}
      width={480}
      paperPadding={'32px'}
      open={props.open}
      onClose={props.onClose}
    >
      <>
        <Box sx={{ display: 'flex', width: 1, justifyContent: 'flex-end' }}>
          <IconButton size="small" edge="start" onClick={props.onClose}>
            <X size={32} color="#000" weight="regular" />
          </IconButton>
        </Box>

        <Typography
          className={'epilogue fs-32 lh-40 semi-bold'}
          align={'center'}
        >
          Do you need help picking a plan?
        </Typography>
        <Box
          sx={{ display: 'flex', width: 1, justifyContent: 'center', mt: 3 }}
        >
          <Button
            id={'browsing-a-lot-modal-browsing-button'}
            fullWidth
            variant={'outlined'}
            color={'primary'}
            onClick={props.onClose}
          >
            Keep Browsing
          </Button>
        </Box>
        {!state.quizCompleted && (
          <Box
            sx={{ display: 'flex', width: 1, justifyContent: 'center', mt: 3 }}
          >
            <Button
              id={'browsing-a-lot-modal-quiz-button'}
              fullWidth
              variant={'outlined'}
              color={'primary'}
              onClick={() => {
                navigate('/quiz');
                props.onClose();
              }}
            >
              Try the Medicare Quiz
            </Button>
          </Box>
        )}
        <Box
          sx={{ display: 'flex', width: 1, justifyContent: 'center', my: 3 }}
        >
          <Button
            id={'browsing-a-lot-modal-schedule-call-button'}
            fullWidth
            variant={'contained'}
            color={'primary'}
            onClick={() => {
              props.onClose();
              openCalendly();
            }}
          >
            Schedule a Call
          </Button>
        </Box>
        <Typography className={'fs-18'} align={'center'}>
          Or call us now{' '}
          <Box
            component={'a'}
            href={'tel:+18889697667'}
            sx={{ color: '#1C434F', textDecoration: 'none' }}
          >
            (888) 969 7667 | TTY: 711
          </Box>
        </Typography>
      </>
    </CRModal>
  );
}
