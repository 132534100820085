import React from 'react';
import { Box, Card, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '@coverright/shared/contexts';
import { tooltips } from '@coverright/utils';
import { useCalendlyModal } from '@coverright/features';
import { useLogEvent } from '@coverright/shared/analytics';
import { PostEnrollmentContext } from '../../components/post-enrollment-context';
import { routes } from '../../app/router-new';
import ToDoList from './ui/to-do-list';
import { useAppSelector, ToDoListItem, SideLayout } from '../../shared';
import { preferredDoctors, preferredDrugs } from '../../entities';
import { EnrollmentDate } from './ui/enrollment-date';
import { isAEPActive, isGEPActive, isIEPActive } from './lib';

export default function ToDoListPage() {
  const navigate = useNavigate();
  const doctors = useAppSelector(preferredDoctors);
  const drugs = useAppSelector(preferredDrugs);
  const [
    {
      state: { quizCompleted, plansShown, callScheduled },
    },
  ] = React.useContext(AppContext);
  const { hasEnrollment } = React.useContext(PostEnrollmentContext);
  const openCalendly = useCalendlyModal();
  const logEvent = useLogEvent();

  React.useEffect(() => {
    if (navigate && hasEnrollment) {
      navigate(routes.dashboard.value);
    }
  }, [navigate, hasEnrollment]);

  const completed = React.useMemo(() => {
    return [
      true,
      doctors.length > 0,
      drugs.length > 0,
      !!quizCompleted,
      !!plansShown,
      !!callScheduled,
    ];
  }, [drugs, doctors, quizCompleted, plansShown, callScheduled]);

  const toDoItems: ToDoListItem[] = [
    {
      label: 'Create your account',
      completedButtonLabel: 'Completed',
      buttonLabel: '',
      completed: completed[0],
    },
    {
      label: 'Add your doctors',
      buttonLabel: '',
      onClick: () => navigate(routes.doctorsDrugs.value),
      completed: completed[1],
    },
    {
      label: 'Add prescription drugs',
      buttonLabel: '',
      onClick: () => navigate(routes.doctorsDrugs.value + '?tab=drugs'),
      completed: completed[2],
    },
    {
      label: 'Take the Medicare quiz',
      buttonLabel: '',
      onClick: () => navigate('/quiz'),
      completed: completed[3],
    },
    {
      label: 'Select your plan',
      buttonLabel: '',
      onClick: () => navigate(routes.planFinder.value),
      completed: completed[4],
    },
    {
      label: 'Schedule a call',
      buttonLabel: '',
      onClick: () => {
        logEvent('click', {
          element_title: 'Schedule a call',
          element_id: 'to-do-schedule-a-call',
        });
        openCalendly();
      },
      completed: completed[5],
      id: 'to-do-schedule-a-call',
    },
  ];

  return (
    <SideLayout
      title={'Welcome to CoverRight!'}
      subtitle={'Your Free Personal Medicare Concierge'}
    >
      <div>
        <Card sx={{ p: 3, flex: 1, mb: 3 }}>
          <Typography variant={'h4'} className={'fs-24 lh-24'}>
            Get ready for your Medicare plans.{' '}
            {completed.filter(Boolean).length} of 6 tips completed.
          </Typography>
          <Typography sx={{ mt: 1, mb: 3, color: '#666' }}>
            Follow our tips to get started.
          </Typography>

          <ToDoList items={toDoItems} />
        </Card>

        <Card sx={{ flex: 1, p: 3 }}>
          <Typography variant={'h4'} className={'fs-24 lh-24'}>
            Enrollment Dates
          </Typography>
          <Typography sx={{ mt: 1, mb: 3, color: '#666' }}>
            Get a plan before enrollment is over and time runs out!
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <EnrollmentDate
              title={'Initial Enrollment Period'}
              active={isIEPActive()}
              tooltip={tooltips.IEP}
            />
            <EnrollmentDate
              title={'General Enrollment Period'}
              active={isGEPActive()}
              tooltip={tooltips.GEP}
            />
            <EnrollmentDate
              title={'Medicare Advantage Open Enrollment Period'}
              active={isGEPActive()}
              tooltip={tooltips.MAOEP}
            />
            <EnrollmentDate
              title={'Annual Enrollment Period'}
              active={isAEPActive()}
              tooltip={tooltips.AEP}
            />
            <EnrollmentDate
              title={'Special Enrollment Period'}
              tooltip={tooltips.SEP}
            />
            <EnrollmentDate
              title={'Medicare Supplement Open Enrollment Period'}
              tooltip={tooltips.MSOEP}
            />
          </Box>
        </Card>
      </div>
    </SideLayout>
  );
}
