import {
  AppBar as MuiAppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
} from '@mui/material';
import { SxProps } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router';
import {
  CaretDown,
  CaretRight,
  Gear,
  List as ListIcon,
  Phone,
  PhoneOutgoing,
  SignOut,
  UserCircle,
} from '@phosphor-icons/react';
import { AppActionTypes, AppContext } from '@coverright/shared/contexts';
import { KeycloakContext } from '@coverright/shared/keycloak';
import { useCalendlyModal } from '@coverright/features';
import { NavAppBarButtons, useNavigationItems } from '../widgets/navigation';
import * as _ from 'lodash';
import { environment } from '../../environments/environment';
import { routes } from '../app/router-new';

export default function AppBar() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [{ state }, dispatch] = React.useContext(AppContext);
  const { keycloak } = React.useContext(KeycloakContext);
  const ref = React.useRef(null);
  const openCalendly = useCalendlyModal();
  //  const auth = useAuth();

  const items = useNavigationItems();

  React.useEffect(() => {
    function isCalendlyEvent(e: MessageEvent<any>) {
      return e.data.event && e.data.event.indexOf('calendly') === 0;
    }

    const listener = (message: MessageEvent<any>) => {
      if (isCalendlyEvent(message)) {
        if (message.data.event === 'calendly.event_scheduled') {
          dispatch({
            type: AppActionTypes.SAVE_STATE,
            payload: {
              stateKey: 'callScheduled',
              value: true,
            },
          });
        }
      }
    };
    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }, []);

  const closeDrawer = () => {
    setOpen(false);
  };

  const logo = React.useMemo(() => {
    switch (true) {
      case state?.forbesHealthLogo:
      case state.logoOverride?.forbesHealth:
        return (
          <Box
            component={'img'}
            sx={{ mr: 2, width: { xs: 280, lg: 320 } }}
            src={'/plan-finder/assets/img/FH_CR_Logo_Light.png'}
            className={'pointer'}
            onClick={() => navigate('/')}
          />
        );
      case state.logoOverride?.benzinga:
        return (
          <Box
            component={'img'}
            sx={{ mr: 2, width: { xs: 280, lg: 320 } }}
            src={'/plan-finder/assets/img/Benzinga_CR_Logo_Light.svg'}
            className={'pointer'}
            onClick={() => navigate('/')}
          />
        );
      case state.logoOverride?.assetInsure:
        return (
          <Box
            component={'img'}
            sx={{ mr: 2, my: '-10px', width: { xs: 280, lg: 320 } }}
            src={'/plan-finder/assets/img/Asset_Insure_Light.png'}
            className={'pointer'}
            onClick={() => navigate('/')}
          />
        );
      case state.logoOverride?.bellInsurance:
        return (
          <Box
            component={'img'}
            sx={{ mr: 2, my: '-10px', width: { xs: 280, lg: 320 } }}
            src={'/plan-finder/assets/img/JRBell_Light.png'}
            className={'pointer'}
            onClick={() => navigate('/')}
          />
        );
      case state.logoOverride?.rightsure:
        return (
          <Box
            component={'img'}
            sx={{ mr: 2, my: '-10px', width: { xs: 280, lg: 320 } }}
            src={'/plan-finder/assets/img/Rightsure_Light.png'}
            className={'pointer'}
            onClick={() => navigate('/')}
          />
        );
      default:
        return (
          <Box
            component={'img'}
            sx={{
              mr: 2,
              height: { xs: 36, lg: 28 },
              width: { xs: 132, lg: 150 },
            }}
            src={'/plan-finder/assets/img/Logo_white.svg'}
            className={'pointer'}
            onClick={() => navigate('/')}
          />
        );
    }
  }, [state]);

  return (
    <>
      <Drawer
        anchor={'top'}
        open={open}
        onClose={closeDrawer}
        PaperProps={{
          sx: {
            borderRadius: '0px 0px 24px 24px',
            boxShadow: 'none',
          },
        }}
        BackdropProps={{
          sx: {
            background: 'rgba(0, 0, 0, 0.25)',
          },
        }}
      >
        <Box sx={{ p: '23px 32px', mt: 11 }}>
          <List>
            {_.uniqWith(_.flattenDeep(items), _.isEqual).map(
              ({ icon: Icon, label, routes }, i) => (
                <ListItem
                  button
                  key={i}
                  onClick={() => {
                    navigate(routes[0]);
                    closeDrawer();
                  }}
                >
                  <ListItemIcon>
                    <Icon
                      size={24}
                      color="#1C434F"
                      weight="fill"
                      className={'mr-16'}
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ '& *': { color: '#1C434F' } }}
                    primary={label}
                  />
                </ListItem>
              )
            )}
            <ListItem
              button
              onClick={() => {
                if ((ref as any).current) {
                  (ref as any).current.click();
                }
              }}
            >
              <Box
                ref={ref}
                component={'a'}
                href={'tel:+18889897667'}
                sx={{ display: 'none' }}
              >
                (888) 969-7667 | TTY: 711
              </Box>
              <ListItemIcon>
                <Phone
                  size={24}
                  color="#1C434F"
                  weight="fill"
                  className={'mr-16'}
                />
              </ListItemIcon>
              <ListItemText
                sx={{ '& *': { color: '#1C434F' } }}
                primary={'(888) 969-7667 | TTY: 711'}
              />
            </ListItem>
            <ListItem
              id={'header-schedule-a-call-button'}
              button
              onClick={() => {
                openCalendly();
                closeDrawer();
              }}
            >
              <ListItemIcon>
                <PhoneOutgoing
                  size={24}
                  color="#000000"
                  weight="regular"
                  className={'mr-16'}
                />
              </ListItemIcon>
              <ListItemText primary={'Schedule a call'} />
              <CaretRight
                size={24}
                color="#000000"
                weight="regular"
                className={'mr-16'}
              />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                navigate(routes.accountSettings.value);
                closeDrawer();
              }}
            >
              <ListItemIcon>
                <Gear
                  size={24}
                  color="#000000"
                  weight="regular"
                  className={'mr-16'}
                />
              </ListItemIcon>
              <ListItemText primary={'Account Settings'} />
              <CaretRight
                size={24}
                color="#000000"
                weight="regular"
                className={'mr-16'}
              />
            </ListItem>
            <ListItem button onClick={() => keycloak.logout()}>
              <ListItemIcon>
                <SignOut
                  size={24}
                  color="#000000"
                  weight="regular"
                  className={'mr-16'}
                />
              </ListItemIcon>
              <ListItemText primary={'Sign Out'} />
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <MuiAppBar>
        <Toolbar
          sx={{
            margin: 'auto',
            width: 1,
            maxWidth: 1248,
            p: { xs: '15px 24px', sm: '15px 40px', xl: 3 },
            backgroundColor: '#1C434F',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: 1,
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 3,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
              {logo}
              <NavAppBarButtons />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{
                  display: { xs: 'none', lg: 'flex' },
                  alignItems: 'center',
                  gap: 3,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Phone size={24} color="#FFFFFF" weight="fill" />
                  <Box
                    component={'a'}
                    href={'tel:+18889897667'}
                    sx={{
                      color: 'white',
                      fontSize: 16,
                      lineHeight: '19px',
                      fontWeight: 500,
                      cursor: 'pointer',
                      whiteSpace: 'nowrap',
                      textDecoration: 'none',
                    }}
                  >
                    (888) 969-7667 | TTY: 711
                  </Box>
                </Box>
                <Button
                  id={'header-schedule-a-call-button'}
                  variant={'contained'}
                  color={'info'}
                  onClick={() => {
                    openCalendly();
                  }}
                >
                  Schedule A Call
                </Button>
                <AccountDropdown />
              </Box>
              <IconButton
                size="small"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ display: { lg: 'none' }, ml: '20px' }}
                onClick={() => {
                  if (open) {
                    closeDrawer();
                  } else {
                    setOpen(true);
                  }
                }}
              >
                <ListIcon size={32} color="#FFFFFF" />
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
      </MuiAppBar>
    </>
  );
}

const AccountDropdown = ({ sx }: { sx?: SxProps }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const { keycloak } = React.useContext(KeycloakContext);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // const auth = useAuth();

  return (
    <>
      <Box
        onClick={handleClick}
        className={'pointer'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          border: '1px solid #FFFFFF',
          borderRadius: '8px',
          p: 1,
        }}
      >
        <UserCircle size={40} color="#FFFFFF" weight="fill" />
        <CaretDown size={24} color="#FFFFFF" weight="fill" />
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            boxShadow: 'none',
            borderRadius: '8px',
            mt: 1.5,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          sx={{ color: '#666666' }}
          onClick={() => {
            handleClose();
            navigate(routes.accountSettings.value);
          }}
        >
          <ListItemIcon>
            <Gear size={24} color="#666666" weight="regular" />
          </ListItemIcon>
          Account Settings
        </MenuItem>
        <MenuItem
          sx={{ color: '#666666' }}
          onClick={() => {
            keycloak.logout();
            /*auth.removeUser();
        auth.signoutRedirect();*/
          }}
        >
          <ListItemIcon>
            <SignOut size={24} color="#666666" weight="regular" />
          </ListItemIcon>
          Sign Out
        </MenuItem>
      </Menu>
    </>
  );
};
