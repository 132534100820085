import { MaPlanListQuery } from '../../../shared/api/medicare';
import React from 'react';
import { useAppSelector } from '../../../shared';
import { medicareQuote, PlanCard } from '../../../entities';
import { areComponentPropsEqual, GetPlansYear } from '@coverright/utils';

interface MemoizedListProps {
  plans: MaPlanListQuery['plans']['data'],
}

const MemoizedList = React.memo(({plans}: MemoizedListProps) => {
  const quote = useAppSelector(medicareQuote);
  return <>
    {plans.map(plan => <PlanCard plan={plan as any}
                                 favorites={[]}
                                 toggleFavorite={() => {}}
                                 zip={quote!.zip}
                                 planYear={quote?.planYear || GetPlansYear()}
                                 countyName={quote?.county || ''}
                                 preferredDrugs={quote!.preferredDrugs}
                                 key={plan.bidId} />)}
  </>
}, areComponentPropsEqual)

export default MemoizedList;
