import { colors } from '@coverright/ui/themes';
import { Box, Button, SxProps, Typography } from '@mui/material';
import { CRTooltip } from '@coverright/ui/inputs';
import { getMedigapCompanyLogo, getNumbersFromString, tooltips } from '@coverright/utils';
import React from 'react';
import { MedigapPlanOutputWithDetails } from '@coverright/data-access/medigap';
import { SelectPlanButton } from '../../../../features';

export function PlanCard({plan}: {plan: MedigapPlanOutputWithDetails}) {
  return <div>
    <PlanLogo plan={plan} />
    <Typography sx={{ height: 15, my: 1 }} className={'fs-12 lh-15 medium'}>
      {[plan.ratingClass, plan.subTitle].filter(v => !!v).join(' | ')}
    </Typography>
    <Typography className={'fs-24 lh-32 mb-12'}>
      <strong>{plan.monthlyPremium}</strong> /mo
    </Typography>

    <CRTooltip placement={'top'} arrow title={tooltips.standardPartBPremium}>
      <div className={'flex flex-align-center'}>
        <img className={'w-16 h-16 mr-5'} src={'assets/img/info.svg'}></img>
        <Typography className={'fs-11 '}>plus standard Part B premium</Typography>
      </div>
    </CRTooltip>

    <CRTooltip placement={'top'} arrow
               title={'A discount applied to the monthly plan premium, typically if you reside with a spouse or domestic partner who is also enrolled in a Medicare Supplement plan from the same provider. Eligibility requirements vary by carrier.'}>
      <div className={'flex flex-align-center'}>
        <img className={'w-16 h-16 mr-5'} src={'assets/img/info.svg'}></img>
        <Typography className={'fs-12 mr-8 medium'}>Household discount:</Typography>
        <Typography sx={{ color: '#029094' }} className={'fs-12 medium self-color'}>
          {plan.householdDiscount ? <>
              {plan.householdDiscount}
              <span className="mh-4">|</span>
              ${((1 - getNumbersFromString(plan.householdDiscount)[0] / 100) * getNumbersFromString(plan.monthlyPremium as string)[0]).toFixed(2)} /mo
            </> :
            'n.a.'
          }
        </Typography>
      </div>
    </CRTooltip>

    <CRTooltip placement={'top'} arrow
               title={'An AM Best credit rating is an independent opinion regarding the creditworthiness of a company, for example a healthcare insurance provider. This analysis is based on a comprehensive review of the company\'s financial strength.'}>
      <div className={'flex flex-align-center mt-16'}>
        <Typography className={'fs-12 lh-20 w-100'}>AM Best rating:</Typography>
        <Typography sx={{ color: '#029094' }}
                    className={'fs-12 lh-20 medium self-color'}>{plan.amBestRating || 'n.a.'}</Typography>
      </div>
    </CRTooltip>


    <CRTooltip placement={'top'} arrow
               title={'An S&P credit rating is an independent opinion regarding the creditworthiness of a company, for example a healthcare insurance provider. This analysis is based on a comprehensive review of the company\'s financial strength.'}>
      <div className={'flex flex-align-center mb-16'}>
        <Typography className={'fs-12 lh-20 w-100'}>S&P rating:</Typography>
        <Typography sx={{ color: '#029094' }}
                    className={'fs-12 lh-20 medium self-color'}>{plan.spRating || 'n.a.'}</Typography>
      </div>
    </CRTooltip>

    <SelectPlanButton id={'mg-plan-table-select-plan-button'}>
      Select plan
    </SelectPlanButton>
  </div>
}

const PlanLogo = React.memo(({ plan, sx }: { plan: MedigapPlanOutputWithDetails, sx?: SxProps }) => {
  return React.useMemo(() => {
    const url = getMedigapCompanyLogo(plan.parentOrgName, plan.orgName);
    if (url) {
      return <Box sx={{
        backgroundPosition: 'left',
        height: 27,
        width: 96,
        maxWidth: 96,
        marginTop: 0,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundImage: `url("${url}")`,
        ...sx
      }} />
    } else {
      const text = plan.orgName === 'Accendo Ins Co' ? 'Accendo (Aetna/CVS)' : plan.title?.split(' - Plan')[0];
      return <Typography sx={{ minHeight: 27, display: 'flex', alignItems: 'center' }}
                         className={'bold fs-18'}>{text}</Typography>
    }
  }, [plan.parentOrgName, plan.orgName, plan.title]);
})
