import { Button } from '@mui/material';
import { ArrowLeft } from '@phosphor-icons/react';
import React from 'react';

interface Props {
  label: string,
  onClick: () => void
}

export const BackButton = ({label, onClick}: Props) => {
  return <Button variant={'text'}
                 size={'small'}
                 startIcon={<ArrowLeft size={16} color={'#1C434F'} weight="regular" />}
                 onClick={onClick}
                 sx={{ mt: 1, color: '#1C434F', cursor: 'pointer', width: 'fit-content' }}>
    {label}
  </Button>
}
