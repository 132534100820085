import React, { useMemo } from 'react';
import { V3Preloader } from '@coverright/ui/shared';
import { Box, Card, Typography } from '@mui/material';
import usePlan from '../api/use-plan';
import { DrugCostChart, DrugOffers } from '@coverright/widgets';

const chartColors = [
  '#1E95A0',
  '#1DB399',
  '#C8E8EB',
  '#7DA8AC',
  '#84C7BB',
  '#C3D4D6',
];

export default function Offers() {
  const { plan, loading } = usePlan();

  const costs = useMemo(() => {
    return (
      plan?.drugCostMonthlyBreakdown?.costs.map((c) => ({
        ...c,
        numericCost:
          plan?.drugPrices.find((d) => d.rxcui === c.rxcui)?.quoteQuantity || 0,
        packageName:
          plan?.drugPrices.find((p) => p.rxcui === c.rxcui)
            ?.packageDescription || c.packageName,
      })) || []
    );
  }, [plan]);

  if (loading) {
    return <V3Preloader sx={{ mt: 4, mb: 6 }} />;
  }

  if (!plan || !plan.drugPrices?.length) {
    return null;
  }

  return (
    <>
      <Card sx={{ p: '24px 0 16px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { md: 'center' },
            px: 3,
            mb: 1,
          }}
        >
          <Typography variant={'h3'}>Save on your drug costs...</Typography>
          {/*<FormControlLabel
          value="start"
          control={<Switch checked color="primary" />}
          label="With savings"
          labelPlacement="start"
        />*/}
        </Box>
        <DrugCostChart
          loading={loading}
          costs={costs}
          colors={chartColors}
          monthlyCosts={plan.drugCostMonthlyBreakdown?.monthlyCosts || []}
        />
      </Card>
      <Box sx={{ background: '#fff', pb: 0.5 }}>
        <DrugOffers
          drugPrices={plan.drugPrices}
          discountedDrugs={plan.discountedDrugs}
        />
      </Box>
    </>
  );
}
