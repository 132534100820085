import { useConfirm } from '../../components/confirm-dialog-context';
import React from 'react';
import PartDModalContent from './ui/part-d-modal-content';

export function usePartDConfirm() {
  const confirm = useConfirm();

  return () => confirm({
    content: <PartDModalContent />,
    okButtonTitle: 'Yes - I have other qualifying coverage',
    cancelButtonTitle: 'No - return to plans'
  })
}
