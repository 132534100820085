import { Calendar } from '@phosphor-icons/react';
import { Button } from '@mui/material';
import React from 'react';
import { useCalendlyModal } from '@coverright/features';

export function ScheduleACallButton({ id }: { id: string }) {
  const openCalendly = useCalendlyModal();

  return (
    <Button
      fullWidth
      variant={'contained'}
      onClick={() => openCalendly()}
      id={id}
      sx={{ justifyContent: 'flex-start', mt: 3, fontWeight: 500 }}
      startIcon={<Calendar size={24} color="#FFF" weight="regular" />}
    >
      Schedule a call
    </Button>
  );
}
