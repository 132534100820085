import * as React from 'react';
import { Box, Button, Typography, Card, Divider, Collapse, Stack } from '@mui/material';
import { SxProps } from '@mui/material';
import {
  ArrowLeft, CaretCircleDown,
  Check, CheckCircle, ClipboardText,
  CurrencyCircleDollar, DownloadSimple, FileText,
  FirstAid, FirstAidKit,
  Info,
  Pill,
  Receipt,
  X, XCircle
} from '@phosphor-icons/react';
import { PlanWithTiers } from '@coverright/data-access/medicare';
import { useNavigate } from 'react-router';
import { getCompanyLogo } from '@coverright/utils';
import { CRTooltip } from '@coverright/ui/inputs';
import { getDeductible } from '@coverright/shared/config';
import * as Scroll from 'react-scroll';
import { Benefit } from '@coverright/ui/marketplaces';
import { DrugTierOutput } from '@coverright/data-access/types/medicare';
import { planFiles } from '../lib/plan-files';
import { convertExtraBenefitValue } from '../../../utils';
import { CompanyLogo, StarRating } from '@coverright/ui/shared';
import { routes } from '../../../app/router-new';
import {SelectPlanButton} from "../../../features";
import {useAppSelector} from "../../../shared";
import {preferredDrugs as drugsSelector, preferredPharmacies as pharmaciesSelector} from "../../../entities";
const Element: any = Scroll.Element;
const Link: any = Scroll.Link;

interface Props {
  plan: PlanWithTiers
}

export default function PlanDetails({plan}: Props) {
  const navigate = useNavigate();
  const preferredDrugs = useAppSelector(drugsSelector);
  const preferredPharmacies = useAppSelector(pharmaciesSelector);

  const files = React.useMemo(() => {
    return planFiles(plan?.planFiles);
  }, [plan])

  return <Box sx={{display: 'flex', flexDirection: 'column', gap: 3}} data-test={'ma-plan-details'}>
    <div>
      <Button variant={'text'} size={'small'}
              id={'ma-plan-details-back-button'}
              onClick={() => navigate(routes.planFinderMA.value)}
              startIcon={<ArrowLeft size={16} color="#1C434F" weight="regular" />}>
        Back to plans
      </Button>
    </div>
    <Card sx={{p: 3}}>
      <Box sx={{display: 'flex', alignItems: 'stretch', gap: 4, flexDirection: {xs: 'column', md: 'row'}}}>
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
          <Box sx={{display: 'flex', gap: 4, py: 3, alignItems: {xs: 'flex-start', md: 'center'}, flexDirection: {xs: 'column', md: 'row'}}}>
            <CompanyLogo url={getCompanyLogo(plan.parentOrgName, plan.orgName)}
                         sx={{
                           height: 100,
                           mx: 2,
                           width: 1,
                           maxWidth: 138,
                         }}
            />
            <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'space-between'}}>
              <StarRating plan={plan}/>
              <Typography className={'fs-24 lh-40 semi-bold epilogue'}>{plan.planName}</Typography>
              <Typography variant={'body2'} sx={{color: '#666666'}}>Plan ID: {plan.bidId}</Typography>
            </Box>
          </Box>

          <Stack spacing={2}>
            {(!!preferredDrugs?.length && !plan.missingFormularyInAep) && <Button sx={{border: '1px solid #1C434F'}} className={'fs-16 p-16 medium'} fullWidth variant={'outlined'}
                                                                                  onClick={() => navigate(routes.drugCost.value.replace(':id', plan.bidId))}
                                                                                  id={'ma-plan-drug-cost-breakdown-button'}
                                                                                  color={'primary'}>
              Drug Cost Breakdown
            </Button>
            }
            <SelectPlanButton id={'ma-plan-details-select-plan-button'} className={'medium'}>Select plan</SelectPlanButton>
          </Stack>
        </Box>

        <Box sx={{p: 3, background: '#F3FBFC', borderRadius: '8px', display: 'flex', flexDirection: 'column', flex: 1}}>
          <Typography className={'lh-32 fs-24 bold'} sx={{color: '#1C434F', mb: 2}} align={'center'}>{plan.monthlyCost || 'N/A'}</Typography>
          <CRTooltip arrow title={'This is the sum of monthly premium, estimated monthly doctor costs and estimated monthly drug costs.'}>
            <Box sx={{display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'center'}}>
              <Typography variant={'body2'} className={'medium epilogue'}>estimated average total cost per month</Typography>
              <Info size={24} color="#1C434F" weight="fill" />
            </Box>
          </CRTooltip>
          <Box className={'mt-24'}>
            <Box sx={{borderRadius: '8px', background: '#E8FDEB', p: 1, width: 'fit-content'}}>
              <CurrencyCircleDollar size={24} color="#0B741C" weight="fill" />
            </Box>
            <Typography className={'medium'} sx={{mt: 2, color: '#4D4D4D'}}>Monthly Premium</Typography>
            <Typography className={'semi-bold'} sx={{color: '#19605D'}}>{plan.monthlyPremium}</Typography>
          </Box>
          <Box sx={{display: 'flex', mt: 3, gap: 7, justifyContent: 'flex-start', flexDirection: {xs: 'column', md: 'row'}}}>
            <div>
              <Box sx={{borderRadius: '8px', background: '#CEF2F1', p: 1, width: 'fit-content'}}>
                <FirstAid size={24} color="#1C434F" weight="fill" />
              </Box>
              <Typography className={'medium'} sx={{mt: 2, color: '#4D4D4D'}}>Estimated drug costs</Typography>
              <Typography className={'semi-bold'} sx={{color: '#19605D'}}>{plan.drugsCost || 'N/A'}</Typography>
            </div>
            <div>
              <Box sx={{borderRadius: '8px', background: '#FAE1E8', p: 1, width: 'fit-content'}}>
                <Pill size={24} color="#460C1D" weight="fill" />
              </Box>
              <Typography className={'medium'} sx={{mt: 2, color: '#4D4D4D'}}>Estimated doctor costs</Typography>
              <Typography className={'semi-bold'} sx={{color: '#19605D'}}>{plan.doctorsCost}</Typography>
            </div>
          </Box>
        </Box>
      </Box>
    </Card>

    <Card sx={{p: 3}}>
      <Typography className={'fs-24 lh-40 semi-bold epilogue'} align={'center'}>Key Plan Benefits Overview</Typography>
    </Card>

    <Card sx={{p: 3}}>
      <Box sx={{display: 'flex', gap: 2, alignItems: {xs: 'flex-start', md: 'center'}, mb: 3, flexDirection: {xs: 'column', md: 'row'}}}>
        <Receipt size={40} color="#1C434F" weight="duotone" />
        <Typography className={'fs-24 lh-40 semi-bold epilogue'}>Estimated Monthly Cost Summary</Typography>
      </Box>

      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2}}>
        <CRTooltip arrow title={'This is the fixed monthly amount charged by the insurance company to maintain insurance coverage through the plan. Many Medicare Advantage plans have a $0 monthly premium.'}>
          <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
            <Info size={16} color="#666666" weight="fill" />
            <Typography>Monthly plan premium</Typography>
          </Box>
        </CRTooltip>
        <Typography>{plan.monthlyPremium}</Typography>
      </Box>
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2}}>
        <CRTooltip arrow title={'Estimated monthly drug cost includes any annual out-of-pocket cost sharing expenses that you must pay for the medications entered, calculated on an average monthly basis. Cost sharing expenses are based on the individual drug tier and the drug coverage breakdown as outlined in the plan details. The retail reference price (where applicable) for your drugs is based on average drug prices in the plan’s local area. Estimated monthly drug costs can only be calculated if you have entered medication information.'}>
          <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
            <Info size={16} color="#666666" weight="fill" />
            <Typography>Estimated drug costs</Typography>
          </Box>
        </CRTooltip>
        <Typography>{plan.drugsCost}</Typography>
      </Box>
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2}}>
        <CRTooltip arrow title={'Estimated doctor costs are calculated based on your estimated number of Primary Care Physician and Specialist visits per year, multiplied by the copays or coinsurance for in-network providers.'}>
          <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
            <Info size={16} color="#666666" weight="fill" />
            <Typography>Estimated doctor costs</Typography>
          </Box>
        </CRTooltip>
        <Typography>{plan.doctorsCost}</Typography>
      </Box>
      <Divider sx={{mb: 2, borderColor: '#666666'}} />
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2}}>
        <CRTooltip arrow title={'This is the sum of monthly premium, estimated monthly doctor costs and estimated monthly drug costs.'}>
          <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
            <Info size={16} color="#666666" weight="fill" />
            <Typography>Total estimated monthly costs</Typography>
          </Box>
        </CRTooltip>
        <Typography>{plan.monthlyCost}</Typography>
      </Box>
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2}}>
        <CRTooltip arrow title={'The Part B premium is what most people pay for the Part B Medical coverage provided under Original Medicare. You still pay the Part B premium, even if you\'re enrolled in a Medicare Advantage plan. The premium amount you pay may be dependent on your income.'}>
          <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
            <Info size={16} color="#666666" weight="fill" />
            <Typography>plus Medicare Part B Premium</Typography>
          </Box>
        </CRTooltip>
        <Typography>{getDeductible('standardPartBMonthlyPremium', plan.planYear)}</Typography>
      </Box>
    </Card>

    <Card sx={{p: 3}}>
      <Box sx={{display: 'flex', gap: 2, alignItems: {xs: 'flex-start', md: 'center'}, mb: 3, flexDirection: {xs: 'column', md: 'row'}}}>
        <FirstAid size={40} color="#1C434F" weight="duotone" />
        <Typography className={'fs-24 lh-40 semi-bold epilogue'}>Your Healthcare Selections</Typography>
      </Box>

      <Typography className={'fs-18 lh-32 bold mb-4'}>Your doctors</Typography>
      {plan.doctorsCoverage.map((doctor) => <IncludeLine key={doctor.npi} value={!!doctor.coverage} label={doctor.name}/>)}
      {!plan.doctorsCoverage.length && <Typography sx={{color: '#666666'}}>You haven’t added any doctors</Typography>}
      <Button id={'ma-plan-details-add-doctor-button'} variant={'outlined'} color={'primary'} sx={{p: 1, borderWidth: '2px!important', fontWeight: 700, fontSize: 16, mt: 2}}
              onClick={() => navigate(routes.doctorsDrugs.value)}>Add doctors</Button>

      <Divider sx={{my: 3, borderColor: '#B3B3B3'}} />

      <Typography className={'fs-18 lh-32 bold mb-4'}>Your drugs</Typography>
      {plan.drugsCoverage.map((drug, i) => <IncludeLine key={`${drug.packRxcui}${i}`} value={!!drug.coverage} label={drug.name || 'Drug is missing'}/>)}
      {!plan.drugsCoverage.length && <Typography sx={{color: '#666666'}}>You haven’t added any drugs</Typography>}
      <Button id={'ma-plan-details-add-drugs-button'} variant={'outlined'} color={'primary'} sx={{p: 1, borderWidth: '2px!important', fontWeight: 700, fontSize: 16, mt: 2}}
              onClick={() => navigate(routes.doctorsDrugs.value + '?tab=drugs')}>Add drugs</Button>

      <Divider sx={{my: 3, borderColor: '#B3B3B3'}} />

      <Typography className={'fs-18 lh-32 bold mb-4'}>Your pharmacy</Typography>
      {!!preferredPharmacies?.length && <IncludeLine value={true} label={preferredPharmacies[0].name}/>}
      {!preferredPharmacies?.length && <Typography sx={{color: '#666666'}}>You haven’t added a pharmacy</Typography>}
      <Button id={'ma-plan-details-add-pharmacy-button'} variant={'outlined'} color={'primary'} sx={{p: 1, borderWidth: '2px!important', fontWeight: 700, fontSize: 16, mt: 2}}
              onClick={() => navigate(routes.doctorsDrugs.value + '?tab=pharmacy')}>Add pharmacy</Button>
    </Card>

    <Card sx={{p: 3, display: 'flex', flexDirection: 'column', gap: 3}}>
      <Box sx={{display: 'flex', gap: 2, alignItems: {xs: 'flex-start', md: 'center'}, mb: 3, flexDirection: {xs: 'column', md: 'row'}}}>
        <ClipboardText size={40} color="#1C434F" weight="duotone" />
        <Typography className={'fs-24 lh-40 semi-bold epilogue'}>Plan Highlights</Typography>
      </Box>

      <Param title={'Plan Type'} value={`Medicare Advantage (${plan.planType})`} tooltip={'Plan types include Preferred Provider Organizations (PPO) and Health Maintenance Organization (HMO) plans. PPO plans typically have a wider availability of healthcare providers, but often have higher doctor visit copays. HMO plans require you to select a Primary Care Physician, and you will need a referral to see a specialist. However, copays are typically lower than a PPO plan.'} />
      <Divider sx={{borderColor: '#B3B3B3'}} />

      <Param title={'Annual In-Network Deductible'} value={plan.inNetworkDeductableAmount} tooltip={'The amount you must pay out of pocket for your health care from in-network medical services before your coverage begins to pay.'} />
      <Divider sx={{borderColor: '#B3B3B3'}} />

      <Param title={'Out-of-Pocket Maximum'} value={plan.outOfPocketMaximum} tooltip={'The maximum out-of-pocket costs that you can expect to pay for your healthcare services in each plan period. This includes copays, coinsurance amounts, hospital visits and other healthcare-related services. Once you reach this limit, the health plan pays 100% of your healthcare costs. The limit does not apply to premiums, balance-billed charges from out of network providers, or other services that are not covered by the plan.'} />
      <Divider sx={{borderColor: '#B3B3B3'}} />

      <Param title={'Primary Doctor Visit'} value={plan.primaryCarePhysician} tooltip={'The amount you can expect to pay for a regular visit to your primary care physician as outlined in your policy details.'} />
      <Divider sx={{borderColor: '#B3B3B3'}} />

      <Param title={'Specialist Visit'} value={plan.physicianSpecialist} tooltip={'The amount you can expect to pay for a visit to a medical specialist (such as an orthopedist, dermatologist or cardiologist), as outlined in your policy details.'} />
      <Divider sx={{borderColor: '#B3B3B3'}} />

      <Param title={'Inpatient Hospital Care'} value={plan.hospitalBenefits} tooltip={'Includes services related to staying in a hospital for scheduled procedures, accidents, or medical emergencies.'} />
      <Divider sx={{borderColor: '#B3B3B3'}} />

      <Param title={'Prescription Drug Coverage'} value={<CheckMark value={plan.extraBenefits.drugCoverage} />} tooltip={'Part D (Prescription drug coverage) includes coverage for your prescription drugs. Part D plans can be purchased separately to accompany Original Medicare and Medigap plans. Part D plans are also often bundled with Medicare Advantage plans.'} />
      <Divider sx={{borderColor: '#B3B3B3'}} />

      <Param title={'Prescription Drug Deductible'} value={[plan.drugDetails?.mrxAltDedAmount, plan.drugDetails?.mrxAltNoDedTier]} tooltip={'The amount you must pay out of pocket for your prescription drugs before your coverage begins to pay.'} />
      <Divider sx={{borderColor: '#B3B3B3'}} />

      <Param title={'Dental Services'}
             value={<Link to={'Dental Services'} offset={-111} smooth={true} spy={true} duration={200}>
               <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                 <CheckMark value={!!plan.dental?.benefits} />
                 {plan?.dental?.benefits && <Typography sx={{color: '#1C434F'}} className={'pointer underline semi-bold'}>View Details</Typography>}
               </Box>
             </Link>}
             tooltip={'Medicare Advantage plans often provide dental benefits at no extra cost. For example: coverage for routine dental exams, cleaning and fluoride treatment.'} />
      <Divider sx={{borderColor: '#B3B3B3'}} />

      <Param title={'Vision Services'}
             value={<Link to={'Vision Services'} offset={-111} smooth={true} spy={true} duration={200}>
               <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                 <CheckMark value={!!plan.vision?.benefits} />
                 {plan?.vision?.benefits && <Typography sx={{color: '#1C434F'}} className={'pointer underline semi-bold'}>View Details</Typography>}
               </Box>
             </Link>}
             tooltip={'Medicare Advantage plans often provide vision benefits at no extra cost. For example: coverage for routine eye exams, contact lenses and eyeglasses.'} />
      <Divider sx={{borderColor: '#B3B3B3'}} />

      <Param title={'Hearing Services'}
             value={<Link to={'Hearing Services'} offset={-111} smooth={true} spy={true} duration={200}>
               <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                 <CheckMark value={!!plan.hearing?.benefits} />
                 {plan.hearing?.benefits && <Typography sx={{color: '#1C434F'}} className={'pointer underline semi-bold'}>View Details</Typography>}
               </Box>
             </Link>}
             tooltip={'Medicare Advantage plans often provide built-in hearing benefits for no additional premium. For example: coverage for routine hearing exams and hearing aids.'} />
      <Divider sx={{borderColor: '#B3B3B3'}} />

      <Param title={'Fitness Benefits'}
             value={<Link to={'Fitness Benefits'} offset={-111} smooth={true} spy={true} duration={200}>
               <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                 <CheckMark value={plan.extraBenefits.fitness} />
                 {plan.extraBenefits.fitness && <Typography sx={{color: '#1C434F'}} className={'pointer underline semi-bold'}>View Details</Typography>}
               </Box>
             </Link>}
             tooltip={'Medicare Advantage plans often provide built-in fitness benefits or gym membership for no additional premium, for example gym memberships or fitness programs.'} />
      <Divider sx={{borderColor: '#B3B3B3'}} />

      <Param title={'Insulin Savings'}
             value={<Link to={'Insulin Savings'} offset={-111} smooth={true} spy={true} duration={200}>
               <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                 <CheckMark value={plan.extraBenefits.insulinSavings} />
                 {plan.extraBenefits.insulinSavings && <Typography sx={{color: '#1C434F'}} className={'pointer underline semi-bold'}>View Details</Typography>}
               </Box>
             </Link>}
             tooltip={'Medicare Advantage plans that offer Insulin Savings are part of Medicare\'s Senior Savings Model. These plans cover several types of insulin at a maximum $35 copay for a 30-day supply in the deductible, initial coverage, and coverage gap phases of the Part D benefit.'} />
      <Divider sx={{borderColor: '#B3B3B3'}} />

      <Param title={'Over-the-counter Allowance'}
             value={<Link to={'Over-the-counter Allowance'} offset={-111} smooth={true} spy={true} duration={200}>
               <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                 <CheckMark value={plan?.oTC.length > 0} />
                 {plan?.oTC.length > 0 && <Typography sx={{color: '#1C434F'}} className={'pointer underline semi-bold'}>View Details</Typography>}
               </Box>
             </Link>}
             tooltip={'A periodical (such as monthly or quarterly) allowance for over-the-counter allowance pharmacy products such as cold and allergy medications, eye drops, vitamins, supplements, and compression stockings.'} />
      <Divider sx={{borderColor: '#B3B3B3'}} />

      <Param title={'Telehealth Services'}
             value={<Link to={'Telehealth Services'} offset={-111} smooth={true} spy={true} duration={200}>
               <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                 <CheckMark value={plan.extraBenefits.telehealth} />
                 {plan.extraBenefits.telehealth && <Typography sx={{color: '#1C434F'}} className={'pointer underline semi-bold'}>View Details</Typography>}
               </Box>
             </Link>}
             tooltip={'Medicare Advantage plans often provide telehealth benefits for no additional premium. These benefits can provide beneficiaries with more accessibility to medical and health-related services, through digital communications technologies, such as mobile phones, tablets and desktop computers.'} />
      <Divider sx={{borderColor: '#B3B3B3'}} />

      <Param title={'Transportation Services'}
             value={<Link to={'Transportation Services'} offset={-111} smooth={true} spy={true} duration={200}>
               <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                 <CheckMark value={plan?.transportation.length > 0} />
                 {plan?.transportation.length > 0 && <Typography sx={{color: '#1C434F'}} className={'pointer underline semi-bold'}>View Details</Typography>}
               </Box>
             </Link>}
             tooltip={'Medicare Advantage plans often provide transportation benefits for no additional premium. For example coverage for transportation to or from doctor\'s appointments, pharmacies, or fitness centers.'} />
    </Card>


    <Card sx={{p: 3, display: 'flex', flexDirection: 'column'}}>
      <Box sx={{display: 'flex', gap: 2, alignItems: {xs: 'flex-start', md: 'center'}, mb: 3, flexDirection: {xs: 'column', md: 'row'}}}>
        <Pill size={40} color="#1C434F" weight="duotone" />
        <Typography className={'fs-24 lh-40 semi-bold epilogue'}>Drug Coverage & Costs</Typography>
      </Box>

      <DrugCoverage plan={plan} />
    </Card>


    <Card sx={{p: 3, display: 'flex', flexDirection: 'column', gap: 3}}>
      <Box sx={{display: 'flex', gap: 2, alignItems: {xs: 'flex-start', md: 'center'}, mb: 3, flexDirection: {xs: 'column', md: 'row'}}}>
        <Box component={'img'} src={'/plan-finder/assets/img/stethoscope.svg'} />
        <Typography className={'fs-24 lh-40 semi-bold epilogue'}>Your Doctor Costs</Typography>
      </Box>

      <Param title={'Primary Doctor Visit'} value={plan.primaryCarePhysician}
             tooltip={'The amount you can expect to pay for a regular visit to your primary care physician as outlined in your policy details.'} />
      <Divider sx={{borderColor: '#B3B3B3'}} />

      <Param title={'Specialist Visit'} value={plan.physicianSpecialist}
             tooltip={'The amount you can expect to pay for a visit to a medical specialist (such as an orthopedist, dermatologist or cardiologist), as outlined in your policy details.'} />
      <Divider sx={{borderColor: '#B3B3B3'}} />

      <Param title={'Preventive Care'} value={plan.preventiveCare}
             tooltip={'Typically, periodic health exams that occur on a regular basis for preventive purposes. These can include routine physicals or annual check-ups.'} />
    </Card>



    <Card sx={{p: 3, display: 'flex', flexDirection: 'column', gap: 3}}>
      <Box sx={{display: 'flex', gap: 2, alignItems: {xs: 'flex-start', md: 'center'}, mb: 3, flexDirection: {xs: 'column', md: 'row'}}}>
        <FirstAidKit size={40} color="#1C434F" weight="duotone" />
        <Typography className={'fs-24 lh-40 semi-bold epilogue'}>Medical and Hospital Coverage </Typography>
      </Box>

      <Param title={'Emergency Room'} value={plan.hospitalityDetails.emergencyRoom}
             tooltip={'Typically, emergency room services include all services provided when a patient visits an emergency room for an emergency condition. An emergency condition is any medical situation that would lead most people to believe that failing to obtain immediate medical care could result in placing the patient\'s health at serious risk.'} />
      <Divider sx={{borderColor: '#B3B3B3'}} />

      <Param title={'Urgently Needed Services'} value={plan.hospitalityDetails.urgentServices}
             tooltip={'Medical care that you get outside of your Medicare health plan\'s service area for a sudden illness or injury that needs attention but isn\'t life threatening. If it\'s not safe to wait until you get home to get care from a plan doctor, then the health plan must pay for the care.'} />
      <Divider sx={{borderColor: '#B3B3B3'}} />

      <Param title={'Ambulance Services'} value={plan.hospitalityDetails.ambulanceServices}
             tooltip={'Transport by ambulance to and from your home and a medical facility to receive medical care. Medicare covers a number of different types of ambulance services depending on the seriousness of your medical condition, or whether other methods of transportation could endanger your health.'} />
      <Divider sx={{borderColor: '#B3B3B3'}} />

      <Param title={'Inpatient Hospital Care'} value={plan.hospitalBenefits}
             tooltip={'Includes services related to staying in a hospital for scheduled procedures, accidents, or medical emergencies.'} />
      <Divider sx={{borderColor: '#B3B3B3'}} />

      <Param title={'Skilled Nursing Facilities (SNF)'} value={plan.skilledNursing}
             tooltip={'A nursing facility with the staff and equipment to give skilled nursing care and, in most cases, skilled rehabilitative services and other related health services.'} />
      <Divider sx={{borderColor: '#B3B3B3'}} />

      <Param title={'Outpatient Diagnostic Tests and Therapeutic Services'} value={plan.outpatientHospitality}
             tooltip={'Tests done to help a doctor diagnose or rule out a suspected illness, injury, or condition. These tests are ordered by your treating doctor and may include x-rays, blood tests, urinalysis, and tests done on tissue specimens.'} />
      <Divider sx={{borderColor: '#B3B3B3'}} />

      <Param title={'Outpatient Hospital And Surgery'} value={plan.outpatientSurgery}
             tooltip={'Typically defined as any surgical procedure that does not require an overnight stay in a hospital.'} />
      <Divider sx={{borderColor: '#B3B3B3'}} />

      <Param title={'Outpatient Rehabilitation Services'} value={plan.outpatientRehabilitation}
             tooltip={'Services to help you regain abilities, such as speech or walking, that have been impaired by an illness or injury. These services take place in an outpatient setting. These services are provided by nurses, as well as by physical, occupational and speech therapists. Examples include working with a physical therapist to help you walk or working with an occupational therapist to help you get dressed.'} />
      <Divider sx={{borderColor: '#B3B3B3'}} />

      <Param title={'Outpatient Mental Health Care'} value={plan.mentalHealth}
             tooltip={'Typically for services provided by a mental health professional in an outpatient setting.'} />
      <Divider sx={{borderColor: '#B3B3B3'}} />

      <Param title={'Home Health Care'} value={plan.homeHealth}
             tooltip={'Health care services and supplies that a doctor decides you may receive in your home under a doctor-established plan of care. Medicare only covers home health care on a limited basis as ordered by your doctor.'} />

    </Card>



    <Card sx={{p: 3, display: 'flex', flexDirection: 'column', gap: 3}}>
      <Box sx={{display: 'flex', gap: 2, alignItems: {xs: 'flex-start', md: 'center'}, mb: 3, flexDirection: {xs: 'column', md: 'row'}}}>
        <Box component={'img'} src={'/plan-finder/assets/img/hand-heart.svg'} />
        <Typography className={'fs-24 lh-40 semi-bold epilogue'}>Extra Benefits</Typography>
      </Box>

      <Element name={"Dental Services"}>
        <Param title={'Dental Services'} value={plan.dental?.benefits}
               tooltip={'Medicare Advantage plans often provide dental benefits at no extra cost. For example: coverage for routine dental exams, cleaning and fluoride treatment.'} />
        <Divider sx={{borderColor: '#B3B3B3', mt: 3}} />
      </Element>

      <Element name={"Vision Services"}>
        <Param title={'Vision Services'} value={plan.vision?.benefits}
               tooltip={'Medicare Advantage plans often provide vision benefits at no extra cost. For example: coverage for routine eye exams, contact lenses and eyeglasses.'} />
        <Divider sx={{borderColor: '#B3B3B3', mt: 3}} />
      </Element>

      <Element name={"Hearing Services"}>
        <Param title={'Hearing Services'} value={plan.hearing?.benefits}
               tooltip={'Medicare Advantage plans often provide built-in hearing benefits for no additional premium. For example: coverage for routine hearing exams and hearing aids.'} />
        <Divider sx={{borderColor: '#B3B3B3', mt: 3}} />
      </Element>

      <Element name={"Fitness Benefits"}>
        <Param title={'Fitness Benefits'} value={plan?.otherDefinedSupplemental.fitnessBenefit}
               tooltip={'Medicare Advantage plans often provide built-in fitness benefits or gym membership for no additional premium, for example gym memberships or fitness programs.'} />
        <Divider sx={{borderColor: '#B3B3B3', mt: 3}} />
      </Element>

      <Element name={"Insulin Savings"}>
        <Param title={'Insulin Savings'} value={<CheckMark value={plan.extraBenefits.insulinSavings} />}
               tooltip={'Medicare Advantage plans that offer Insulin Savings are part of Medicare\'s Senior Savings Model. These plans cover several types of insulin at a maximum $35 copay for a 30-day supply in the deductible, initial coverage, and coverage gap phases of the Part D benefit.'} />
        <Divider sx={{borderColor: '#B3B3B3', mt: 3}} />
      </Element>

      <Param title={'Meals After Hospital Stay'} value={plan.meal}
             tooltip={'Healthy food or home deliveries for plan members post discharge from hospital or skilled nursing facility.'} />
      <Divider sx={{borderColor: '#B3B3B3'}} />

      <Element name={"Over-the-counter Allowance"}>
        <Param title={'Over-the-Counter Allowance'} value={plan.oTC}
               tooltip={'A periodical (such as monthly or quarterly) allowance for over-the-counter allowance pharmacy products such as cold and allergy medications, eye drops, vitamins, supplements, and compression stockings.'} />
        <Divider sx={{borderColor: '#B3B3B3', mt: 3}} />
      </Element>

      <Element name={"Telehealth Services"}>
        <Param title={'Telehealth Services'} value={plan.telehealth}
               tooltip={'Medicare Advantage plans often provide telehealth benefits for no additional premium. These benefits can provide beneficiaries with more accessibility to medical and health-related services, through digital communications technologies, such as mobile phones, tablets and desktop computers.'} />
        <Divider sx={{borderColor: '#B3B3B3', mt: 3}} />
      </Element>

      <Element name={"Transportation Services"}>
        <Param title={'Transportation Services'} value={plan.transportation}
               tooltip={'Medicare Advantage plans often provide transportation benefits for no additional premium. For example coverage for transportation to or from doctor\'s appointments, pharmacies, or fitness centers.'} />
      </Element>

    </Card>

    <Card sx={{p: 3, display: 'flex', flexDirection: 'column', gap: 3}}>
      <Box sx={{display: 'flex', gap: 2, alignItems: {xs: 'flex-start', md: 'center'}, mb: 3, flexDirection: {xs: 'column', md: 'row'}}}>
        <FileText size={40} color="#1C434F" weight="duotone" />
        <Typography className={'fs-24 lh-40 semi-bold epilogue'}>Plan and Medicare Brochures</Typography>
      </Box>

      <Param title={'Plan Brochures'} value={<>
        {!files.length && <Typography>Not available</Typography>}
        {!!files.length && files.map(item => <a key={item.url} className={`underline flex flex-align-center medium mt-10`} target={'_blank'} href={item.url}>
          <DownloadSimple size={24} className={'mr-8'} color="#1C434F" weight="bold" />
          {item.label}
        </a>)}
      </>} />

      <Divider sx={{borderColor: '#B3B3B3', my: 2}} />

      <Param title={'Medicare Brochure(s)'} value={<a className={`underline flex flex-align-center medium mt-10`} target={'_blank'} href={'https://www.medicare.gov/Pubs/pdf/10050-Medicare-and-You.pdf'}>
        <DownloadSimple size={24} className={'mr-8'} color="#1C434F" weight="bold" />
        Medicare & You Handbook ({plan.planYear?.toString().replace('Year', '')})
      </a>} />

    </Card>

  </Box>
}

const TableStyles: SxProps = {
  width: 1,
  '& thead': {
    '& td:not(:first-child)': {
      p: '8px 24px',
      backgroundColor: '#F3FBFC',
      fontWeight: 700
    },
  },
  '& tbody': {
    '& td': {
      p: '16px',
    },
    '& tr:not(:last-child)': {
      '& td': {
        borderBottom: '1px solid #B3B3B3',
      },
    },
  },
}

const DrugCoverage = ({plan}: {plan: PlanWithTiers}) => {
  const tiers = React.useMemo(() => {
    if (plan?.drugsTiers30Preferred?.initialCoverageDrugTiers.length) {
      return plan?.drugsTiers30Preferred?.initialCoverageDrugTiers
    } else {
      return plan?.drugsTiers30Standard?.initialCoverageDrugTiers
    }
  }, [plan]);

  const gapTiers = React.useMemo(() => {
    if (plan?.drugsTiers30Preferred?.coverageGapDrugTiers.length) {
      return plan?.drugsTiers30Preferred?.coverageGapDrugTiers
    } else {
      return plan?.drugsTiers30Standard?.coverageGapDrugTiers
    }
  }, [plan]);

  const InitialTable = () => <Box sx={{overflow: 'auto'}}>
    <Box sx={TableStyles} component={'table'} cellSpacing={0}>
      <thead>
      <tr>
        <td></td>
        <td>Preferred Retail Pharmacy cost-sharing
          (30 day supply)</td>
        <td>Standard Retail Pharmacy cost-sharing
          (30 day supply)</td>
        <td>Preferred Mail Order cost-sharing
          (90 day supply) </td>
        <td>Standard Mail Order cost-sharing
          (90 day supply)</td>
      </tr>
      </thead>
      <tbody>
      {plan?.quoteDrugsTiers?.pharmacyName && <tr>
        <td>Your pharmacy</td>
        <td colSpan={4} className={'p-0 bg-body'}><Benefit sx={{width: 'fit-content'}} className={'p-20 bg-white'} label={plan?.quoteDrugsTiers?.pharmacyName} value={true} labelClass={'regular color-primary'}/></td>
      </tr>}
      {tiers?.map((tier, i, array) => <tr key={tier.tierName}>
        <td>
          <div className={`flex`}>
            <CRTooltip arrow title={getInitialTooltipByTier(tier.tier)}>
              <Box sx={{display: 'flex', gap: 1, alignItems: 'center', mb: '4px', width: 'fit-content'}}>
                <Typography className={'bold fs-14 no-wrap'}>{tier.tierName}</Typography>
                <Info size={16} color="#666666" weight="fill" className={'w-16 h-16'} />
              </Box>
            </CRTooltip>
          </div>
        </td>
        {[
          plan?.drugsTiers30Preferred?.initialCoverageDrugTiers[i].coverage,
          plan?.drugsTiers30Standard?.initialCoverageDrugTiers[i].coverage,
          plan?.drugsTiers90Preferred?.initialCoverageDrugTiers[i].coverage,
          plan?.drugsTiers90Standard?.initialCoverageDrugTiers[i].coverage
        ].every( v => v === plan?.drugsTiers30Preferred?.initialCoverageDrugTiers[i].coverage ) ? (
          <td colSpan={4} style={{textAlign: 'center'}}>{plan?.drugsTiers30Preferred?.initialCoverageDrugTiers[i].coverage}</td>
        ) : <>
          <td>{plan?.drugsTiers30Preferred?.initialCoverageDrugTiers[i].coverage || 'n.a.'}</td>
          <td>{plan?.drugsTiers30Standard?.initialCoverageDrugTiers[i].coverage || 'n.a.'}</td>
          <td>{plan?.drugsTiers90Preferred?.initialCoverageDrugTiers[i].coverage || 'n.a.'}</td>
          <td>{plan?.drugsTiers90Standard?.initialCoverageDrugTiers[i].coverage || 'n.a.'}</td>
        </>}
      </tr>)}
      </tbody>
    </Box>
  </Box>;

  const GapTable = () => <Box sx={{overflow: 'auto'}}>
    <Box sx={TableStyles} component={'table'} cellSpacing={0}>
      <thead>
      <tr>
        <td></td>
        <td>Preferred Retail Pharmacy cost-sharing
          (30 day supply)</td>
        <td>Standard Retail Pharmacy cost-sharing
          (30 day supply)</td>
        <td>Preferred Mail Order cost-sharing
          (90 day supply) </td>
        <td>Standard Mail Order cost-sharing
          (90 day supply)</td>
      </tr>
      </thead>
      <tbody>
      {gapTiers?.map((tier, i) => <tr key={tier.tierName}>
        <td>
          <div className={`flex`}>
            <CRTooltip arrow title={getGapTooltipByTier(tier.tier)}>
              <Box sx={{display: 'flex', gap: 1, alignItems: 'center', mb: '4px', width: 'fit-content'}}>
                <Typography className={'bold fs-14 no-wrap'}>{tier.tierName}</Typography>
                <Info size={16} color="#666666" weight="fill" className={'w-16 h-16'} />
              </Box>
            </CRTooltip>
          </div>
        </td>
        {[
          plan?.drugsTiers30Preferred?.coverageGapDrugTiers[i].coverage,
          plan?.drugsTiers30Standard?.coverageGapDrugTiers[i].coverage,
          plan?.drugsTiers90Preferred?.coverageGapDrugTiers[i].coverage,
          plan?.drugsTiers90Standard?.coverageGapDrugTiers[i].coverage
        ].every( v => v === plan?.drugsTiers30Preferred?.coverageGapDrugTiers[i].coverage ) ? (
          <td colSpan={4} style={{textAlign: 'center'}}>{plan?.drugsTiers30Preferred?.coverageGapDrugTiers[i].coverage}</td>
        ) : <>
          <td>{plan?.drugsTiers30Preferred?.coverageGapDrugTiers[i].coverage || 'n.a.'}</td>
          <td>{plan?.drugsTiers30Standard?.coverageGapDrugTiers[i].coverage || 'n.a.'}</td>
          <td>{plan?.drugsTiers90Preferred?.coverageGapDrugTiers[i].coverage || 'n.a.'}</td>
          <td>{plan?.drugsTiers90Standard?.coverageGapDrugTiers[i].coverage || 'n.a.'}</td>
        </>}
      </tr>)}
      <tr>
        <td style={{borderBottom: 'none'}}>
          <Typography className={'bold fs-14 no-wrap'}>All other tiers</Typography>
        </td>
        <td colSpan={4} style={{textAlign: 'center', borderBottom: 'none'}}>25% of retail price</td>
      </tr>
      </tbody>
    </Box>
  </Box>

  return <div>
    <DrugSection title={'Deductible phase'}
                 text={'You pay the full retail price of your drugs up until you meet the deductible level (if applicable).'}
                 content={<Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, alignItems: {xs: 'flex-start', md: 'center'}, gap: 2, p: 2, borderBottom: '1px solid #B3B3B3'}}>
                   <Box flex={1}>
                     <CRTooltip arrow title={'The amount you must pay out of pocket for your prescription drugs before your coverage begins to pay.'}>
                       <Box sx={{display: 'flex', gap: 1, alignItems: 'center', mb: '4px', width: 'fit-content'}}>
                         <Typography className={'bold fs-14 no-wrap'}>Deductible Amount</Typography>
                         <Info size={16} color="#666666" weight="fill" className={'w-16 h-16'} />
                       </Box>
                     </CRTooltip>
                   </Box>
                   <Box flex={1}>
                     {[plan.drugDetails?.mrxAltDedAmount as string, plan.drugDetails?.mrxAltNoDedTier as string].filter(v => !!v).map(v => <Typography key={v}>{v}</Typography>)}
                   </Box>
                 </Box>}
    />


    <DrugSection title={'Initial Coverage Limit phase'}
                 text={plan?.drugDetails?.initialCoverageDescription || ''}
                 content={<InitialTable />}
    />


    <DrugSection title={'Coverage Gap phase'}
                 text={plan?.drugDetails?.coverageGapDescription || ''}
                 content={<GapTable />}
    />


    <DrugSection title={'Catastrophic Coverage phase'}
                 text={plan?.drugDetails?.catastrophicCoverageDescription || ''}
                 content={<Box sx={{overflow: 'auto'}}>
                   <Box sx={TableStyles} component={'table'} cellSpacing={0}>
                     <tbody>
                     {plan?.drugDetails?.catastrophicCoverage[0].items.map((item, i, array) => <tr key={item.title}>
                       <td>
                         <CRTooltip arrow title={getGapTooltipByTier([DrugTierOutput.Generic, DrugTierOutput.NonPreferredBrand][i])}>
                           <Box sx={{display: 'flex', gap: 1, alignItems: 'center', mb: '4px', width: 'fit-content'}}>
                             <Typography className={'bold fs-14 no-wrap'}>{item.title}</Typography>
                             <Info size={16} color="#666666" weight="fill" className={'w-16 h-16'} />
                           </Box>
                         </CRTooltip>
                       </td>
                       <td  style={{textAlign: 'center'}}>{item.values}</td>
                     </tr>)}
                     </tbody>
                   </Box>
                 </Box>}
    />
  </div>
}

const DrugSection = (props: {title: string, text: string, content: JSX.Element}) => {
  const [open, setOpen] = React.useState(true);

  return <div className={'mt-16'}>
    <Box sx={{display: 'flex', alignItems: 'center', cursor: 'pointer', justifyContent: 'space-between', gap: 2, mb: 2, background: '#F4F9FB', borderRadius: '16px', width: 1, p: 3}}>
      <div>
        <Typography variant={'body2'} className={'epilogue bold mb-4'}>{props.title}</Typography>
        <Typography sx={{color: '#666666'}}>{props.text}</Typography>
      </div>
      <Box sx={{
        transform: !open ? 'rotate(-180deg) !important' : 'rotate(0)',
        transition: 'all 0.3s',
      }} onClick={() => setOpen(!open)}>
        <CaretCircleDown size={32} color="#1C434F" weight="regular" />
      </Box>
    </Box>
    <Collapse in={open}>
      {props.content}
    </Collapse>
  </div>
}

const CheckMark = ({value}: {value?: boolean}) => value ? <CheckCircle size={24} color="#0B741C" weight="fill" /> : <XCircle size={24} color="#D32C1C" weight="fill" />

const Param = (props: {title: string, tooltip?: string, value: any}) => {

  const value = React.useMemo(() => {
    return convertExtraBenefitValue(props.value);
  }, [props.value])

  return <div>
    <CRTooltip arrow title={props.tooltip || ''}>
      <Box sx={{display: 'flex', gap: 1, alignItems: 'center', mb: '4px', width: 'fit-content'}}>
        <Typography className={'bold'}>{props.title}</Typography>
        {props.tooltip && <Info size={16} color="#666666" weight="fill" />}
      </Box>
    </CRTooltip>
    {value}
  </div>
}

const IncludeLine = (props: {label: string, value: boolean}) => <Box sx={{display: 'flex', gap: 1}}>
  <Box sx={{mt: '4px'}}>
    {props.value && <Check size={16} color="#0B741C" weight="regular" />}
    {!props.value && <X size={16} color="#D32C1C" weight="regular" />}
  </Box>
  <Typography >{props.label}</Typography>
</Box>


const getInitialTooltipByTier = (tier: any): string => {
  switch (tier) {
    case DrugTierOutput.PreferredGeneric: return 'Typically includes generic drugs that have been available for a long period of time. Chemically equivalent variants of brand name drugs that can be approved by the FDA once the brand\'s patent or other periods of exclusivity in the market expire.';
    case DrugTierOutput.Generic: return 'Chemically equivalent variants of brand name drugs that can be approved by the FDA once the brand\'s patent or other periods of exclusivity in the market expire.';
    case DrugTierOutput.PreferredBrand: return 'Typically the brand-name drugs that have been available for a long period of time and are widely accepted. Typically lower-cost among all brand-name drugs.';
    case DrugTierOutput.NonPreferredBrand: return 'Typically includes higher cost than Tier 3, non-preferred generic and brand-name drugs.';
    case DrugTierOutput.Specialty: return 'Typically higher cost than Tier 4, non-preferred generic and brand-name drugs.';
    case DrugTierOutput.SelectCareDrugs: return 'Typically highest cost, non-preferred generic and brand-name drugs.';
  };
  return '';
}

const getGapTooltipByTier = (tier: any): string => {
  switch (tier) {
     case DrugTierOutput.Generic: return 'Chemically equivalent versions of brand name drugs that can be approved by the FDA once the brand\'s patent or other periods of exclusivity in the market expire.';
     case DrugTierOutput.NonPreferredBrand: return 'A drug sold by a drug company under a specific name or trademark and that is protected by a patent. ';
  }
  return '';
}
