import { useEffect, useMemo } from 'react';
import { useLazyChatAccessTokenQuery } from '../../../shared/api/chat';

export default function useChatAccessToken(chatIdentityId?: string) {
  const [getToken, {data}] = useLazyChatAccessTokenQuery()

  useEffect(() => {
    if (chatIdentityId) {
      getToken({chatIdentityId})
    }
  }, [chatIdentityId]);

  return useMemo(() => {
    return data?.chatAccessToken;
  }, [data]);
}
