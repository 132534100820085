import {createAsyncThunk} from "@reduxjs/toolkit";
import {environment} from "../../../../environments/environment";
import moment from "moment";


export const fetchABTesting = createAsyncThunk(
  'root/fetchABTesting',
  async () => {
    const data = await fetch(
      environment.strapiUrl + `/api/a-b-tests`,
      {
        headers: {
          "content-type": "application/json",
          'Authorization': 'Bearer ' + process.env.NX_STRAPI_TOKEN
        },
        cache: 'no-cache',
      }
    ).then(res => res.json())
    const tests: ABTest[] = data.data.map((i: {attributes: ABTest}) => i.attributes);

    return tests.filter(t => {
      if (t.from && t.to) {
        return moment().isBetween(moment(t.from), moment(t.to));
      } else if (t.from) {
        return moment().isAfter(moment(t.from))
      } if (t.to) {
        return moment().isBefore(moment(t.to))
      }
      return true;
    })
  }
)

export type ABTestType = 'test';

interface ABTest {
  active: boolean,
  from: string | null,
  to: string | null,
  key: ABTestType,
}

