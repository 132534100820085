import { LazyQueryHookOptions, QueryOptions, useLazyQuery } from '@apollo/client';
import {gql} from "@apollo/client";
import { QueryPercentOfZeroPremiumMedicarePlansArgs } from '@coverright/data-access/types/medicare';
import { client } from '@coverright/data-access/apollo-clients';

export function usePercentOfZeroPremiumMedicarePlans(options?: LazyQueryHookOptions<{percentOfZeroPremiumMedicarePlans: string}, QueryPercentOfZeroPremiumMedicarePlansArgs>) {
  return useLazyQuery<{percentOfZeroPremiumMedicarePlans: string}, QueryPercentOfZeroPremiumMedicarePlansArgs>(
    gql(getDrugRequest),
    {
      fetchPolicy: 'no-cache',
      ...options
    }
  );
}

export function percentOfZeroPremiumMedicarePlans(options?: Partial<QueryOptions<QueryPercentOfZeroPremiumMedicarePlansArgs>>) {
  return client.query<{percentOfZeroPremiumMedicarePlans: string}, QueryPercentOfZeroPremiumMedicarePlansArgs>({
    query: gql(getDrugRequest),
    fetchPolicy: 'no-cache',
    ...options
  }).then(res => res.data.percentOfZeroPremiumMedicarePlans);
}


const getDrugRequest = `
query ($county: String!, $zip: String!, $planTypes: [MedicarePlanType!]) {
  percentOfZeroPremiumMedicarePlans(zip: $zip, county: $county, planTypes: $planTypes, allowAllStates: true)
}
`;
