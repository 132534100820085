import {useAppSelector} from "../../../shared";
import {medigapQuote, mgFilters, mgSortBy} from "../../../entities";
import {useMemo, useState} from "react";
import {PlansDictionary} from "../../../widgets/mg-plan-list/lib/types";
import {useLazyMgPlanListQuery} from "../../../shared/api/medigap";
import {useSnackbar} from "notistack";
import {Sort} from "@coverright/data-access/types/medicare";
import {addDetailsToMGPlanList} from "@coverright/data-access/medigap";
import * as _ from "lodash";
import {useDebouncedEffect} from "@coverright/utils";
import {mgComparisonPlans} from "../../../features";
import {MedigapPlansField} from "@coverright/data-access/types/medigap";

export function useMgComparisonPlanList() {
  const quote = useAppSelector(medigapQuote);
  const list = useAppSelector(mgComparisonPlans);
  const filters = useAppSelector(mgFilters);
  const [plans, setPlans] = useState<PlansDictionary>({});

  const [getPlans, { data, isFetching, isUninitialized , isSuccess}] = useLazyMgPlanListQuery();
  const { enqueueSnackbar } = useSnackbar();

  const loadPlans = async () =>  {
    if (filters && quote?.medigapFilterState) {
      try {
        const {planCategory, id, ...quoteFilters} = quote.medigapFilterState
        const response = await getPlans({
          filter: {
            ...quoteFilters,
            companies: [],
            monthlyPlanPremiumRanges: [],
            planNames: [],
          },
          page: {
            page: 0,
            size: 1000
          },
          sort: [{
            direction: Sort.Asc,
            field: MedigapPlansField.PlanName
          }]
        });

        if (response.data?.medigapPlans.data) {
          const data = response.data.medigapPlans.data;

          const detailed = addDetailsToMGPlanList(data);

          if (detailed) {
            const plans = _.groupBy(detailed, 'planName')
            return _.pick(plans, Object.keys(plans).filter(key => (
              list.some(fp => fp.id === key)
            )));
          }
          return {} as PlansDictionary;
        } else {
          throw Error('Mg plan list data is wrong');
        }
      } catch (e) {
        console.error(e);
        enqueueSnackbar('Error loading plans', {variant: 'error'});
      }

    } else {
      console.warn('No filters in Mg list')
    }
    return {};
  }

  useDebouncedEffect(() => {
    loadPlans().then(setPlans)
  }, 600, [filters])

  const total = useMemo(() => {
    return +data?.medigapPlans.totalElements || 0
  }, [data]);

  return {plans, total, isLoading: isFetching || isUninitialized, isSuccess}
}


