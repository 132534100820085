import React, { MutableRefObject, useRef } from 'react';
import { Card } from '@mui/material';
import { DrugsTab, PharmacyTab } from '../../widgets';
import {
  medicareQuote,
  preferredDrugs,
  preferredPharmacies,
  drugDeliveryType,
  useSaveDrugs,
  useSavePharmacies,
} from '../../entities';
import { V3Preloader } from '@coverright/ui/shared';
import {
  SideLayout,
  StyledTabs,
  useAppSelector,
  hasEnrollments,
  enrolledPlan as enrolledPlanSelector,
} from '../../shared';
import usePlanYear from './lib/use-plan-year';
import { getTitle, getTabs, getTabIndex } from './lib/lib';
import DiscountAlert from '../../widgets/discount-alert';
import { DrugCostBreakdown } from '@coverright/widgets';

export default function () {
  const quote = useAppSelector(medicareQuote);
  const hasEnrollment = useAppSelector(hasEnrollments);
  const { save: saveDrugs } = useSaveDrugs();
  const { save: savePharmacies } = useSavePharmacies();
  const drugs = useAppSelector(preferredDrugs);
  const pharmacies = useAppSelector(preferredPharmacies);
  const deliveryType = useAppSelector(drugDeliveryType);
  const offersRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

  const enrolledPlan = useAppSelector(enrolledPlanSelector);

  const planYear = usePlanYear(enrolledPlan?.year);

  if (!quote) {
    return <V3Preloader sx={{ my: 6 }} />;
  }

  const onDiscountAlertClick = () => {
    if (offersRef.current) {
      offersRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <SideLayout
      title={getTitle(hasEnrollment)}
      subtitle={
        'Check if your medications are covered and add them to your drug list.'
      }
      slots={{
        start: enrolledPlan ? (
          <DiscountAlert
            plan={enrolledPlan}
            onClick={onDiscountAlertClick}
            quoteId={quote?.id}
            clientId={quote?.clientId}
          />
        ) : undefined,
      }}
    >
      <Card sx={{ p: 3 }}>
        <StyledTabs tabs={getTabs(quote)} initialIndex={getTabIndex()}>
          <DrugsTab value={drugs} onChange={saveDrugs} />
          <PharmacyTab
            value={pharmacies}
            onChange={savePharmacies}
            orderType={deliveryType}
          />
        </StyledTabs>
      </Card>

      {!!enrolledPlan && !!drugs.length && (
        <div ref={offersRef}>
          <DrugCostBreakdown
            bidId={enrolledPlan.bidId}
            zip={enrolledPlan.zip!}
            countyName={enrolledPlan.county!}
            planYear={planYear}
            quoteId={quote?.id}
            clientId={quote?.clientId}
            type={enrolledPlan.type}
          />
        </div>
      )}
    </SideLayout>
  );
}
