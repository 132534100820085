import ListCard from './list-card';
import { useNavigate } from 'react-router';
import {routes} from "../../../../app/router-new";
import {useAppSelector} from "../../../../shared";
import {preferredPharmacies} from "../../../../entities";

export default function PharmaciesListCard() {
  const pharmacies = useAppSelector(preferredPharmacies);
  const navigate = useNavigate();

  return <ListCard list={(pharmacies || []).map(p => ({title: p.name, subtitle: p.address}))}
                   onEditClick={() => navigate(routes.drugs.value + '?tab=pharmacy')}
                   noDataLabel={'No pharmacies have been added yet.'}
                   title={'Pharmacies'} />
}
