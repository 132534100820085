import { useAppSelector } from '../../../shared/hooks';
import { medicareQuote } from '../../ma-quote';
import { useLogEvent } from '@coverright/shared/analytics';
import { useSnackbar } from 'notistack';
import { usePatchClientPersonalInfoMutation } from '../../../shared/api/enrollment';
import {
  convertPreferredDrugInput,
  PreferredDrugOutput,
} from '@coverright/widgets';

export function useSaveDrugs() {
  const [mutation, { isLoading, error }] = usePatchClientPersonalInfoMutation();
  const quote = useAppSelector(medicareQuote);
  const logEvent = useLogEvent();
  const { enqueueSnackbar } = useSnackbar();

  const save = async (drugs: PreferredDrugOutput[]) => {
    if (quote) {
      const preferredDrugs = drugs.map(convertPreferredDrugInput);
      if ((window as any).track && preferredDrugs.length) {
        logEvent('Drugs Added', {
          rxcuis: preferredDrugs,
          location: 'Plan finder',
        });
      }

      try {
        await mutation({
          clientId: quote.clientId,
          preferredDrugs,
        }).unwrap();
        enqueueSnackbar('Drugs has been saved', { variant: 'success' });
      } catch (e) {
        enqueueSnackbar('Error saving drugs', { variant: 'error' });
      }
    } else {
      throw Error('Medicare quote is not exists');
    }
  };

  return { save, isLoading };
}
