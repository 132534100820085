import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import * as _ from "lodash";


type ComparisonSlice = {
  maPlans: ComparisonPlan[],
  mgPlans: ComparisonPlan[],
}

const initialState: ComparisonSlice = {
  maPlans: JSON.parse(sessionStorage.getItem('MA_COMPARISON') || '[]'),
  mgPlans: JSON.parse(sessionStorage.getItem('MG_COMPARISON') || '[]'),
}

const slice = createSlice({
  name: 'comparison',
  initialState,
  reducers: {
    resetComparisonPlans(state, {payload}: PayloadAction<ComparisonPlanType>) {
      switch (payload) {
        case 'MA': state.maPlans = []; break;
        default: state.mgPlans = [];
      }
    },
    addPlanToComparison(state, {payload}: PayloadAction<{type: ComparisonPlanType, plan: ComparisonPlan}>) {
      switch (payload.type) {
        case 'MA': {
          state.maPlans = _.uniq([...state.maPlans, payload.plan]);
          sessionStorage.setItem('MA_COMPARISON', JSON.stringify(state.maPlans));
        } break;
        default: {
          state.mgPlans = _.uniq([...state.mgPlans, payload.plan]);
          sessionStorage.setItem('MG_COMPARISON', JSON.stringify(state.mgPlans));
        }
      }

    },
    removePlanFromComparison(state, {payload}: PayloadAction<{type: ComparisonPlanType, plan: ComparisonPlan}>) {
      switch (payload.type) {
        case 'MA': state.maPlans = state.maPlans.filter(p => p.id !== payload.plan.id); break;
        default: state.mgPlans = state.mgPlans.filter(p => p.id !== payload.plan.id);
      }
    }
  },
  selectors: {
    maComparisonPlans(state) {
      return state.maPlans;
    },
    mgComparisonPlans(state) {
      return state.mgPlans;
    },
  },
})

export const comparisonReducer = slice.reducer;

export const {
  maComparisonPlans,
  mgComparisonPlans
} = slice.selectors;

export const {
  resetComparisonPlans,
  addPlanToComparison,
  removePlanFromComparison
} = slice.actions

export type ComparisonPlanType = 'MA' | 'MG';
export type ComparisonPlan = {name: string, id: string};
