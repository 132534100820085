import { Box } from '@mui/material';
import React from 'react';
import { SideLayout } from '../../shared';
import { PdpPlanList } from '../../widgets';
import { BackButton, SwitchToMarketplaceButton, withPdpFiltersContext } from '../../features';
import { routes } from '../../app/router-new';
import { PdpFilters } from "../../widgets";
import { useNavigate } from 'react-router';
import useCreatePdpQuoteIfNotExists from './api/use-create-pdp-quote-if-not-exists';

function PlanFinderPdp() {
  useCreatePdpQuoteIfNotExists();
  const navigate = useNavigate();
  return <SideLayout title={'Prescription Drug Plans'}
                     subtitle={<BackButton label={'Back'}
                                           onClick={() => navigate(routes.planFinderMG.value)} />}
                     slots={{
                       start: <PdpFilters />,
                       headerSide: <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                         <SwitchToMarketplaceButton to={'MG'} />
                       </Box>
                     }}
  >
    <PdpPlanList />
  </SideLayout>
}

export default withPdpFiltersContext(PlanFinderPdp);
