import React, { useEffect } from 'react';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

import App from './app/app';
import { SnackbarProvider } from 'notistack';
import { initTrackEvent } from '@coverright/shared/analytics';
import { Provider as HttpProvider } from 'use-http';
import {Provider as ReduxProvider} from 'react-redux'
import { environment } from './environments/environment';
import {store} from "./app/app/store";
import { initKeycloak, KeycloakProvider } from '@coverright/shared/keycloak';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://d4f5af392fdf371924bd08d8817eb5b4@o4507526906511360.ingest.us.sentry.io/4507526909460480",
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  environment: environment.production ? 'production' : 'develop',
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/*"localhost",*/ /^https:\/\/v3\.coverright\.com\/plan-finder/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


const root = createRoot(document.getElementById('root') as HTMLElement)

initApp().then(() => {
  root.render(
    <ReduxProvider store={store}>
      <BrowserRouter basename={'/plan-finder'}>
        <SnackbarProvider preventDuplicate classes={{
          variantSuccess: 'success-snack'
        }} anchorOrigin={{horizontal: 'left', vertical: 'bottom'}} maxSnack={3} autoHideDuration={6000}>
          <HttpProvider url={environment.userFlowRestUrl}>
            <KeycloakProvider>
              <App />
            </KeycloakProvider>
          </HttpProvider>
        </SnackbarProvider>
      </BrowserRouter>
    </ReduxProvider>
  );
})



async function initApp() {
  await initKeycloak({
    onLoad: 'login-required'
  })
  initTrackEvent('plan-finder');
}
