import { Card, Grid, Typography } from '@mui/material';
import React from 'react';
import { Video, VideoCard } from '../../../entities';

interface Props {
  videos: Video[],
  onVideoClick: (id: number) => void,
  isVideoCompleted: (id: number) => boolean,
}
export function WatchNext({videos, onVideoClick, isVideoCompleted}: Props) {
  return <Card sx={{maxWidth: {md: 326}}}>
    <Typography className={'fs-24 mb-16'} variant={'h4'}>Watch next </Typography>
    <Grid spacing={2} container>
      {videos.map(v =><Grid key={v.id} xs={12} sm={6} md={12}  item>
        <VideoCard onClick={() => onVideoClick(v.id)}
                   completed={isVideoCompleted(v.id)}
                   video={v}/>
      </Grid>)}
    </Grid>
  </Card>
}
