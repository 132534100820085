import {useLazyPdpCompaniesQuery,} from '../../shared/api/medicare';
import React from 'react';
import CompanyFilter from './ui/company-filter';
import {Box, Collapse, Typography} from "@mui/material";
import {PlanYear} from "@coverright/data-access/types/medicare";
import {useDebouncedEffect} from "@coverright/utils";
import SortBySelect from "./ui/sort-by-select";
import {usePdpFilters, YearToYearSwitch} from '../../features';

export function PdpFilters() {
  const { filters, buildHandler } = usePdpFilters();
  const [getCompanies, {isSuccess}] = useLazyPdpCompaniesQuery();

  useDebouncedEffect(() => {
    if (filters) {
      getCompanies({filters});
    }
  }, 800, [filters]);

  return <Collapse in={isSuccess}>
    <Box sx={{
      display: 'flex',
      alignItems: {xs: 'flex-start', lg: 'center'}, gap: 2,
      flexDirection: {xs: 'column', lg: 'row'},
      background: {xs: 'white', lg: 'none'},
      p: {xs: 3, lg: 0},
      borderRadius: '16px'
    }}>
      <Typography variant={'body2'} sx={{whiteSpace: 'nowrap'}}>Filter By</Typography>
      <CompanyFilter value={filters?.companies || []} onChange={buildHandler('companies')} />
      <SortBySelect />
      <YearToYearSwitch checked={filters?.planYear === PlanYear.Year2024}
                        onChange={buildHandler('planYear')}/>
    </Box>
  </Collapse>
}
