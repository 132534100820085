import { api } from './chat-api';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A type representing a string identifier */
  UUID: { input: any; output: any; }
};

export type Mutation = {
  __typename?: 'Mutation';
  bindChatIdentityId: Scalars['Boolean']['output'];
  createConversation: Scalars['Boolean']['output'];
  createConversationForClient: Scalars['Boolean']['output'];
};


export type MutationBindChatIdentityIdArgs = {
  chatIdentityId: Scalars['UUID']['input'];
};


export type MutationCreateConversationForClientArgs = {
  clientId: Scalars['UUID']['input'];
  clientUserId: Scalars['UUID']['input'];
};

export type Query = {
  __typename?: 'Query';
  chatAccessToken: Scalars['String']['output'];
  chatIdentityId?: Maybe<Scalars['UUID']['output']>;
  test: Scalars['Boolean']['output'];
};


export type QueryChatAccessTokenArgs = {
  chatIdentityId: Scalars['UUID']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  testSubscription: Scalars['Int']['output'];
};

export enum UserRole {
  RoleAdmin = 'ROLE_ADMIN',
  RoleAgentManager = 'ROLE_AGENT_MANAGER',
  RoleCsr = 'ROLE_CSR',
  RoleNone = 'ROLE_NONE',
  RoleTechStaff = 'ROLE_TECH_STAFF',
  RoleUnauthorized = 'ROLE_UNAUTHORIZED',
  RoleUser = 'ROLE_USER'
}

export type ChatIdentityIdQueryVariables = Exact<{ [key: string]: never; }>;


export type ChatIdentityIdQuery = { __typename?: 'Query', chatIdentityId?: any | null };

export type ChatAccessTokenQueryVariables = Exact<{
  chatIdentityId: Scalars['UUID']['input'];
}>;


export type ChatAccessTokenQuery = { __typename?: 'Query', chatAccessToken: string };

export type BindChatIdentityIdMutationVariables = Exact<{
  chatIdentityId: Scalars['UUID']['input'];
}>;


export type BindChatIdentityIdMutation = { __typename?: 'Mutation', bindChatIdentityId: boolean };

export type CreateConversationMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateConversationMutation = { __typename?: 'Mutation', createConversation: boolean };


export const ChatIdentityIdDocument = `
    query chatIdentityId {
  chatIdentityId
}
    `;
export const ChatAccessTokenDocument = `
    query chatAccessToken($chatIdentityId: UUID!) {
  chatAccessToken(chatIdentityId: $chatIdentityId)
}
    `;
export const BindChatIdentityIdDocument = `
    mutation bindChatIdentityId($chatIdentityId: UUID!) {
  bindChatIdentityId(chatIdentityId: $chatIdentityId)
}
    `;
export const CreateConversationDocument = `
    mutation createConversation {
  createConversation
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    chatIdentityId: build.query<ChatIdentityIdQuery, ChatIdentityIdQueryVariables | void>({
      query: (variables) => ({ document: ChatIdentityIdDocument, variables })
    }),
    chatAccessToken: build.query<ChatAccessTokenQuery, ChatAccessTokenQueryVariables>({
      query: (variables) => ({ document: ChatAccessTokenDocument, variables })
    }),
    bindChatIdentityId: build.mutation<BindChatIdentityIdMutation, BindChatIdentityIdMutationVariables>({
      query: (variables) => ({ document: BindChatIdentityIdDocument, variables })
    }),
    createConversation: build.mutation<CreateConversationMutation, CreateConversationMutationVariables | void>({
      query: (variables) => ({ document: CreateConversationDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useChatIdentityIdQuery, useLazyChatIdentityIdQuery, useChatAccessTokenQuery, useLazyChatAccessTokenQuery, useBindChatIdentityIdMutation, useCreateConversationMutation } = injectedRtkApi;

