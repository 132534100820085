import React from 'react';
import { Card } from '@mui/material';
import DoctorsTab from './ui/doctors-tab';
import { DrugsTab, PharmacyTab } from '../../widgets';
import {getTabIndex, getTabs} from "./lib/lib";
import {SideLayout, StyledTabs, useAppSelector} from "../../shared";
import {
  drugDeliveryType,
  medicareQuote,
  preferredDoctors,
  preferredDrugs,
  preferredPharmacies,
  useSaveDrugs,
  useSavePharmacies,
  useSaveDoctors
} from "../../entities";

export default function() {
  const quote = useAppSelector(medicareQuote);
  const {save: saveDrugs} = useSaveDrugs();
  const {save: savePharmacies} = useSavePharmacies();
  const {save: saveDoctors} = useSaveDoctors();
  const doctors = useAppSelector(preferredDoctors);
  const drugs = useAppSelector(preferredDrugs);
  const pharmacies = useAppSelector(preferredPharmacies);
  const deliveryType = useAppSelector(drugDeliveryType);


  return <SideLayout title={'Doctors & Drugs'}
                     subtitle={'Want to find the right plan that covers all of your needs? Enter your doctors and drugs to see what plan covers you best.'}>

    <Card sx={{p: 3}}>
      <StyledTabs tabs={getTabs(quote)}
                  initialIndex={getTabIndex()}
                  id={'doctors-drugs-tabs'}
      >
        <DoctorsTab value={doctors} onChange={saveDoctors} />
        <DrugsTab value={drugs} onChange={saveDrugs} />
        <PharmacyTab value={pharmacies}
                     onChange={savePharmacies}
                     orderType={deliveryType}
        />
      </StyledTabs>

    </Card>
  </SideLayout>
}
