import { CRModal } from '@coverright/ui/shared';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { X } from '@phosphor-icons/react';
import React from 'react';
import BadgeAnimation from '../../../components/animations/BadgeAnimation';

export function CompletedModal(props: {open: boolean, onClose: (goToNext?: boolean) => void}) {

  return <CRModal BackdropProps={{
    sx: {
      background: 'rgba(0, 0, 0, 0.25)',
    }
  }} width={480} height={600} paperPadding={0} open={props.open} onClose={() => props.onClose()}>
    <>
      <Box sx={{display: 'flex', width: 1, p: 3, justifyContent: 'flex-end', alignItems: 'flex-start', flex: 1, zIndex: 1}}>
        <IconButton
          size="small"
          edge="start"
          onClick={() => props.onClose()}
        >
          <X size={32} color="#000" />
        </IconButton>
      </Box>
      <Box sx={{position: 'absolute', display: 'flex', width: 1, justifyContent: 'center'}}>
        <BadgeAnimation height={500} width={480} />
      </Box>
      <Typography sx={{px: 3}} className={'epilogue fs-32 lh-48 semi-bold'} align={'center'}>
        You've completed the CoverRight Citizen Expert course! Great work!
      </Typography>
      <Box sx={{display: 'flex', p: 3, width: 1}}>
        <Button fullWidth variant={'contained'} color={'primary'} onClick={() => props.onClose(true)}>Cool! Let’s wrap this up!</Button>
      </Box>
    </>
  </CRModal>
}
