import {Box, Card, Typography} from "@mui/material";
import { ChatMessage } from '../lib';


interface Props {
  avatar?: JSX.Element | null,
  message: ChatMessage,
  direction: "outgoing" | "incoming",
}

export default function Message({message: {text, date}, avatar = null, direction}: Props) {
  return <Box sx={{display: 'flex', flexDirection: direction === 'outgoing' ? 'row-reverse' : 'row', gap: 1, alignItems: 'center', width: 1}}>
    {avatar}
    <Box sx={{display: 'flex', flexDirection: 'column', gap: .5, alignItems: direction === 'outgoing' ? 'flex-end' : 'flex-start', width: 1}}>
      <Box sx={{maxWidth: '65%'}}>
        <Typography className={'fs-18'} sx={{whiteSpace: 'pre-wrap'}}>{text}</Typography>
      </Box>
      <Typography color={'textSecondary'} className={'fs-12'}>{date?.toLocaleTimeString()}</Typography>
    </Box>
  </Box>
}
