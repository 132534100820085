import React from "react";
import {
  Box,
  Button,
  IconButton, Typography
} from '@mui/material';
import { X } from '@phosphor-icons/react';
import { CRModal } from '@coverright/ui/shared';


interface ConfirmDialogContextData {
  content: string | JSX.Element,
  okButtonTitle?: string,
  cancelButtonTitle?: string,
  width?: string | number,
}

export const ConfirmDialogContext = React.createContext((data: ConfirmDialogContextData) => new Promise(resolve => {}))

export function ConfirmDialogProvider({ children }: { children: any }) {
  const ref = React.useRef();
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState<ConfirmDialogContextData>();

  const confirm = (data: ConfirmDialogContextData) => {
    setState(data)
    setOpen(true);
    return new Promise<boolean>((resolve) => {
      (ref as any).current = resolve;
    })
  }

  const fn = (choice: boolean) => {
    (ref as any).current(choice)
    ref.current = undefined;
  }

  return (
    <ConfirmDialogContext.Provider value={confirm}>
      {children}
      {state?.content && <Modal
        {...state}
        open={open}
        onClose={(value?: boolean) => {
          setOpen(false);
          fn(!!value);
        }}
      />}
    </ConfirmDialogContext.Provider>
  )
}

export const withConfirmDialog = (WrappedComponent: any) => (props: any) => {
  return (
    <ConfirmDialogProvider>
      <WrappedComponent {...{...props}} />
    </ConfirmDialogProvider>
  )
}

export function useConfirm() {
  return React.useContext(ConfirmDialogContext)
}


interface ConfirmModalProps {
  open: boolean,
  onClose: (value?: boolean) => void,
  okButtonTitle?: string,
  content: string | JSX.Element,
  cancelButtonTitle?: string,
  width?: string | number,
}

function Modal(props: ConfirmModalProps) {
  return <CRModal BackdropProps={{
      sx: {
        background: 'rgba(0, 0, 0, 0.25)',
      }
    }} width={props.width || 640} paperPadding={'24px'} open={props.open} onClose={() => props.onClose(false)}>
      <>
        <Box sx={{display: 'flex', width: 1, justifyContent: 'flex-end'}}>
          <IconButton onClick={() => props.onClose(false)}>
            <X size={32} color="#000" />
          </IconButton>
        </Box>
        {typeof props.content === 'string' && <Typography variant={'h4'} align={'center'}>{props.content}</Typography>}
        {typeof props.content !== 'string' && <>{props.content}</>}

        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, width: 1, gap: 3, justifyContent: {md: 'center'}, mt: 3}}>
          <Button variant={'outlined'} color={'primary'} data-test={'confirm-button-cancel'} onClick={() => props.onClose(false)}>{props.cancelButtonTitle || 'Cancel'}</Button>
          <Button variant={'contained'} color={'primary'} data-test={'confirm-button-ok'} onClick={() => props.onClose(true)}>{props.okButtonTitle || 'Delete'}</Button>
        </Box>
      </>
    </CRModal>
}
