import { CRModal } from '@coverright/ui/shared';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { X } from '@phosphor-icons/react';
import * as React from 'react';
import { useLogEvent } from '@coverright/shared/analytics';
import { VideoPlayer } from '@coverright/features';
import { useRef } from 'react';

interface VideoModalProps {
  open: boolean;
  onClose: () => void;
  url: string;
  title: string;
}

export default function VideoModal(props: VideoModalProps) {
  const logEvent = useLogEvent();
  const playerRef = useRef<null | { getCurrentTime: () => number }>(null);

  const onClose = () => {
    logEvent('Play video duration', {
      duration: playerRef.current?.getCurrentTime(),
      name: props.title,
      url: document.location.href,
    });
    props.onClose();
  };

  return (
    <CRModal
      BackdropProps={{
        sx: {
          background: 'rgba(0, 0, 0, 0.25)',
        },
      }}
      width={950}
      paperPadding={'32px'}
      open={props.open}
      onClose={onClose}
    >
      <>
        <Box sx={{ display: 'flex', width: 1, justifyContent: 'flex-end' }}>
          <IconButton size="small" edge="start" onClick={onClose}>
            <X size={32} color="#000" weight="fill" />
          </IconButton>
        </Box>

        <Typography
          className={'epilogue fs-32 lh-48 mv-24 semi-bold'}
          align={'center'}
        >
          {props.title}
        </Typography>

        <VideoPlayer videoUrl={props.url} ref={playerRef} />
      </>
    </CRModal>
  );
}
