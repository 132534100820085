import React from 'react';
import { environment } from '../../environments/environment';

export function useBrowsingALotModal() {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setShow(environment.production)
    }, 300000)
  }, [])

  return { show, setShow };
}
