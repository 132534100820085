import { useLocation } from 'react-router';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import {
  client, enrollmentClient,
  setErrorHandler,
} from '@coverright/data-access/apollo-clients';
import { delegate, getQuoteId, resetStoredUser } from '@coverright/utils';
import {
  identifyHotjar,
  useLogEvent,
  useTrackAppointment,
} from '@coverright/shared/analytics';
import {ApolloProvider} from '@apollo/client';
import { ThemeProvider } from '@mui/material';
import { V3Theme } from '@coverright/ui/themes';
import {
  AgentContextProvider,
  MedigapQuoteProvider,
  PdpQuoteProvider,
  ProfileContext,
  QuoteContext,
  QuoteProvider,
  UserTestContextProvider,
  withAppContextProvider,
} from '@coverright/shared/contexts';
import * as _ from 'lodash';
import Theme from './theme';
import { Cookies } from 'react-cookie';
import Layout from './layout';
import { environment } from '../../environments/environment';
import { ConfirmDialogProvider } from '../components/confirm-dialog-context';
import {CloseModal, useApplyClientAgent} from '@coverright/features';
import { useBrowsingALotModal } from '../hooks/use-browsing-a-lot-modal';
import BrowsingALotModal from '../components/browsing-a-lot-modal';
import { PostEnrollmentProvider } from '../components/post-enrollment-context';
import {
  useHasEnrollmentsQuery,
  useUserProfileQuery,
} from '../shared/api/enrollment';
import {
  useLazyMedicareQuoteQuery,
  useLazyPdpQuoteQuery,
} from '../shared/api/medicare';
import { useLazyMedigapQuoteQuery } from '../shared/api/medigap';
import Router from './router-new';
import { fetchABTesting, useAppDispatch } from '../shared';
import { ChatContextProvider } from '../widgets';
import { fetchPersistentState } from '../entities';
const cookie = new Cookies();

function Index() {
  const { enqueueSnackbar } = useSnackbar();
  useTrackAppointment();

  // todo refactor this!!!!!
  setErrorHandler(({ graphQLErrors, networkError }: any) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path, extensions }: any) => {
        if (extensions?.code === 'PLAN_IS_MISSING') {
          return;
        }
        switch (extensions?.status) {
          case '403':
            {
              resetStoredUser();
              document.location.reload();
            }
            break;
          default: {
            console.error(
              `Message: ${message}, Location: ${JSON.stringify(
                locations
              )}, Path: ${path}`
            );
            enqueueSnackbar('Network error, please try again later', {
              variant: 'error',
            });
          }
        }
      });
  });

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={V3Theme}>
        <ThemeProvider theme={Theme}>
          <ChatContextProvider>
            <ConfirmDialogProvider>
              <AgentContextProvider>
                <UserTestContextProvider>
                  <QuoteProvider quoteId={getQuoteId()}>
                    <MedigapQuoteProvider>
                      <PdpQuoteProvider>
                        <PostEnrollmentProvider>
                          <Inner />
                        </PostEnrollmentProvider>
                      </PdpQuoteProvider>
                    </MedigapQuoteProvider>
                  </QuoteProvider>
                </UserTestContextProvider>
              </AgentContextProvider>
            </ConfirmDialogProvider>
          </ChatContextProvider>
        </ThemeProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default _.flowRight([withAppContextProvider])(
  Index
);

const Inner = () => {
  const location = useLocation();
  const logEvent = useLogEvent();

  const profile = React.useContext(ProfileContext);
  const quote = React.useContext(QuoteContext);
  const { show: browsingALotOpen, setShow: setBrowsingALotOpen } =
    useBrowsingALotModal();
  useInitApp();
  useApplyClientAgent(enrollmentClient, environment.cdnUrl, quote?.clientId);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (
      !sessionStorage.getItem('utm_source') ||
      sessionStorage.getItem('utm_source') === 'null'
    ) {
      if (urlParams.has('utm_source')) {
        sessionStorage.setItem(
          'utm_source',
          urlParams.get('utm_source') as string
        );
      } else if (cookie.get('__opix_utm')) {
        const utm_source = cookie.get('__opix_utm').utm_source;
        if (utm_source) {
          sessionStorage.setItem('utm_source', utm_source);
        }
      }
    }

    delegate(document, 'click', 'button', function (event) {
      logEvent('click', {
        element_title: event.target.textContent,
        element_id: event.target.id,
      });
    });
  }, []);

  React.useEffect(() => {
    identifyHotjar();
    if (location.pathname) {
      const pageName = _.startCase(_.last(location.pathname.split('/')));
      logEvent('page_view', { page_title: pageName });
    }
  }, [location.pathname]);

  React.useEffect(() => {
    if (
      profile.profileId &&
      quote.id &&
      (!quote.zip ||
        !quote.county ||
        !profile.gender ||
        !profile.birthDate ||
        typeof profile.tobacco !== 'boolean')
    ) {
      document.location.href =
        environment.dashboardUrl + 'schedule-onboarding/basic-info';
    }
  }, [profile, quote]);

  return (
    <>
      <CloseModal />
      <BrowsingALotModal
        open={browsingALotOpen}
        onClose={() => setBrowsingALotOpen(false)}
      />
      <Layout
        slots={{
          router: <Router />,
        }}
      />
    </>
  );
};

async function useInitApp() {
  useHasEnrollmentsQuery();
  const dispatch = useAppDispatch();
  const { data: profileData } = useUserProfileQuery();
  const [getMaQuote] = useLazyMedicareQuoteQuery();
  const [getPdpQuote] = useLazyPdpQuoteQuery();
  const [getMgQuote] = useLazyMedigapQuoteQuery();

  const profile = useMemo(() => {
    return profileData?.userProfile;
  }, [profileData]);

  useEffect(() => {
    if (profile) {
      dispatch(fetchABTesting());

      if (profile.quoteId) {
        getMaQuote({ id: profile.quoteId });
        dispatch(fetchPersistentState(profile.quoteId));
      }
      if (profile.pdpQuoteId) {
        getPdpQuote({ id: profile.pdpQuoteId });
      }
      if (profile.medigapQuoteId) {
        getMgQuote({ id: profile.medigapQuoteId });
      }
    }
  }, [profile]);
}
