import Message from '../ui/message';
import { Button, Collapse } from '@mui/material';
import * as React from 'react';
import { useDebouncedEffect } from '@coverright/utils';
import { useState } from 'react';
import { CRTooltip } from '@coverright/ui/inputs';


interface Props {
  isTherePlanWithFullDrugCoverage: boolean,
  preferredDrugsLength: number,
}
export default function(props: Props) {
  const [open, setOpen] = useState(false);

  useDebouncedEffect(() => {
    /*if (props.rating?.length && !filters?.rating?.includes(Rating.None) && planFilterData?.starRatings?.find(i => i.value === 'NONE')?.label !== 'Not rated') {
        setStarRatingOpen(true);
      } else {
        setStarRatingOpen(false);
      }*/
  }, 500, []);

  return <Collapse in={open}>
    <Message title={'Some plans are too new to be given a star rating.'}
             description={<>Your current filter settings will not show plans yet to be given a <CRTooltip placement={'bottom'} arrow title={'The Star Rating is an overall rating based on the plan\'s quality and performance across the different types of services that the plan offers. \n\n For plans covering health services, this includes an overall rating of the quality of services of 5 main categories: Staying healthy (screening tests and vaccines); Managing chronic (long-term) conditions; Member experience with the health plan; Member complaints and changes with the health plan\'s performance; and Health plan customer service. The data to determine these ratings is sourced from member surveys conducted by Medicare, information from clinicians, information submitted by plans, and results from Medicare\'s monitoring activities.'}><span className={'underline medium'}>Star Rating</span></CRTooltip>. The Centers for Medicare & Medicaid Services (CMS) provides <CRTooltip placement={'bottom'} arrow title={'The Star Rating is an overall rating based on the plan\'s quality and performance across the different types of services that the plan offers. \n\n For plans covering health services, this includes an overall rating of the quality of services of 5 main categories: Staying healthy (screening tests and vaccines); Managing chronic (long-term) conditions; Member experience with the health plan; Member complaints and changes with the health plan\'s performance; and Health plan customer service. The data to determine these ratings is sourced from member surveys conducted by Medicare, information from clinicians, information submitted by plans, and results from Medicare\'s monitoring activities.'}><span className={'underline medium'}>Star Ratings</span></CRTooltip> for plans each year. However, if a plan is new to your area it might not yet be awarded a star-rating.  Not having a star rating yet does necessarily imply the plan is low quality.</>}
             onClose={() => setOpen(false)}
    />
  </Collapse>
}

