import {MedicareQuoteQuery} from "../../../shared/api/medicare";
import {FirstAidKit, Pill, Prescription} from "@phosphor-icons/react";
import {Typography} from "@mui/material";
import React from "react";

export function getTabIndex() {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('tab')) {
    if (urlParams.get('tab') === 'drugs') {
      return 1;
    } else if (urlParams.get('tab') === 'pharmacy') {
      return 2;
    }
  }
  return 0;
}

export const getTabs = (quote: MedicareQuoteQuery['medicareQuote']) => [
  {
    icon: FirstAidKit,
    label: <div>
      <Typography variant={'body2'} className={'medium epilogue'} align={'left'}>Your Doctors</Typography>
      <Typography sx={{fontFamily: 'Epilogue', color: '#1C434F'}} align={'left'}>{quote?.preferredDoctors?.length || 0} added</Typography>
    </div>
  },
  {
    icon: Pill,
    label: <div>
      <Typography variant={'body2'} className={'medium epilogue'} align={'left'}>Your Drugs</Typography>
      <Typography sx={{fontFamily: 'Epilogue', color: '#1C434F'}} align={'left'}>{quote?.preferredDrugs?.length || 0} added</Typography>
    </div>
  },
  {
    icon: Prescription,
    label: <div>
      <Typography variant={'body2'} className={'medium epilogue'} align={'left'}>Your Pharmacy</Typography>
      <Typography sx={{fontFamily: 'Epilogue', color: '#1C434F'}} align={'left'}>{quote?.preferredPharmacies?.length || 0} added</Typography>
    </div>
  }
]
