import { useNavigate, useParams } from 'react-router';
import { Box, Button, Card, Typography } from '@mui/material';
import { Routes } from '../../app/router';
import { ArrowLeft } from '@phosphor-icons/react';
import React, { useEffect, useMemo, useRef } from 'react';
import { useLogEvent } from '@coverright/shared/analytics';
import { V3Preloader } from '@coverright/ui/shared';
import { useAppDispatch, useAppSelector } from '../../shared';
import {
  fetchVideoCategories,
  setPersistentStateValue,
  persistentState,
} from '../../entities';
import { CompletedModal } from './ui/CompletedModal';
import { WellDoneModal } from './ui/WellDoneModal';
import { WatchNext } from './ui/WatchNext';
import * as _ from 'lodash';
import { useVideoCategories } from '../../features';
import { VideoPlayer } from '@coverright/features';

export default function Video() {
  const params = useParams<{ id: string }>();
  const id = params.id ? parseFloat(params.id) : undefined;
  const [wellDoneOpen, setWellDoneOpen] = React.useState(false);
  const [completedOpen, setCompletedOpen] = React.useState(false);
  const navigate = useNavigate();
  const state = useAppSelector(persistentState);
  const logEvent = useLogEvent();
  const playerRef = useRef<null | { getCurrentTime: () => number }>(null);

  const categories = useVideoCategories();
  const dispatch = useAppDispatch();

  const videosCompleted: number[] = useMemo(() => {
    return state?.videosCompleted || [];
  }, [state]);

  useEffect(() => {
    if (!categories.length) {
      dispatch(fetchVideoCategories());
    }
  }, [categories]);

  const category = React.useMemo(() => {
    return categories.find((c) => c.videos.some((v) => v.id === id));
  }, [categories, id]);

  const video = React.useMemo(() => {
    if (category) {
      return category.videos.find((v) => v.id === id);
    }
    return undefined;
  }, [category, id]);

  const isLastInModule = React.useMemo(() => {
    if (category) {
      const index = category.videos.findIndex((v) => v.id === id);
      return !category.videos[index + 1];
    }
    return undefined;
  }, [category]);

  const nextCategory = React.useMemo(() => {
    if (category) {
      const index = categories.findIndex((c) => c.id === category.id);
      if (categories[index + 1]) {
        return categories[index + 1];
      }
    }
    return undefined;
  }, [categories, category, id]);

  const nextVideos = React.useMemo(() => {
    if (category) {
      const index = category.videos.findIndex((v) => v.id === id);
      if (category.videos[index + 1]) {
        return category.videos.slice(index + 1);
      } else if (nextCategory) {
        return nextCategory.videos;
      }
    }
    return [];
  }, [categories, category, id, nextCategory]);

  React.useEffect(() => {
    if (
      videosCompleted?.length &&
      categories?.length &&
      categories
        .flatMap((c) => c.videos)
        .every((v) => videosCompleted.includes(v.id))
    ) {
      setWellDoneOpen(false);
      setCompletedOpen(true);
    }
  }, [videosCompleted, categories]);

  const trackVideoDuration = () =>
    logEvent('Play video duration', {
      duration: playerRef.current?.getCurrentTime(),
      name: video?.title,
      url: document.location.href,
    });

  const goToNextVideo = () => {
    if (nextVideos && nextVideos[0]) {
      trackVideoDuration();
      navigate('/video/' + nextVideos[0].id);
    }
  };

  const onVideoFinished = () => {
    if (video) {
      dispatch(
        setPersistentStateValue({
          videosCompleted: _.uniq([...videosCompleted, video.id]),
        })
      );
      setWellDoneOpen(true);
    }
  };

  return (
    <div>
      <CompletedModal
        open={completedOpen}
        onClose={() => setCompletedOpen(false)}
      />
      <WellDoneModal
        open={wellDoneOpen}
        onClose={(goToNext?: boolean) => {
          setWellDoneOpen(false);
          if (goToNext) {
            goToNextVideo();
          }
        }}
        moduleName={category?.title}
        isLastInModule={isLastInModule}
        videosLeft={nextVideos.length}
        nextModuleName={nextCategory?.title}
      />
      <div>
        <Button
          variant={'text'}
          color={'primary'}
          id={'back-button'}
          onClick={() => navigate(Routes.getRouteUrl('learn'))}
          startIcon={<ArrowLeft size={16} color="#1C434F" weight="regular" />}
        >
          Back
        </Button>
      </div>

      {!categories.length && <V3Preloader sx={{ mt: 4 }} />}

      {!!categories.length && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 3,
            mt: 3,
          }}
        >
          <Card>
            <Typography
              className={'fs-24 mb-16'}
              variant={'h4'}
              align={'center'}
            >
              {category?.title}
            </Typography>
            <Typography
              className={'fs-24 mb-16'}
              variant={'h4'}
              align={'center'}
            >
              {video?.title}
            </Typography>

            <VideoPlayer
              maxWidth={{ md: 824 }}
              videoUrl={video?.url}
              videoTitle={video?.title}
              onFinished={() => onVideoFinished()}
              ref={playerRef}
            />

            <Box
              sx={{
                backgroundColor: 'white',
                width: 1,
                mt: 3,
                p: 2,
                overflowY: 'auto',
                height: 175,
                border: '1px solid #B3B3B39E',
              }}
            >
              <Typography className={'bold'}>Video script</Typography>
              {video?.description && (
                <div
                  style={{ whiteSpace: 'pre-line' }}
                  dangerouslySetInnerHTML={{ __html: video.description }}
                />
              )}
            </Box>
          </Card>

          <WatchNext
            videos={nextVideos}
            onVideoClick={(id) => {
              trackVideoDuration();
              navigate('/video/' + id);
            }}
            isVideoCompleted={(id) => videosCompleted.includes(id)}
          />
        </Box>
      )}
    </div>
  );
}
