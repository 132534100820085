import React from 'react';
import Lottie from 'react-lottie';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import animationData from './quiz-complete-loader-animation.json'


const SearchLoader = (props: {height?: number, width?: number, style?: React.CSSProperties}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    renderer: 'svg',
  }
  return (
    <Lottie
      options={defaultOptions}
      height={props.height || 500}
      width={props.width || 500}
      isClickToPauseDisabled
      style={props.style}
    />
  )
}
export default SearchLoader;
