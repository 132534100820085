import React from 'react';
import {maExtraBenefits} from '../../../entities';
import { FilterProps, ListItem, useAppSelector } from '../../../shared';
import {MedicareQuoteFilterExtraBenefit} from "@coverright/data-access/types/medicare";
import { usePartDConfirm, PlanFilter } from '../../../features';

export function ExtraBenefitsFilter({ value, benefits, onChange }: FilterProps & {benefits: ListItem[]}) {
  const partDConfirm = usePartDConfirm();

  const handleChange = (value: string[]) => {
    if (!value.length) {
      partDConfirm().then(val => val && onChange(value))
    } else {
      onChange(value)
    }
  }

  return <PlanFilter value={value}
                     onChange={handleChange}
                     label={'Drug Coverage'}
                     data-test={'drug-coverage-filter'}
                     options={benefits.map(t => (
                       {...t, checked: value?.includes(t.value)}
                     ))}
                     multiple
  />
}

export default function (props: FilterProps) {
  const benefits = useAppSelector(maExtraBenefits);
  return <ExtraBenefitsFilter {...props}
                              benefits={benefits
                                .filter(b => b.value === MedicareQuoteFilterExtraBenefit.DrugCoverage)
                                .map(b => ({...b, label: b.label.replace('Drug coverage', 'Part D Drug Coverage')}))} />
}
