import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  Collapse,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import SelectPlanModal from '../../../components/select-plan-modal';
import {
  DrugTierOutput,
  DrugTiersOutput,
} from '@coverright/data-access/types/medicare';
import * as _ from 'lodash';
import {
  getCompanyLogo,
  getInitialTooltipByTier,
  mapDeliveryType,
} from '@coverright/utils';
import { CompanyLogo } from '@coverright/ui/shared';
import { CaretCircleDown, Info } from '@phosphor-icons/react';
import { Benefit, DrugCoverageList } from '@coverright/ui/marketplaces';
import { ResponsiveTable, ResponsiveTableColumn } from '@coverright/features';
import { PdpPlanWithTiers } from '@coverright/data-access/medicare';
import { CRTooltip } from '@coverright/ui/inputs';
import { useNavigate } from 'react-router';
import { PdpQuoteContext } from '@coverright/shared/contexts';
import { routes } from '../../../app/router-new';

type PlanCardProps = {
  plan: PdpPlanWithTiers;
};

function PlanCard({ plan }: PlanCardProps) {
  const quote = React.useContext(PdpQuoteContext);
  const navigate = useNavigate();
  const [selectModalOpen, setSelectModalOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(true);

  return (
    <div data-test={'pdp_plan_card'}>
      <SelectPlanModal
        open={selectModalOpen}
        onClose={() => setSelectModalOpen(false)}
      />
      <Card>
        <Box
          sx={{
            display: 'flex',
            gap: 3,
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
          }}
        >
          <Stack spacing={2} sx={{ flex: 1 }}>
            <CompanyLogo
              sx={{ backgroundPosition: 'left' }}
              url={getCompanyLogo(plan.parentOrgName, plan.orgName)}
            />
            <Typography className={'epilogue fs-32 semi-bold lh-48'}>
              {plan.planName}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: 4,
                flexDirection: { xs: 'column', md: 'row' },
              }}
            >
              <Box sx={{ flex: 1 }}>
                <Typography className={'epilogue fs-18 semi-bold'}>
                  Monthly Premium
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Typography>{plan.monthlyPremium} per month</Typography>
              </Box>
              <Box sx={{ flex: 1 }}>
                <Typography className={'epilogue fs-18 semi-bold'}>
                  Annual Deductible
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Typography>
                  {plan.drugDetails?.mrxAltDedAmount} p.a.
                </Typography>
              </Box>
            </Box>
          </Stack>

          <Box
            sx={{
              p: '24px 16px',
              borderRadius: '16px',
              background: '#fcf6df',
              maxWidth: { md: 232 },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
                justifyContent: 'space-between',
              }}
            >
              <div>
                <Typography className={'fs-40 semi-bold mb-8'} align={'center'}>
                  {plan.monthlyCost || 'N/A'}
                </Typography>
                <Typography align={'center'}>
                  estimated average total cost per month
                </Typography>
              </div>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  alignItems: 'center',
                }}
              >
                {typeof plan.monthlyCost === 'string' && !!quote.preferredDrugs?.length && !plan.missingFormularyInAep && (
                  <Button
                    sx={{ border: '1px solid #1C434F' }}
                    className={'fs-16 p-16 medium'}
                    fullWidth
                    variant={'outlined'}
                    onClick={() =>
                      navigate(routes.drugCostPDP.value + '/' + plan.bidId)
                    }
                    id={'pdp-plan-drug-cost-breakdowns-button'}
                    color={'primary'}
                  >
                    Drug Cost Breakdown
                  </Button>
                )}

                <Button
                  sx={{ border: '1px solid #1C434F' }}
                  className={'fs-16 p-16 medium'}
                  fullWidth
                  variant={'contained'}
                  onClick={() => setSelectModalOpen(true)}
                  id={'pdp-plan-select-plan-button'}
                  color={'primary'}
                >
                  Select Drug Plan
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ flex: 1, mt: 3 }}>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => setCollapsed(!collapsed)}
          >
            <Typography className={'epilogue fs-18 semi-bold'}>
              Additional details
            </Typography>
            <Box
              sx={{
                transform: !collapsed
                  ? 'rotate(-180deg) !important'
                  : 'rotate(0)',
                transition: 'all 0.3s',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <CaretCircleDown
                color={'#1C434F'}
                width={24}
                height={24}
                weight={'fill'}
              />
            </Box>
          </Box>

          <Divider sx={{ mb: 1, mt: 0.5 }} />

          <Collapse in={!collapsed}>
            <Box
              sx={{
                display: 'flex',
                gap: 4,
                flexDirection: { xs: 'column', md: 'row' },
              }}
            >
              <Box sx={{ flex: 1 }}>
                <Typography className={'semi-bold'}>Plan Type</Typography>
                <Typography>Prescription Drug Plan (PDP)</Typography>
              </Box>
              <Box sx={{ flex: 1, mt: '-12px' }}>
                <DrugCoverageList
                  title={'Your Prescriptions'}
                  titleClassName={'fs-16 semi-bold lh-24'}
                  labelClassName={'fs-16 mb-8 regular'}
                  coverage={plan.drugsCoverage}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <Typography className={'semi-bold'}>Your Pharmacy</Typography>
                {plan?.quoteDrugsTiers?.pharmacyName && (
                  <Benefit
                    label={
                      plan?.quoteDrugsTiers?.pharmacyName +
                      ` (${mapDeliveryType(
                        plan?.quoteDrugsTiers?.deliveryType
                      )})`
                    }
                    value={true}
                    labelClass={'fs-16 mb-8 regular'}
                  />
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: 4,
                mt: 3,
                flexDirection: { xs: 'column', md: 'row' },
              }}
            >
              <Box sx={{ flex: 1 }}>
                <Typography className={'semi-bold'}>
                  Prescription Drug Deductible
                </Typography>
                <Typography>
                  {[
                    plan?.drugDetails?.mrxAltDedAmount || '',
                    plan?.drugDetails?.mrxAltNoDedTier || '',
                  ]
                    .filter((v) => v)
                    .join(' ')}
                </Typography>
              </Box>
              <Box sx={{ flex: 1 }}>
                <Typography className={'semi-bold'}>
                  Initial Coverage Limit
                </Typography>
                <Typography>
                  {plan.drugDetails?.initialCoverageLimit}
                </Typography>
              </Box>
              <Box sx={{ flex: 1 }}>
                <Typography className={'semi-bold'}>
                  Coverage Gap ('Donut Hole') Limit
                </Typography>
                <Typography>
                  {plan.drugDetails?.catastrophicThreshold}
                </Typography>
              </Box>
            </Box>

            <Typography className={'epilogue mt-24 mb-16 fs-18 semi-bold'}>
              Initial coverage breakdown
            </Typography>

            <ResponsiveTable
              columns={getTableColumns()}
              rows={[
                plan.drugsTiers30Preferred,
                plan.drugsTiers30Standard,
                plan.drugsTiers90Preferred,
                plan.drugsTiers90Standard,
              ]}
            />
          </Collapse>
        </Box>
      </Card>
    </div>
  );
}

export default React.memo(PlanCard);

const getTableColumns: () => ResponsiveTableColumn<DrugTiersOutput | null>[] =
  _.memoize(() => {
    const result: ResponsiveTableColumn<DrugTiersOutput | null>[] = [
      {
        title: 'Coverage',
        value: (data, i) => coverageNames[i],
      },
    ];
    Array(5)
      .fill(1)
      .forEach((v, i) => {
        result.push({
          title: (
            <CRTooltip
              placement={'top'}
              arrow
              title={getInitialTooltipByTier(tiers[i])}
            >
              <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                <Typography className={'semi-bold no-wrap'}>{`Tier ${
                  v + i
                }`}</Typography>
                <Info color={'#666666'} width={24} height={24} />
              </Box>
            </CRTooltip>
          ),
          value: (data) =>
            data?.initialCoverageDrugTiers.find((t) =>
              t.tierName.includes(`Tier ${v + i}`)
            )?.coverage || '',
        });
      });
    return result;
  });

const coverageNames = [
  'Preferred Retail Pharmacy cost-sharing (30 day supply)',
  'Standard Retail Pharmacy cost-sharing (30 day supply)',
  'Preferred Mail Order cost-sharing (90 day supply)',
  'Standard Mail Order cost-sharing (90 day supply)',
];

const tiers = [
  DrugTierOutput.PreferredGeneric,
  DrugTierOutput.Generic,
  DrugTierOutput.PreferredBrand,
  DrugTierOutput.NonPreferredBrand,
  DrugTierOutput.Specialty,
];
