import { ListItemText, MenuItem, OutlinedInputProps } from '@mui/material';
import { SelectInput, V3Checkbox } from '@coverright/ui/inputs';
import React, { useMemo } from 'react';

export type Value = string | number | string[] | undefined;

type PlanFilterOption<T> = {
  label: string,
  value: T,
  checked?: boolean
}

interface Props<T> {
  value: T,
  onChange: (value: T) => void,
  multiple?: boolean,
  options: PlanFilterOption<T>[],
  label: string,
  InputProps?: Partial<OutlinedInputProps>,
  getDisplayValue?: (values?: Value) => string,
  'data-test'?: string
}

export function PlanFilter<T extends Value>(props: Props<T>) {

  const getDisplayValue = (placeholder: string, values?: Value) => {
    if (props.getDisplayValue) {
      return props.getDisplayValue(values);
    }

    if (Array.isArray(values)) {
      if (!values || !values.length) {
        return placeholder;
      } else {
        return `${placeholder} (${values.length})`
      }
    }
    return '';
  }

  const renderValue = useMemo(() => {
    return props.multiple ? (v: any) => getDisplayValue(props.label, v) : undefined
  }, [props.multiple]);

  return <SelectInput
    variant="outlined"
    multiple={props.multiple}
    hideTick
    renderValue={renderValue}
    data-test={props["data-test"]}
    InputProps={{
      classes: {
        input: 'pv-8 pl-8 pr-60'
      },
      ...props.InputProps
    }}
    MenuProps={{
      PaperProps: {
        style: {
          maxHeight: 500,
        },
      },
      MenuListProps: {
        dense: true,
      }
    }}
    className={'mb-0'}
    value={props.value || []}
    onChange={e => {
      props.onChange(e.target.value as T);
    }}
  >
    {props.options
      .map(d => <MenuItem key={String(d.value)} value={d.value} data-test={'filter-option'}>
        {props.multiple && <>
          <V3Checkbox variant={'standart'}
                      label={''}
                      wrapperSx={{maxWidth: 50}}
                      checked={d.checked}/>
          <ListItemText primary={d.label} />
        </>}
        {!props.multiple && d.label}
      </MenuItem>)}
  </SelectInput>
}
