import {
  useLazyIsTherePlanWithFullDrugCoverageQuery
} from '../../../shared/api/medicare';
import { MedicareQuoteOutput } from '@coverright/data-access/types/medicare';
import { useEffect } from 'react';

export default function useIsTherePlanWithFullDrugCoverage(quote: MedicareQuoteOutput) {
  const [getData, data] = useLazyIsTherePlanWithFullDrugCoverageQuery();

  useEffect(() => {
    if (quote.preferredDrugs?.length) {
      getData({
        zip: quote.zip,
        countyName: quote.county as string,
        planYear: quote.planYear,
        quoteId: quote.id
      })
    }
  }, [quote]);

  return quote.preferredDrugs?.length ? Boolean(data.data?.isTherePlanWithFullDrugCoverage) : false;
}
