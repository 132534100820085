import { Box, Card } from '@mui/material';
import React, { useState } from 'react';
import {useAppSelector} from "../../shared";
import {medicareQuote, maFilters, maSortBy} from "../../entities";
import {profile as profileSelector} from "../../entities/profile";
import { MaPlanListQuery, } from '../../shared/api/medicare';
import usePlanList from './api/use-plan-list';
import UpdateZip from './ui/update-zip';
import { V3Preloader } from '@coverright/ui/shared';
import { Footer } from '@coverright/ui/marketplaces';
import { useDebouncedEffect } from '@coverright/utils';
import { FilterBadges, MaMessages, LoadMorePlansButton, PlansCountRepresentDisclamer } from '../../features';
import useFilterBadges from './lib/use-filter-badges';
import MemoizedList from './ui/memoized-list';

export function MaPlanList() {
  const quote = useAppSelector(medicareQuote);
  const profile = useAppSelector(profileSelector);
  const sortBy = useAppSelector(maSortBy);
  const filters = useAppSelector(maFilters);
  const [page, setPage] = useState(0);
  const {plansTotal, loadPlans, hasMorePlans, isLoading, isSuccess} = usePlanList();
  const [plans, setPlans] = useState<MaPlanListQuery['plans']['data']>([]);
  const badges = useFilterBadges();

  const load = async (page: number) => {
    if (quote) {
      return await loadPlans(page, sortBy, quote.zip, quote.county as string, filters, quote.id)
    }
    return [];
  }

  useDebouncedEffect(() => {
    setPlans([]);
    load(0).then(setPlans)
  }, 600, [filters, sortBy])

  const loadMore = () => {
    const newPage = page + 1
    setPage(newPage)
    load(newPage).then(response => {
      setPlans(prev => [...prev, ...response]);
    })
  }

  return <>
    <Card sx={{p: {xs: 2, md: 4}}}>
      {quote && <MaMessages plans={plans} loading={isLoading} filters={filters} quote={quote}/>}
      <UpdateZip loading={isLoading} plansTotal={plansTotal} />
      <PlansCountRepresentDisclamer zip={quote?.zip} county={quote?.county} state={profile?.state} type={'MA'} />
      <FilterBadges badges={badges} />
    </Card>

    <Box sx={{p: 0, flex: 1, display: 'flex', flexDirection: 'column', gap: 3}}>
      {(!!plans.length && isSuccess) && <MemoizedList plans={plans} />}
      {(!isLoading && isSuccess && hasMorePlans) && <Box textAlign={"center"} mt={'32px'}>
        <LoadMorePlansButton id={'ma-load-more-plans-button'} disabled={isLoading} onClick={loadMore} />
      </Box>}
      {isLoading && <V3Preloader sx={{mt: 4, mb: 6}} />}
      <Footer sx={{maxWidth: 'initial'}} zip={quote?.zip} />
    </Box>
  </>
}


