import { Box, Button, Card, Typography } from '@mui/material';
import { Calendar, Envelope, Phone, Lightning } from '@phosphor-icons/react';
import React from 'react';
import { useCalendlyModal } from '@coverright/features';
import UrgentCallModal from './urgent-call-modal';
import { TrustpilotWidget } from '@coverright/ui/shared';
import { useLogEvent } from '@coverright/shared/analytics';
import {useClientAgent} from "@coverright/shared/contexts";

export default function AgentCard() {
  const logEvent = useLogEvent();
  const { agent } = useClientAgent();
  const ref = React.useRef(null);
  const emailRef = React.useRef(null);
  const openCalendly = useCalendlyModal();
  const [open, setOpen] = React.useState(false);

  return (
    <Card sx={{ p: 4, maxWidth: { lg: 294 }, minWidth: 294 }}>
      <UrgentCallModal open={open} onClose={() => setOpen(false)} />
      <Typography variant={'h4'} className={'fs-24 lh-24'}>
        Need help?
      </Typography>
      <Typography className={'epilogue'} sx={{ mt: 1, mb: 3, color: '#666' }}>
        Let’s find together the plan who suits your needs.
      </Typography>
      {agent && (
        <>
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              gap: 3,
              alignItems: 'center',
              my: 3,
            }}
          >
            {agent.picUrl && (
              <Box
                style={{
                  borderRadius: '50%',
                  backgroundImage: `url("${agent.picUrl}")`,
                  backgroundPosition: 'center center',
                  backgroundSize: 'cover',
                }}
                className={'w-64 h-64'}
              />
            )}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography className={'epilogue semi-bold'}>
                {agent.firstName} {agent.lastName}
              </Typography>
              <Typography className={'epilogue'} sx={{ color: '#666' }}>
                Licensed Agent
              </Typography>
            </Box>
          </Box>
          <Button
            fullWidth
            variant={'contained'}
            onClick={() => setOpen(true)}
            id={'client-agent-card-call-me-today-button'}
            sx={{ justifyContent: 'flex-start', px: 2, fontWeight: 500 }}
            startIcon={<Lightning size={24} color="#FFF" weight="fill" />}
          >
            Call Me Today
          </Button>
          <Button
            fullWidth
            variant={'outlined'}
            id={'client-agent-card-schedule-a-call-button'}
            sx={{
              justifyContent: 'flex-start',
              px: 2,
              fontWeight: 500,
              mt: 1.5,
              '& .light': { display: 'none' },
              '&:hover .dark': { display: 'none' },
              '&:hover .light': { display: 'block' },
            }}
            onClick={() => openCalendly()}
            startIcon={
              <>
                <Calendar
                  className={'dark'}
                  size={24}
                  color="#1C434F"
                  weight="fill"
                />
                <Calendar
                  className={'light'}
                  size={24}
                  color="#FFF"
                  weight="regular"
                />
              </>
            }
          >
            Schedule a call
          </Button>
          {agent.phone && (
            <Button
              fullWidth
              sx={{
                justifyContent: 'flex-start',
                px: 2,
                fontWeight: 500,
                mt: 1.5,
                '& .light': { display: 'none' },
                '&:hover .dark': { display: 'none' },
                '&:hover .light': { display: 'block' },
              }}
              id={'client-agent-card-phone-button'}
              onClick={() => {
                if ((ref as any).current) {
                  (ref as any).current.click();
                }
              }}
              variant={'outlined'}
              startIcon={
                <>
                  <Phone
                    className={'light'}
                    size={24}
                    color="#FFF"
                    weight="fill"
                  />
                  <Phone
                    className={'dark'}
                    size={24}
                    color="#1C434F"
                    weight="fill"
                  />
                </>
              }
            >
              <Box
                ref={ref}
                id={'call_button'}
                onClick={(e: any) => e.stopPropagation()}
                component={'a'}
                href={'tel:+' + agent.phone.replace(/\D/g, '')}
                sx={{ display: 'none' }}
              >
                11
              </Box>
              {agent.phone}
            </Button>
          )}
          <Button
            fullWidth
            sx={{
              justifyContent: 'flex-start',
              px: 2,
              fontWeight: 500,
              mt: 1.5,
              '& .light': { display: 'none' },
              '&:hover .dark': { display: 'none' },
              '&:hover .light': { display: 'block' },
            }}
            variant={'outlined'}
            id={'client-agent-card-email-button'}
            onClick={() => {
              if ((emailRef as any).current) {
                (emailRef as any).current.click();
              }
            }}
            startIcon={
              <>
                <Envelope
                  className={'light'}
                  size={24}
                  color="#FFF"
                  weight="fill"
                />
                <Envelope
                  className={'dark'}
                  size={24}
                  color="#1C434F"
                  weight="fill"
                />
              </>
            }
          >
            Email {agent.firstName}
          </Button>
          <Box
            ref={emailRef}
            id={'email_button'}
            onClick={(e: any) => e.stopPropagation()}
            component={'a'}
            href={'mailto:' + agent.email}
            sx={{ display: 'none' }}
          >
            11
          </Box>
        </>
      )}

      <Box
        sx={{
          my: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <a
          href="https://www.bbb.org/us/ny/brooklyn/profile/medicare/coverright-inc-0121-87151389/#sealclick"
          id="bbblink"
          className="rbhzbus mb-16"
          target="_blank"
          rel="nofollow"
        >
          <img
            src="https://seal-newyork.bbb.org/logo/rbhzbus/bbb-87151389.png"
            style={{ border: 0 }}
            alt="CoverRight Inc.  BBB Business Review"
          />
        </a>
        <TrustpilotWidget width={150} />
      </Box>

      <Typography sx={{ fontWeight: 600, mt: 3 }}>Hours</Typography>
      <Typography sx={{ mt: 1.5, color: '#666' }}>
        Mon - Fri: 8am - 10pm ET
      </Typography>
      <Typography sx={{ mt: 1.5, color: '#666' }}>
        Sat - Sun: 9am - 7pm ET
      </Typography>

      <Box
        sx={{
          p: '24px 16px',
          mt: 3,
          borderRadius: '8px',
          background: '#F9E69A',
        }}
      >
        <Typography className={'epilogue fs-16 mb-12 semi-bold'}>
          Why should you call us?
        </Typography>
        <Typography sx={{ ml: '20px', mb: 1, display: 'list-item' }}>
          It's 100% free.
        </Typography>
        <Typography sx={{ ml: '20px', mb: 1, display: 'list-item' }}>
          You want to go forward with a plan.
        </Typography>
        <Typography sx={{ ml: '20px', mb: 1, display: 'list-item' }}>
          You don’t know which plan suits you.
        </Typography>
        <Typography sx={{ ml: '20px', mb: 1, display: 'list-item' }}>
          You have questions about Medicare.
        </Typography>
        <Typography sx={{ ml: '20px', display: 'list-item' }}>
          You want to speak to a Medicare expert.
        </Typography>
      </Box>

      <Box
        sx={{
          mt: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{ mb: 3, width: 75, height: 89 }}
          component={'img'}
          src={'/plan-finder/assets/img/Guarantee.png'}
        />
        <Typography className={'epilogue fs-24 semi-bold lh-28 mb-8'}>
          Best Rate Guarantee
        </Typography>
        <Typography sx={{ color: '#666', lineHeight: '24px' }}>
          If we can’t find you the best Medicare Supplement insurance rate,
          we’ll give you $200 (Terms Apply). <br />
          <Box
            component={'span'}
            onClick={() => {
              logEvent('click', {
                element_title: 'Learn more',
                element_id: 'best-rate-guarantee-offer-link',
              }).then(() =>
                window.open('https://coverright.com/best-rate-guarantee-offer')
              );
            }}
            sx={{ cursor: 'pointer', color: '#19605D' }}
          >
            Learn more
          </Box>
        </Typography>
      </Box>
    </Card>
  );
}
