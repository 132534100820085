import * as yup from 'yup';
import { validatePhone } from '@coverright/data-access/enrollment';
import { UserProfileOutput } from '@coverright/data-access/types/enrollment';
import React from 'react';
import { useFormik } from 'formik';
import { getCId, getStoredUser } from '@coverright/utils';
import * as _ from 'lodash';
import { FormikTextInput, PhoneNumberInput, TextInput } from '@coverright/ui/inputs';
import { Box, Button } from '@mui/material';
import { useSaveProfile } from '../api/use-save-profile';

const schemaFields: any = {
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  firstName: yup
    .string()
    .required('First name is required'),
  lastName: yup
    .string()
    .required('Last name is required'),
  phoneNumber: yup.string()
    .required("Phone number is required")
    .test("test-phone",
      "Please enter valid phone number",
      validatePhone)
}

export const AccountSettingsForm = ({onSave, onCancel, profile}: {onSave: () => void, onCancel: () => void, profile: UserProfileOutput}) => {
  const {saveProfile, loading} = useSaveProfile(onSave, () => formik.setFieldError('email', 'IN_USE'));

  const formik = useFormik({
    initialValues: {
      email: profile.email || getStoredUser()?.email || '',
      firstName: profile.firstName || getStoredUser()?.firstName || '',
      lastName: profile.lastName || getStoredUser()?.lastName || '',
      phoneNumber: profile.phoneNumber || getStoredUser()?.phoneNumber || '',
    },
    validationSchema: yup.object(schemaFields),
    onSubmit: (values) => {
      if (formik.isValid) {
        saveProfile({
          email: _.trim(values.email),
          firstName: _.trim(values.firstName),
          lastName: _.trim(values.lastName),
          phoneNumber: '+' + values.phoneNumber.replace(/\D/g, ''),
          cId: getCId(),
          profileId: getStoredUser()?.profileId,
        })
      }
    },
    validateOnChange: true
  });

  return <form onSubmit={formik.handleSubmit} className={'fill-width'}>
    <FormikTextInput
      formik={formik}
      fullWidth
      name="firstName"
      autoFocus={true}
    />
    <FormikTextInput
      formik={formik}
      fullWidth
      name="lastName"
    />
    <TextInput
      fullWidth
      name="email"
      label="Email"
      data-test="email"
      placeholder={'Enter your email address'}
      value={formik.values.email}
      onChange={formik.handleChange}
      error={formik.touched.email && Boolean(formik.errors.email)}
      helperText={(formik.touched.email && formik.errors.email) ? formik.errors.email === 'IN_USE' ? 'Looks like that email is already in use.' : formik.errors.email : undefined}
    />
    <PhoneNumberInput onChange={formik.handleChange}
                      value={formik.values.phoneNumber}
                      name="phoneNumber"
                      label="Phone Number"
                      placeholder="(123) 456-7890"
                      error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                      helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
    />
    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
      <Button variant={'outlined'} disabled={loading} onClick={onCancel}>Cancel</Button>
      <Button variant={'contained'} disabled={loading} type={'submit'}>Save changes</Button>
    </Box>

  </form>
}
