import * as _ from "lodash";
import {BenefitEntity} from ".";
import {MaPlanQuery} from "../../../shared/api/medicare";

export function mapBenefit(benefit: any, plan: MaPlanQuery['plan']): BenefitEntity {
  const name = benefit.attributes?.name;
  // let description: JSX.Element | undefined = <Markdown>{benefit.attributes?.description}</Markdown>;
  let description: BenefitEntity['description'] = benefit.attributes?.description;
  const howToActivate = benefit.attributes?.howToActivate;

  switch (_.lowerCase(name)) {
    case 'dental': description = plan?.dental?.benefits || []; break;
    case 'vision': description = plan?.vision?.benefits || []; break;
    case 'hearing': description = plan?.hearing?.benefits || []; break;
    case 'meals': description = plan?.meal; break;
    case 'otc benefits': description = plan?.oTC; break;
  }

  return {name, description, howToActivate};
}
