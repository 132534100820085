import { Field, PlansFilterInput, Sort } from '@coverright/data-access/types/medicare';
import { GetNextPlansYear } from '@coverright/utils';
import { useLazyMaPlanListQuery } from '../../../shared/api/medicare';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';

export default function usePlanList() {
  const [getPlans, {data, isFetching, isUninitialized , isSuccess}] = useLazyMaPlanListQuery();
  const { enqueueSnackbar } = useSnackbar();

  const loadPlans = async (page: number, sortBy: Field, zip: string, countyName: string, filters?: PlansFilterInput, quoteId?: string) => {
    if (filters) {
      try {
        const response = await getPlans({
          filter: {...filters, showAllPlans: false, zip, countyName},
          fullYear: filters.planYear === GetNextPlansYear(),
          page: {
            size: 8,
            page: page
          },
          quoteId,
          sort: getSort(sortBy)
        })

        if (response.data?.plans.data) {
          return response.data?.plans.data
        } else {
          throw Error('Ma plan list data is wrong');
        }
      } catch (e) {
        console.error(e);
        enqueueSnackbar('Error loading plans', {variant: 'error'});
      }

    } else {
      console.warn('No filters in Ma list')
    }
    return [];
  }

  const plansTotal = useMemo(() => {
    return data?.plans.totalElements ?? 0;
  }, [data]);

  const hasMorePlans = React.useMemo(() => {
    return data?.plans.hasNext;
  }, [data?.plans.hasNext])

  return {plansTotal, loadPlans, hasMorePlans, isLoading: isFetching || isUninitialized, isSuccess}
}

const getSort = (sortBy: Field) => sortBy === Field.Rating ? [
  { "field": Field.Rating,"direction":Sort.Desc },
  { "field": Field.MaxOutOfPocket, "direction": Sort.Asc},
  { "field": Field.Deductible, "direction": Sort.Asc},
  { "field": Field.Name, "direction": Sort.Asc}
] : [
  { "field": Field.MonthlyCost,"direction":Sort.Asc },
  { "field": Field.Premium,"direction":Sort.Asc },
  { "field": Field.MaxOutOfPocket, "direction": Sort.Asc},
  { "field": Field.Deductible, "direction": Sort.Asc},
  { "field": Field.Name, "direction": Sort.Asc}
]
