import {useContext, useMemo} from 'react';
import {useAppSelector} from "../../../shared/hooks";
import {hasEnrollments as hasEnrollmentsSelector, hasMaEnrollment as hasMaEnrollmentSelector} from "../../../shared/root-slice";
import {postEnrollmentDashboardItems, postEnrollmentPlansItems, preEnrollmentItems} from "../model/items";
import {ChatContext} from "../../chat";

export default function useNavigationItems() {
  const hasEnrollment = useAppSelector(hasEnrollmentsSelector)
  const hasMaEnrollment = useAppSelector(hasMaEnrollmentSelector)
  const {hasNewMessages} = useContext(ChatContext)

  const items = useMemo(() => {
    if (hasEnrollment === false) {
      return [preEnrollmentItems];
    }

    if (hasEnrollment) {
      if (hasMaEnrollment !== undefined) {
        const dashboardItems = hasMaEnrollment ? postEnrollmentDashboardItems : postEnrollmentDashboardItems.filter((_, i) => i !== 1);
        const chatItem = dashboardItems.find(i => i.label === 'Chat');
        if (chatItem) {
          chatItem.badge = hasNewMessages;
        }
        return [dashboardItems, postEnrollmentPlansItems]
      }
    }

    return [];
  }, [hasEnrollment, hasMaEnrollment, hasNewMessages]);

  return items;
}
