import {Card} from "@mui/material";
import React, {useEffect, useMemo} from "react";
import {PlanType} from "@coverright/data-access/medicare";
import BenefitItem from "./ui/benefit-item";
import { useAppSelector, enrolledPlan as enrolledPlanSelector } from "../../shared";
import { useLazyMaPlanQuery } from "../../shared/api/medicare";
import { medicareQuote } from "../../entities";
import {GetPlansYear} from "@coverright/utils";
import {buildBenefitDescriptions} from "./lib/build-benefit-descriptions";
import {routes} from "../../app/router-new";
import { useNavigate } from "react-router";

export function BenefitCards() {
  const navigate = useNavigate();
  const quote = useAppSelector(medicareQuote);
  const enrolledPlan = useAppSelector(state => enrolledPlanSelector(state, PlanType.Ma));
  const [getPlan, {data}] = useLazyMaPlanQuery();

  useEffect(() => {
    if (enrolledPlan) {
      getPlan({
        bidId: enrolledPlan.bidId,
        planYear: GetPlansYear(),
        zip: enrolledPlan.zip!,
        countyName: enrolledPlan.county!,
        quoteId: quote?.id
      })
    }
  }, [enrolledPlan, quote]);

  const items = useMemo(() => {
    return buildBenefitDescriptions(data?.plan);
  }, [data]);

  if (!items.length) {
    return null;
  }

  return <Card sx={{display: 'flex', gap: 3, flexDirection: {xs: 'column', sm: 'row'}}}>
    {items.map((item, i) => (
      <BenefitItem value={item.label}
                   key={i}
                   onViewClick={() => (
                     navigate(routes.benefits.value + `?benefit=${item.link}`)
                   )} />
    ))}
  </Card>
}
