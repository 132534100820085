import React from 'react';
import { maCompanies } from '../../../entities';
import { FilterProps, useAppSelector } from '../../../shared';
import {CompanyFilter} from "../../../features";


export default function(props: FilterProps) {
  const companies = useAppSelector(maCompanies);
  return <CompanyFilter {...props} companies={companies} />
}
