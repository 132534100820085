import { LazyQueryHookOptions, QueryOptions, useLazyQuery } from '@apollo/client';
import {gql} from "@apollo/client";
import {QueryPdpPlanAggregationArgs, PdpPlanAggregationOutput} from '@coverright/data-access/types/medicare';
import { client } from '@coverright/data-access/apollo-clients';

export function usePdpPlanAggregation(options?: LazyQueryHookOptions<{pdpPlanAggregation: PdpPlanAggregationOutput}, QueryPdpPlanAggregationArgs>) {
  return useLazyQuery<{pdpPlanAggregation: PdpPlanAggregationOutput}, QueryPdpPlanAggregationArgs>(gql(getPlans), {
    fetchPolicy: "no-cache",
    errorPolicy: 'all',
    ...options
  });
}

export function pdpPlanAggregation(options?: Partial<QueryOptions<QueryPdpPlanAggregationArgs>>) {
  return client.query<{pdpPlanAggregation: PdpPlanAggregationOutput}, QueryPdpPlanAggregationArgs>({
    query: gql(getPlans),
    errorPolicy: 'all',
    ...options
  }).then(res => res.data?.pdpPlanAggregation);
}



const getPlans = `
query($county: String!, $zip: String!, $dsnpOnly: Boolean, $includeHidden: Boolean, $planYear: PlanYear) {
    pdpPlanAggregation(county: $county, zip: $zip, dsnpOnly: $dsnpOnly, includeHidden: $includeHidden, planYear: $planYear) {
      monthlyPremiumMax
      monthlyPremiumMin
    }
}
`;
