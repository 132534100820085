import { useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

export function usePageSize() {
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down('md'));
  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  const pageSize = React.useMemo(() => {
    return phone ? 1 : tablet ? 2 : 3;
  }, [tablet, phone])

  return pageSize;
}
