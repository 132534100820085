import {PlanFilter, Value} from "./plan-filter";
import React from "react";

interface Props<T> {
  sortBy: T,
  onChange: (value: T) => void,
  options: {label: string, value: T}[],
}

export function SortBySelect<T extends Value>(props: Props<T>) {
  return <PlanFilter value={props.sortBy}
                     onChange={props.onChange}
                     label={''}
                     options={props.options}
                     data-test={'sort-select'}
  />
}
