import AppBar from '../../components/app-bar';
import { Box, Container } from '@mui/material';
import * as React from 'react';
import ExpectCallNotification from '../../components/expect-call-notification';
import { useNavigate, useLocation } from 'react-router';
import Navigation from '../../widgets/navigation';
import {ReactChild} from "react";
import Footer from './ui/footer';

type Props = {
  slots: {
    router: ReactChild
  }
}

export default function(props: Props) {
  const location = useLocation();
  const navigate = useNavigate();

  return <>
    <AppBar />
    <Container sx={{mt: '120px', mb: '162px'}}>
      <Box sx={{maxWidth: 1248}}>
        <ExpectCallNotification />
        <Navigation onTabClick={navigate}
                    pathname={location.pathname}/>
        {props.slots.router}
      </Box>
    </Container>
    <Footer />
  </>
}
