import ListCard from './list-card';
import { useNavigate } from 'react-router';
import {routes} from "../../../../app/router-new";
import {useAppSelector} from "../../../../shared";
import {detailedDrugs} from "../../../../entities";
import * as _ from 'lodash';
import usePlan from "../../api/use-plan";
import {useMemo} from "react";

export default function DrugsListCard() {
  const navigate = useNavigate();
  const quoteDrugs = useAppSelector(detailedDrugs);
  const {plan} = usePlan();

  const drugs = useMemo(() => {
    return quoteDrugs.map(d => ({
      title: d.name || '',
      subtitle: d.isGeneric ? 'Generic' : 'Branded',
      value: d.quantity,
      valueLabel: _.startCase(d.frequency.toLowerCase()),
      tickIcon: plan?.drugsCoverage?.find(dd => dd.rxcui === d.rxcui)?.coverage
    }))
  }, [quoteDrugs, plan]);

  return <ListCard list={drugs}
   onEditClick={() => navigate(routes.drugs.value)}
   noDataLabel={'No drugs have been added yet.'}
   title={'Drugs'} />
}
