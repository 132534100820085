import { useMaPlansTotalQuery } from '../../../shared/api/medicare';
import {
  ExtraBenefit,
  Field,
  MedicareQuoteOutput, PlansFilterInput,
  Sort
} from '@coverright/data-access/types/medicare';

export default function useTotalPlans(quote: MedicareQuoteOutput, filters?: PlansFilterInput) {
  const data = useMaPlansTotalQuery({
    filter: {
      zip: quote.zip,
      countyName: quote.county as string,
      SNPTypes: filters?.SNPTypes,
      extraBenefits: filters?.extraBenefits?.includes(ExtraBenefit.DrugCoverage) ? [ExtraBenefit.DrugCoverage] : [],
    },
    page: {
      size: 1,
      page: 0
    },
    quoteId: quote.id,
    sort: [
      { "field": Field.Rating,"direction": Sort.Desc }
    ]
  });

  return data.data?.plans.totalElements || 0;
}
