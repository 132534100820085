import React, { useMemo } from 'react';
import { Card, Collapse } from '@mui/material';
import { useIsMAState, useIsWIState } from '@coverright/data-access/medicare';
import { MedigapPlanName } from '@coverright/data-access/types/medigap';
import {isAllMgCategoriesSelected, PlanCategory, useMgFilters} from "../../features";
import { Description } from './ui/description';
import {descriptions} from "./lib";

export function MgCategoryDescription() {
  const { filters } = useMgFilters();
  const isMassachusetts = useIsMAState(filters?.zip);
  const isWisconsin = useIsWIState(filters?.zip);

  const open = useMemo(() => {
    const allSelected = isAllMgCategoriesSelected(filters?.planNames);
    return !isMassachusetts && !isWisconsin && !!filters?.planNames?.length && !allSelected
  }, [isMassachusetts, isWisconsin, filters?.planNames]);

  return <Collapse in={open}>
    <Card sx={{p: 4, mt: 3}}>
      <Description open={open && !!filters?.planNames?.includes(MedigapPlanName.F)} value={descriptions[PlanCategory.MostPopular]} />
      <Description open={open && !!filters?.planNames?.includes(MedigapPlanName.Hdf)} value={descriptions[PlanCategory.HighDeductible]} />
      <Description open={open && !!filters?.planNames?.includes(MedigapPlanName.A)} value={descriptions[PlanCategory.CoreBenefits]} />
    </Card>
  </Collapse>
}




