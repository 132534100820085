import React from 'react';
import { Navigate, Route, Outlet, useLocation } from 'react-router';
import Q1 from './q1';
import QuizResults from './quiz-results';
import Quizzes from './quizzes';

export default function() {
  const {pathname} = useLocation();
  console.log(pathname);
  return <Outlet />
}


/*
return <Routes>
  <Route
    path={''}
    element={<Quizzes />}
  />
  <Route
    path={'quiz-results'}
    element={<QuizResults />}
  />
  <Route
    path={':question'}
    element={<Q1 />}
  />
</Routes>*/
