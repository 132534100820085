import React, { MutableRefObject, useRef } from 'react';
import DashboardPlanCard from '../../components/dashboard/dashboard-plan-card';
import {
  hasMaEnrollment,
  hasPdpEnrollment,
  enrolledPlans,
  enrolledPlan as enrolledPlanSelector,
  SideLayout,
  useAppSelector,
} from "../../shared";
import {profile as profileSelector} from '../../entities/profile';
import SideCta from "./ui/side-cta";
import DiscountAlert from "../../widgets/discount-alert";
import {medicareQuote} from "../../entities";
import { Box } from '@mui/material';
import DoctorsListCard from "./ui/cards/doctors-list-card";
import DrugsListCard from "./ui/cards/drugs-list-card";
import PharmaciesListCard from "./ui/cards/pharmacies-list-card";
import OffersBanner from "./ui/offers-banner";
import Offers from './ui/offers';
import { V3Preloader } from '@coverright/ui/shared';
import {BenefitCards, VideoGrid} from "../../widgets";
import {useLogEvent} from "@coverright/shared/analytics";

export default function() {
  const quote = useAppSelector(medicareQuote);
  const plans = useAppSelector(enrolledPlans);
  const hasMaPlan = useAppSelector(hasMaEnrollment);
  const hasPdpPlan = useAppSelector(hasPdpEnrollment);
  const profile = useAppSelector(profileSelector);
  const offersRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const logEvent = useLogEvent();

  const enrolledPlan = useAppSelector(enrolledPlanSelector);

  if (!quote) {
    return <V3Preloader />
  }

  const onDiscountAlertClick = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (offersRef.current) {
      offersRef.current.scrollIntoView({ behavior: 'smooth' });
      await logEvent('click', {
        element_title: e.currentTarget.textContent || '',
        element_id: e.currentTarget.id
      })
    }
  }

  return <SideLayout title={profile?.firstName ? `Hi, ${profile?.firstName}!` : 'Loading...'}
                     subtitle={'Browse plans, benefits, view important Medicare information and more.'}
                     slots={{
                       side: <SideCta hasMaPlan={!!hasMaPlan}
                                      hasPdpPlan={hasPdpPlan} />,
                       end: <VideoGrid />
                     }}
  >
    {enrolledPlan && <DiscountAlert plan={enrolledPlan}
                                    onClick={onDiscountAlertClick}
                                    quoteId={quote?.id}
                                    clientId={quote?.clientId} />}

    {plans.map(plan => <DashboardPlanCard hideButton
                                          key={plan.bidId}
                                          plan={plan}/>)}

    <Box sx={{display: 'flex', gap: 3, flexDirection: {xs: 'column', md: 'row'}}}>
      <DoctorsListCard />
      <DrugsListCard />
      <PharmaciesListCard />
    </Box>

    {enrolledPlan && <>
      <OffersBanner ref={offersRef} />
      <Offers />
      <BenefitCards />
    </>}


  </SideLayout>
}
