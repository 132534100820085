import { createApi } from '@reduxjs/toolkit/query/react'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import {
  DrugsWithPackageDocument,
  DrugsWithPackageQuery, DrugsWithPackageQueryVariables,
  MaPlanDiscountsDocument,
  MaPlanDiscountsQuery,
  MaPlanDiscountsQueryVariables,
  MaPlanFilterDocument,
  MaPlanFilterQuery,
  MaPlanFilterQueryVariables,
  MaPlanListDocument,
  MaPlanListQuery,
  MaPlanListQueryVariables,
  MedicareQuoteDocument,
  MedicareQuoteQuery,
  MedicareQuoteQueryVariables,
  PdpCompaniesDocument,
  PdpCompaniesQuery,
  PdpCompaniesQueryVariables,
  PdpPlanDiscountsDocument,
  PdpPlanDiscountsQuery,
  PdpPlanDiscountsQueryVariables,
  PdpPlanListDocument,
  PdpPlanListQuery,
  PdpPlanListQueryVariables, PdpQuoteDocument,
  PdpQuoteQuery,
  PdpQuoteQueryVariables,
  SaveMedicareQuoteDocument,
  SaveMedicareQuoteMutation,
  SaveMedicareQuoteMutationVariables,
  SavePdpQuoteDocument,
  SavePdpQuoteMutation,
  SavePdpQuoteMutationVariables
} from './medicare-rtk';
import {MEDICARE_TAGS} from "./tags";
import {getToken} from "@coverright/shared/keycloak";

// Define a service using a base URL and expected endpoints
export const api = createApi({
  reducerPath: 'medicareApi',
  tagTypes: Object.values(MEDICARE_TAGS),
  baseQuery: graphqlRequestBaseQuery({
    url: process.env.NX_GRAPHQL as string,
    prepareHeaders: async headers => {
      const token = await getToken().catch(() => {});

      return {
        authorization: token ? `Bearer ${token}` : "",
        ...headers
      }
    }
  }),
  endpoints: (build) => ({
    saveMedicareQuote: build.mutation<SaveMedicareQuoteMutation, SaveMedicareQuoteMutationVariables>({
      query: (variables) => ({ document: SaveMedicareQuoteDocument, variables }),
      invalidatesTags: [MEDICARE_TAGS.MA_QUOTE],
    }),
    drugsWithPackage: build.query<DrugsWithPackageQuery, DrugsWithPackageQueryVariables>({
      query: (variables) => ({ document: DrugsWithPackageDocument, variables })
    }),
    medicareQuote: build.query<MedicareQuoteQuery, MedicareQuoteQueryVariables>({
      query: (variables) => ({ document: MedicareQuoteDocument, variables }),
      providesTags: [MEDICARE_TAGS.MA_QUOTE],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data.medicareQuote?.preferredDrugs?.length) {
            dispatch(api.endpoints.drugsWithPackage.initiate({
              pairs: data.medicareQuote.preferredDrugs.map(d => ({
                packageRxcui: d.packRxcui,
                productRxcui: d.rxcui
              }))
            }))
          }
        } catch (e: any) {
          throw Error('hasEnrollments error: ' + e.error.message)
        }
      },
    }),
    pdpPlanDiscounts: build.query<PdpPlanDiscountsQuery, PdpPlanDiscountsQueryVariables>({
      query: (variables) => ({ document: PdpPlanDiscountsDocument, variables }),
      providesTags: [MEDICARE_TAGS.PLAN_DISCOUNTS],
    }),
    maPlanDiscounts: build.query<MaPlanDiscountsQuery, MaPlanDiscountsQueryVariables>({
      query: (variables) => ({ document: MaPlanDiscountsDocument, variables }),
      providesTags: [MEDICARE_TAGS.PLAN_DISCOUNTS],
    }),
    maPlanFilter: build.query<MaPlanFilterQuery, MaPlanFilterQueryVariables>({
      query: (variables) => ({ document: MaPlanFilterDocument, variables }),
      providesTags: [MEDICARE_TAGS.PLAN_LIST],
    }),
    maPlanList: build.query<MaPlanListQuery, MaPlanListQueryVariables>({
      query: (variables) => ({ document: MaPlanListDocument, variables }),
      providesTags: [MEDICARE_TAGS.PLAN_LIST],
    }),
    pdpPlanList: build.query<PdpPlanListQuery, PdpPlanListQueryVariables>({
      query: (variables) => ({ document: PdpPlanListDocument, variables }),
      providesTags: [MEDICARE_TAGS.PDP_PLAN_LIST],
    }),
    pdpCompanies: build.query<PdpCompaniesQuery, PdpCompaniesQueryVariables>({
      query: (variables) => ({ document: PdpCompaniesDocument, variables }),
      providesTags: [MEDICARE_TAGS.PDP_PLAN_LIST],
    }),
    pdpQuote: build.query<PdpQuoteQuery, PdpQuoteQueryVariables>({
      query: (variables) => ({ document: PdpQuoteDocument, variables }),
      providesTags: [MEDICARE_TAGS.PDP_QUOTE],
    }),
    savePdpQuote: build.mutation<SavePdpQuoteMutation, SavePdpQuoteMutationVariables>({
      query: (variables) => ({ document: SavePdpQuoteDocument, variables }),
      invalidatesTags: [MEDICARE_TAGS.PDP_QUOTE],
    }),
  }),
})
