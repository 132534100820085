import {createAsyncThunk} from "@reduxjs/toolkit";
import {environment} from "../../../../environments/environment";
import { PersistentState } from '../lib/types';

export const fetchPersistentState = createAsyncThunk(
  'root/persistenceState',
  async (quoteId: string): Promise<{ state: PersistentState }> => {
    const response = await fetch(`${environment.userFlowRestUrl}/getState/${quoteId}`)
    const state = await response.json()
    return state;
  }
)
