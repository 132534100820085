import { useConfirm } from '../../components/confirm-dialog-context';
import React from 'react';
import DsnpOnModalContent from './ui/dsnp-on-modal-content';

export function useDsnpOnConfirm() {
  const confirm = useConfirm();

  return () => confirm({
    content: <DsnpOnModalContent />,
    okButtonTitle: 'Yes - I want to see D-SNP plans',
    cancelButtonTitle: 'No - return to plans'
  });
}
