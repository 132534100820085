import {useSaveMedicareQuoteMutation} from "../../../shared/api/medicare";
import {useAppSelector} from "../../../shared/hooks";
import {medicareQuote} from "../../../entities/ma-quote";
import {PlansFilterInput} from "@coverright/data-access/types/medicare";

export default function useMaSaveFilters() {
  const [save] = useSaveMedicareQuoteMutation();
  const quote = useAppSelector(medicareQuote);

  const saveFilters = (filters?: PlansFilterInput) => {
    if (filters) {
      save({
        data: {
          zip: quote?.zip,
          county: quote?.county,
          id: quote?.id,
          filters: {
            extraBenefits: filters?.extraBenefits as any,
            SNPTypes: filters?.SNPTypes,
            planTypes: filters?.planTypes,
            companies: filters?.companies,
            showTopDoctors: filters?.showTopDoctors,
            showTopDrugs: filters?.showTopDrugs,
          },
          planYear: filters?.planYear,
          preferredDoctors: undefined,
        }
      })
    }
  }

  return saveFilters;
}
