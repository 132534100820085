import {EnrolledPlan, PlanType} from "@coverright/data-access/medicare";
import * as _ from "lodash";

export default function chooseEnrolledPlanFromMany(enrolledPlans: EnrolledPlan[], type?: PlanType) {
  const validPlans = enrolledPlans.filter(p => type ? p.type === type : p.type !== PlanType.Mg);
  if (validPlans.length) {
    let plan = validPlans[0];
    if (validPlans.length > 1) {
      plan = _.orderBy(validPlans, 'effectiveDate', 'desc')[0];
    }
    return plan;
  }
  return undefined;
}
