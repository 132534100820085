import React from 'react';
import { Box, Typography } from '@mui/material';
import { V3InfoPanel } from '@coverright/ui/shared';
import { PreferredDrugOutput, DrugSelection } from '@coverright/widgets';

type DrugsProps = {
  value: PreferredDrugOutput[];
  onChange: (data: PreferredDrugOutput[]) => void;
};

export function DrugsTab(props: DrugsProps) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, p: 1 }}>
      <Box>
        <Typography variant={'h3'} align={'center'}>
          Add or update your drugs.
        </Typography>
        <Typography
          className={'fs-21 lh-32 medium'}
          sx={{ color: '#4D4D4D', my: { md: '4px' } }}
          align={'center'}
        >
          Drug prices may vary. Add any drugs you are prescribed.{' '}
        </Typography>
      </Box>

      <DrugSelection
        label={'Search Prescription Drug '}
        value={props.value}
        onChange={props.onChange}
      />
      <V3InfoPanel
        sx={{ mt: -4 }}
        title={'Can’t find your prescription?'}
        text={
          'Not all drugs are covered by Medicare plans. If you can’t find a prescription drug that’s important to you, continue entering the ones available and then speak to one of our licensed advisors.'
        }
      />
    </Box>
  );
}
