import React from 'react';
import { mgCompanies } from '../../../entities';
import { FilterProps, useAppSelector } from '../../../shared';
import {CompanyFilter} from "../../../features";


export default function(props: FilterProps) {
  const companies = useAppSelector(mgCompanies);
  return <CompanyFilter {...props} companies={companies || []} />
}
