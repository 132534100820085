import React from 'react';
import { Box, Typography } from '@mui/material';
import { DoctorSelection } from '@coverright/widgets';
import { V3InfoPanel } from '@coverright/ui/shared';
import { ProviderOutput } from '@coverright/data-access/types/medicare';
import { useLogEvent } from '@coverright/shared/analytics';
import { useAppSelector } from '../../../shared';
import { medicareQuote } from '../../../entities';

type DoctorProps = {
  value: ProviderOutput[];
  onChange: (data: ProviderOutput[]) => void;
};

const DoctorsTab = (props: DoctorProps) => {
  const quote = useAppSelector(medicareQuote);
  const logEvent = useLogEvent();

  if (!quote) return null;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, p: 1 }}>
      <Box>
        <Typography variant={'h3'} align={'center'}>
          Add or update your doctors
        </Typography>
        <Typography
          className={'fs-21 lh-32'}
          sx={{ color: '#333333' }}
          align={'center'}
        >
          Doctor networks vary by plan. Add any doctors you must see.
        </Typography>
      </Box>

      <DoctorSelection
        zip={quote.zip}
        value={props.value}
        onChange={(v) => {
          logEvent('Add doctor', {
            doctors: v,
          });
          props.onChange(v);
        }}
      />
      <V3InfoPanel
        sx={{ mt: '-32px' }}
        title={'Can’t find your doctor?'}
        text={
          'Don’t worry if you can’t find your doctor! Our licensed advisors can conduct a more detailed search before finalizing your plan. Enter what you can and we can do an initial search for those that are showing.'
        }
      />
    </Box>
  );
};

export default DoctorsTab;
