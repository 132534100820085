import * as React from 'react';
import { MedigapPlansFilterInput } from '@coverright/data-access/types/medigap';
import { useAppDispatch, useAppSelector } from '../../../shared';
import { mgFilters, setMgFilter } from '../../../entities';
import useSaveFilters from '../api/use-save-mg-filters';
import { useDebouncedEffect } from '@coverright/utils';
import { PropsWithChildren, useContext } from 'react';

interface IMgFiltersContext {
  filters?: MedigapPlansFilterInput,
  buildHandler: (key: keyof MedigapPlansFilterInput) => (value: any) => void
}

export const MgFiltersContext = React.createContext<IMgFiltersContext>({
  buildHandler: () => () => {}
});

const Provider = ({children}: PropsWithChildren) => {
  const filters = useAppSelector(mgFilters);
  const dispatch = useAppDispatch();
  const saveFilters = useSaveFilters();

  const buildHandler = (key: keyof MedigapPlansFilterInput) => (value: any) => {
    dispatch(setMgFilter({key, value}))
  }

  useDebouncedEffect(() => {
    saveFilters(filters);
  }, 800, [filters])

  return <MgFiltersContext.Provider value={{buildHandler, filters}}>
    {children}
  </MgFiltersContext.Provider>
}

export const withMgFiltersContext = (WrappedComponent: any) => (props: any) => (
  <Provider>
    <WrappedComponent {...props} />
  </Provider>
)

export function useMgFilters() {
  return useContext(MgFiltersContext)
}
