import { MaPlanListQuery } from '../../../shared/api/medicare';
import {
  ExtraBenefit,
  MedicareQuoteFilterExtraBenefit,
  MedicareQuoteOutput,
  PlansFilterInput, ProviderOutput
} from '@coverright/data-access/types/medicare';
import * as _ from 'lodash';
import useIsPpoAvailable from '../api/use-is-ppo-available';
import useIsTherePlanWithFullDrugCoverage from '../api/use-is-there-plan-with-full-drug-coverage';
import useTotalPlans from '../api/use-total-plans';

export default function useMessagesVars(quote: MedicareQuoteOutput, plans: MaPlanListQuery['plans']['data'], filters?: PlansFilterInput) {
  const isPpoAvailable = useIsPpoAvailable();
  const isTherePlanWithFullDrugCoverage = useIsTherePlanWithFullDrugCoverage(quote);
  const totalPlans = useTotalPlans(quote, filters);

  return {
    planCoversAllDoctorsExists: planCoversAllDoctorsExists(plans),
    isOnlyHMOSelected: isOnlyHMOSelected(filters),
    isTherePlansWithDoctorsCoverage: isTherePlansWithDoctorsCoverage(plans),
    plansLength: plans.length,
    preferredDoctorsLength: quote.preferredDoctors?.length || 0,
    preferredDrugsLength: quote.preferredDrugs?.length || 0,
    isPPOSelected: isPPOSelected(plans),
    lowestPlanPrice: lowestPlanPrice(plans),
    isFirstVisit: !!localStorage.getItem('visited'),
    isExtraBenefitsSelected: isExtraBenefitsSelected(filters),
    allDoctorsStatus: allDoctorsStatus(quote, plans),
    isPpoAvailable,
    isTherePlanWithFullDrugCoverage,
    totalPlans,
    isDSNPSelected: isDSNPSelected(filters),
    hasUnsupportedDrugs: hasUnsupportedDrugs(quote),
  }
}

const allDoctorsStatus = (quote: MedicareQuoteOutput, plans: MaPlanListQuery['plans']['data']) => (
  !!quote?.preferredDoctors?.reduce((prev: boolean, current: ProviderOutput) => (
    plans.some(plan => (
      plan.doctorsCoverage.some(cov => (
        cov.npi === current.npi && cov.coverage !== null && typeof cov.coverage !== 'undefined'
      ))
    ))
  ), true)
)

const isExtraBenefitsSelected = (filters?: PlansFilterInput) => (
  !!filters?.extraBenefits?.filter(b => (
    b !== ExtraBenefit.DrugCoverage && b !== ExtraBenefit.PartBPremiumGiveback
  )).length
)

const lowestPlanPrice = (plans: MaPlanListQuery['plans']['data']) => (
  _.min(plans.map(p => parseFloat(p.monthlyCost?.replace('$' , '') || '0'))) || 0
)

const isPPOSelected = (plans: MaPlanListQuery['plans']['data']) => (
  plans.some(p => p.planType === 'PPO')
)

const isTherePlansWithDoctorsCoverage = (plans: MaPlanListQuery['plans']['data']) => (
  plans.some(p => p.doctorsCoverage.length)
)

const isDSNPSelected = (filters?: PlansFilterInput) => (
  _.isEqual(filters?.SNPTypes, ["D_SNP"])
)

const isOnlyHMOSelected = (filters?: PlansFilterInput) => (
  _.isEqual(filters?.planTypes, ["HMO"])
)

const planCoversAllDoctorsExists = (plans: MaPlanListQuery['plans']['data']) => (
  plans.some(p => p.doctorsCoverage.length && p.doctorsCoverage.every(c => c.coverage))
)

const hasUnsupportedDrugs = (quote: MedicareQuoteOutput) => (
  quote.preferredDrugs.some(drug => drug.notSupported)
)
