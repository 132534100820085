import Message from '../ui/message';
import { Button, Collapse } from '@mui/material';
import * as React from 'react';
import { useDebouncedEffect } from '@coverright/utils';
import { useState } from 'react';
import { CRTooltip } from '@coverright/ui/inputs';


interface Props {
  lowestPlanPrice: number,
}
export default function(props: Props) {
  const {lowestPlanPrice} = props;
  const [open, setOpen] = useState(false);

  useDebouncedEffect(() => {
    setOpen(lowestPlanPrice > 100);
  }, 500, [lowestPlanPrice]);

  return <Collapse in={open}>
    <Message title={'4 reasons why your drug cost may be expensive!'}
             description={<ul>
               <li>Your drugs are expensive because you have entered a very high quantity or you may have entered an incorrect quantity/frequency.</li>
               <li>Your plan doesn’t covered the drugs you’ve entered!</li>
               <li>You’re in the Coverage Gap (or ‘Donut Hole’), explained <a target={'_blank'} href={'https://coverright.com/medicare-part-d/part-d-donut-hole/'}>here</a></li>
               <li>You could switch from a branded drug to a generic drug for better pricing</li>
             </ul>}
             onClose={() => setOpen(false)}
             data-test={'expensiveOpen'}
    />
  </Collapse>
}

