import { useMemo } from 'react';
import { useChatIdentityIdQuery } from '../../../shared/api/chat';

export default function useChatIdentityId() {
  const { data } = useChatIdentityIdQuery();

  return useMemo(() => {
    return data?.chatIdentityId;
  }, [data]);
}
