import {NavigationItem} from "../model/types";

export const findRouteIndex = (items: NavigationItem[], pathname: string): number => {
  if (pathname === '/') {
    return 0;
  }


  return items.findIndex(item => {
    for (const route of item.routes) {
      // does route contain variables?
      if (/:[a-z]*/gm.test(route)) {
        const path = pathname.split('/');
        const segments = route.split('/');
        for (const index in segments) {
          if (/:[a-z]/gm.test(segments[index]) && path[index]) {
            path[index] = segments[index];
          }
        }
        if (path.join('/') === route) {
          return true
        }
      } else if (route === pathname) {
        return true
      }
    }
    return false;
  });
}

export const findRouteInItemSets = (itemSets: NavigationItem[][], pathname: string) => {
  for (let i = 0; i < itemSets.length; i++) {
    const routeIndex = findRouteIndex(itemSets[i], pathname);
    if (~routeIndex) {
      return [i, routeIndex];
    }
  }
  return [-1, -1];
}
