import { PlanCategory } from "../../../features";

export const descriptions = {
  [PlanCategory.MostPopular]: {
    title: `Most Popular (F, G and N)`,
    subTitle: `Selected by over [b]80%[/b] of all Medicare Supplement enrollees.`,
    keyPoints: [
      `Selected by [b]80%[/b] of all Medicare Supplement enrollees.  These plans also represent the plans we recommend most to customers purchasing Medicare Supplement`,
      `You pay a higher monthly premium in exchange for minimal out-of-pocket costs`,
      `Most beneficiaries will select a plan in this category based on monthly premium budget`,
      `Plan G is now most popular plan for those new to Medicare as Plan F is no longer available to those who became newly eligible for Medicare after Jan 1, 2020`,
    ]
  },
  [PlanCategory.HighDeductible]: {
    title: `Plan F and G (high deductible)`,
    subTitle: `High deductible versions of the two most popular plans (Plan F, G)`,
    keyPoints: [
      `High deductible versions of the two most popular plans (Plan F and G)`,
      `You pay a deductible of {getDeductible('deductible')} before the plan begins to pay`,
      `Once the deductible is met, the plan covers 100% of the costs like a regular Plan F and G`,
      `These plans are a good option if you want network flexibility as well as catastrophic coverage and do not expect to use healthcare services often`,
      `Plan F is no longer available to those who became newly eligible for Medicare after Jan 1, 2020`,
    ]
  },
  [PlanCategory.CoreBenefits]: {
    title: `Core Benefits (A and B)`,
    subTitle: `Selected by [b]3%[/b] of all Medicare Supplement enrollees.`,
    keyPoints: [
      `Selected by [b]3%[/b] of all Medicare Supplement enrollees.  In general, we recommend consumers looking at Plan A/B to review Medicare Advantage options instead`,
      `These plans include basic benefits such as coverage for your 20% out-of-pocket for your medical services (also known as the Part B coinsurance)`,
      `Plan B includes coverage for the short-term hospital stay deductible of {getDeductible('inpatientHospitalDeductible')} which covers first 60 days of stay (also known as the Part A deductible)`,
      `You may sometimes find that a plan with less coverage may cost similar or more than a plan with more coverage.  This can sometimes occur because the plan with less coverage is less popular and carriers may need to charge more to cover the costs of paying claims (while more popular plans can average out claim costs across a larger population).`,
    ]
  },
}
