import Message from '../ui/message';
import { Button, Collapse } from '@mui/material';
import * as React from 'react';
import { useDebouncedEffect } from '@coverright/utils';
import { useState } from 'react';
import { CRTooltip } from '@coverright/ui/inputs';


interface Props {
  isOnlyHMOSelected: boolean,
  isDSNPSelected: boolean,
  isTherePlansWithDoctorsCoverage: boolean,
  planCoversAllDoctorsExists: boolean,
  isPpoAvailable: boolean,
}
export default function(props: Props) {
  const {isTherePlansWithDoctorsCoverage, planCoversAllDoctorsExists, isOnlyHMOSelected, isDSNPSelected, isPpoAvailable} = props;
  const [open, setOpen] = useState(false);

  useDebouncedEffect(() => {
    setOpen(isOnlyHMOSelected && !isDSNPSelected && isTherePlansWithDoctorsCoverage && !planCoversAllDoctorsExists && !isPpoAvailable);
  }, 500, [isTherePlansWithDoctorsCoverage, planCoversAllDoctorsExists, isOnlyHMOSelected, isDSNPSelected, isPpoAvailable]);

  return <Collapse in={open}>
    <Message title={'Not all your doctors are covered'}
             description={<>You are currently only viewing <CRTooltip placement={'bottom'} arrow title={'An HMO (Health Maintenance Organization) plan is a type of Medicare Advantage plan. HMO plan enrollees must use doctors and hospitals within a plan’s specific network to receive their covered services except for emergency situations.'}><span className={'underline medium'}>HMO</span></CRTooltip> plans, however, it appears that not all your doctors are covered by any plan.  If you must keep your doctors, we recommend looking at <span className={'underline medium'}>Medicare Supplement</span> options (as there are no or limited <CRTooltip placement={'bottom'} arrow title={'Coverage for medical services typically received in an outpatient facility or doctor\'s office, as well as emergency room and ambulance services.'}><span className={'underline medium'}>PPO</span></CRTooltip> Medicare Advantage plans in your area).</>}
             onClose={() => setOpen(false)}
             data-test={'setNotAllDoctorsWithoutPpoOpen'}
    />
  </Collapse>
}

