import { useAppDispatch, useAppSelector } from '../shared';
import { fetchVideoCategories, videoCategories } from '../entities';
import { useEffect } from 'react';

export function useVideoCategories() {
  const categories = useAppSelector(videoCategories);
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!categories.length) {
      dispatch(fetchVideoCategories());
    }
  }, [categories]);

  return categories;
}
