import {Box, Collapse, IconButton, Typography} from "@mui/material";
import {WarningOctagon, X} from "@phosphor-icons/react";
import React from "react";

interface Props {
  open: boolean,
  onClick: (benefit: string) => void,
  onClose: () => void,
}

export default function Banner({open, onClick, onClose}: Props) {
  return <Collapse in={open}>
    <Box sx={{borderRadius: '16px', border: '1px dashed #F9E69A', background: '#E8FDEB', p: 3, maxWidth: 866, mb: 3}}>
      <Box  sx={{display: 'flex', alignItems: {xs: 'flex-start', sm: 'center'}, justifyContent: 'space-between'}}>
        <Box  sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'row'}, alignItems: {sm: 'center'}, gap: 1}}>
          <WarningOctagon size={24} color={'#000000'} weight={'fill'} />
          <Typography className={'fs-24 semi-bold lh-32'}>
            You may have hundreds of dollars waiting to be activated!
          </Typography>
        </Box>

        <IconButton onClick={() => onClose()}>
          <X size={24} color="#000000" weight="regular" />
        </IconButton>
      </Box>

      <Typography>
        Check out your <span onClick={() => onClick('flex_card_allowance')} className={'underline pointer'}>Flex Card</span>, <span onClick={() => onClick('grocery_allowance')} className={'underline pointer'}>Grocery</span>, <span onClick={() => onClick('over_the_counter_otc_allowance')} className={'underline pointer'}>Over The Counter (OTC)</span>, and <span onClick={() => onClick('telehealth_and_transportation')} className={'underline pointer'}>Transportation allowance</span> benefits below to see if you have credits waiting to be activated. Some offers expire, so act now!
      </Typography>
    </Box>
  </Collapse>
}
