import {Box, Card} from "@mui/material";
import {mgComparisonPlans, Paginator, usePageSize} from "../../../features";
import {PlanTable} from "../../../widgets/mg-plan-list/ui/plan-table";
import * as _ from "lodash";
import {V3Preloader} from "@coverright/ui/shared";
import {Footer} from "@coverright/ui/marketplaces";
import React from "react";
import {useAppSelector} from "../../../shared";
import {medigapQuote} from "../../../entities";
import {useMgComparisonPlanList} from "../api/use-mg-comparison-plan-list";

export function MgComparisonTable() {
  const quote = useAppSelector(medigapQuote);
  const {plans, total, isLoading, isSuccess} = useMgComparisonPlanList();
  const [page, setPage] = React.useState(0);
  const pageSize = usePageSize();
  const totalColumns = Object.keys(plans).length

  return <Box sx={{p: 0, flex: 1, display: 'flex', flexDirection: 'column', gap: 3}}>
    {(!!total && isSuccess) && <>
      <Card sx={{p: '16px 0'}}>
        <Paginator onChange={setPage}
                   page={page}
                   pageSize={pageSize}
                   loading={isLoading}
                   total={totalColumns} />
      </Card>
      <PlanTable plans={_.pick(plans, Object.keys(plans).slice(page * pageSize, page * pageSize + pageSize))} />
      {(!isLoading && !!totalColumns) && <Card sx={{p: '16px 0'}}>
        <Paginator onChange={setPage}
                   page={page}
                   pageSize={pageSize}
                   loading={isLoading}
                   total={totalColumns}
                   scrollTo={document.getElementById("mg-info")}
        />
      </Card>}
    </>}
    {isLoading && <V3Preloader sx={{mt: 4, mb: 6}} />}
    <Footer sx={{maxWidth: 'initial'}} zip={quote?.medigapFilterState.zip} />
  </Box>
}
