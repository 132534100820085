import {Box, Tab, Tabs, Typography, useMediaQuery, useTheme} from "@mui/material";
import React, {ReactChildren, ReactNode} from "react";
import {TabPanel} from "./index";
import SwipeableViews from "react-swipeable-views";

type Props = {
  tabs: {
    icon: React.FC,
    label: JSX.Element,
  }[],
  initialIndex?: number,
  children: JSX.Element[],
  id?: string
}

export function StyledTabs(props: Props) {
  const [value, setValue] = React.useState(props.initialIndex ?? 0);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  return <>
    <Tabs sx={{px: 1}}
          onChange={(e, newVal) => setValue(newVal)}
          value={value}
          id={props.id}
          variant="fullWidth">
      {props.tabs.map((tab, i) => <Tab value={i}
                                       key={i}
                                       sx={{justifyContent: 'flex-start', alignItems: 'flex-start'}}
                                       iconPosition={phone ? "top" : "start"}
                                       icon={<TabIcon Icon={tab.icon} active={value === i} />}
                                       label={tab.label} />)}

    </Tabs>
    <SwipeableViews
      axis={'x'}
      index={value}
    >
      {React.Children.map(props.children, (child, i) => {
        if (React.isValidElement(child)) {
          return <TabPanel value={value} index={i} key={i}>
            {React.cloneElement(child)}
          </TabPanel>
        }
        return null
      })}
    </SwipeableViews>
  </>
}

const TabIcon = ({Icon, active}: {Icon: any, active: boolean}) => {
  return <Box sx={{backgroundColor: active ? '#ECF5F8' : '#D9D9D9', p: 1, borderRadius: '8px', mr: {sm: 2}, mb: {xs: 2, sm: 0}}}>
    <Icon size={32} color={active ? '#1C434F' : '#808080'} weight={active ? 'fill' : 'regular'} />
  </Box>
}
