import { useSaveUserProfileMutation } from '../../../shared/api/enrollment';
import { useSnackbar } from 'notistack';
import { getStoredUser, setStoredUser } from '@coverright/utils';
import { GraphQLErrorType } from '@coverright/data-access/apollo-clients';

export function useSaveProfile(onCompleted: () => void, onEmailExists: () => void) {
  const { enqueueSnackbar } = useSnackbar();
  const [save, {isLoading}] = useSaveUserProfileMutation();

  const saveProfile = (data: ProfileInput) => {
    setStoredUser({ ...getStoredUser(), ...data })
    save({ data }).then(() => {
      enqueueSnackbar('Your contact settings have been updated', {variant: 'success'});
      onCompleted();
    })
      .catch((error: any) => {
        if (error?.graphQLErrors) {
          switch (error.graphQLErrors[0]?.extensions?.type) {
            case "INVALID_EMAIL":
            case GraphQLErrorType.ALREADY_EXISTS: {
              onEmailExists();
              if ((window as any).track) {
                (window as any).track('Error - user already exists', {email: getStoredUser()?.email});
              }
              break;
            }
            case GraphQLErrorType.NO_CONNECTION:
              // setSaveError("Service is not available");
              break;
            default:
            // setSaveError("Unknown error")
          }
        } else if (error?.message) {
          // setSaveError(error?.message)
        }
      })
  }

  return {saveProfile, loading: isLoading}
}

interface ProfileInput {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  cId?: string;
  profileId?: string;
}
