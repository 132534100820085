import moment from 'moment/moment';

export function getAEPStartDate(date = new Date()): Date {
  let year = date.getFullYear();
  if (moment(date).isAfter(moment({month: 10, day: 7, year}))) {
     year++;
  }

  return moment({month: 9, day: 15, year}).toDate()
}

export function daysTillStartAEP(date = new Date()): number {

  return moment(getAEPStartDate(date)).diff(moment(date), 'days')
}

export function daysTillEndAEP(date: Date): number {
  let year = date.getFullYear();
  if (moment(date).isAfter(moment({month: 11, day: 7, year}))) {
    year++;
  }

  return moment({month: 11, day: 7, year}).diff(moment(date), 'days')
}
