import {Card, Divider, Typography, TextField, Box, IconButton} from "@mui/material";
import React, {useState} from "react";
import {PaperPlaneRight} from "@phosphor-icons/react";
import { useLogEvent } from '@coverright/shared/analytics';


interface Props {
  onSubmit: (text: string) => void
}

export default function InputArea({onSubmit}: Props) {
  const [value, setValue] = useState('')
  const logEvent = useLogEvent();

  const submit = () => {
    onSubmit(value);
    setValue('');
  }

  return <div className={'mr-16'}>
    <Typography variant={'h4'} className={'fs-24 lh-30'}>Chat now</Typography>
    <Divider sx={{mt: 1, mb: 0}} />
    <TextField
      sx={{'& textarea': {fontSize: '18px!important'}}}
      data-test='chat-input'
      value={value}
      onChange={e => setValue(e.target.value)}
      fullWidth
      onClick={() => logEvent('click', {
        element_title: 'Chat input',
        element_id: 'chat-input',
        element_type: 'input'
      })}
      inputProps={{
        onKeyDown: e => {
          if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            submit();
          }
        }
      }}
      id="standard-multiline-static"
      multiline
      className={'mb-0'}
      rows={1}
      variant={"standard"}
    />
    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
      <IconButton onClick={submit} data-test='chat-submit'>
        <PaperPlaneRight size={32} />
      </IconButton>
    </Box>
  </div>
}
