import { Box, Card } from '@mui/material';
import React, { useState } from 'react';
import {useAppSelector} from "../../shared";
import {profile as profileSelector} from "../../entities/profile";
import { PdpPlanListQuery } from '../../shared/api/medicare';
import usePlanList from './api/use-plan-list';
import UpdateZip from './ui/update-zip';
import { V3Preloader } from '@coverright/ui/shared';
import { Footer } from '@coverright/ui/marketplaces';
import { useDebouncedEffect } from '@coverright/utils';
import { FilterBadges, LoadMorePlansButton, PlansCountRepresentDisclamer } from '../../features';
import useFilterBadges from './lib/use-filter-badges';
import MemoizedList from './ui/memoized-list';
import { pdpQuote } from '../../entities/pdp-quote';
import { pdpFilters, pdpSortBy } from '../../entities';

export function PdpPlanList() {
  const quote = useAppSelector(pdpQuote);
  const profile = useAppSelector(profileSelector);
  const sortBy = useAppSelector(pdpSortBy);
  const filters = useAppSelector(pdpFilters);
  const [page, setPage] = useState(0);
  const {plansTotal, loadPlans, hasMorePlans, isLoading, isSuccess} = usePlanList();
  const [plans, setPlans] = useState<PdpPlanListQuery['PdpPlans']['data']>([]);
  const badges = useFilterBadges();

  const load = async (page: number) => {
    if (quote && quote.zip && quote.countyName) {
      return await loadPlans(page, sortBy, quote.zip, quote.countyName, filters, quote.clientId)
    }
    return [];
  }

  useDebouncedEffect(() => {
    setPlans([]);
    load(0).then(setPlans)
  }, 600, [quote, filters, sortBy])

  const loadMore = () => {
    const newPage = page + 1
    setPage(newPage)
    load(newPage).then(response => {
      setPlans(prev => [...prev, ...response]);
    })
  }

  return <>
    <Card sx={{p: 4}}>
      <UpdateZip loading={isLoading} plansTotal={plansTotal} />
      <PlansCountRepresentDisclamer zip={quote?.zip || undefined}
                                    county={quote?.countyName}
                                    state={profile?.state}
                                    type={'PDP'} />
      <FilterBadges badges={badges} />
    </Card>

    <Box sx={{p: 0, flex: 1, display: 'flex', flexDirection: 'column', gap: 3}}>
      {(!!plans.length && isSuccess) && <MemoizedList plans={plans} />}
      {(!isLoading && isSuccess && hasMorePlans) && <Box textAlign={"center"} mt={'32px'}>
        <LoadMorePlansButton id={'pdp-load-more-plans-button'} disabled={isLoading} onClick={loadMore} />
      </Box>}
      {isLoading && <V3Preloader sx={{mt: 4, mb: 6}} />}
      <Footer sx={{maxWidth: 'initial'}} zip={quote?.zip || undefined} pdp />
    </Box>
  </>
}


