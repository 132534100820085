import { environment } from '../../../../environments/environment';

export const savePersistentState = async (state: any) => {
  return fetch(environment.userFlowRestUrl + '/saveState', {
    body: JSON.stringify(state),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
  })
}
