import { Button, CircularProgress } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import useResetQuizAnswers from '../lib/use-reset-quiz-answers';
import {ArrowLeft} from "@phosphor-icons/react";

export default function RestartQuizButton({label}: {label?: string}) {
  const { resetQuizAnswers, isLoading } = useResetQuizAnswers();
  const navigate = useNavigate();

  const onRestartClick = () => {
    resetQuizAnswers()
      .then(() => navigate('/quiz/q1'))
  }

  if (isLoading) {
    return <CircularProgress size={20} />
  }

  return <Button id={'restart-quiz-button'}
                 onClick={onRestartClick}
                 variant={'text'}
                 size={'small'}
                 startIcon={<ArrowLeft size={16} color="#1C434F" weight="regular" />}
                 sx={{ color: '#1C434F', cursor: 'pointer', width: 'fit-content', fontSize: 18 }}>
    {label || 'Take our Medicare quiz'}
  </Button>
}
