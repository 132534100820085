import { enrolledPlan as enrolledPlanSelector, useAppSelector } from '../../../shared';
import { medicareQuote } from '../../../entities';
import { useLazyMaPlanDiscountsQuery, useLazyPdpPlanDiscountsQuery } from '../../../shared/api/medicare';
import { useEffect, useMemo } from 'react';
import { PlanType } from '@coverright/data-access/medicare';

export default function usePlan() {
  const maQuote = useAppSelector(medicareQuote);
  const enrolledPlan = useAppSelector(enrolledPlanSelector);
  const [getMaPlan, {data: maData, isLoading: maLoading}] = useLazyMaPlanDiscountsQuery()
  const [getPdpPlan, {data: pdpData, isLoading: pdpLoading}] = useLazyPdpPlanDiscountsQuery()

  useEffect(() => {
    if (enrolledPlan && enrolledPlan.zip && enrolledPlan.county) {
      const vars = {
        zip: enrolledPlan.zip,
        countyName: enrolledPlan.county,
        planYear: enrolledPlan.year,
        bidId: enrolledPlan.bidId,
      };

      if (enrolledPlan.type === PlanType.Ma) {
        getMaPlan({
          ...vars,
          quoteId: maQuote?.id
        })
      } else if (enrolledPlan.type === PlanType.Pdp) {
        getPdpPlan({
          ...vars,
          clientId: maQuote?.clientId
        })
      }
    }
  }, [enrolledPlan, maQuote]);

  const plan = useMemo(() => {
    return maData?.maPlanDiscounts || pdpData?.pdpPlanDiscounts;
  }, [maData, pdpData]);

  return {plan, loading: maLoading || pdpLoading}
}
