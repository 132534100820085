export const youTubeScriptLoaded = () => (window as any).YT;

export const loadYouTubeScript = (onLoadedCallback: () => void) => {
  const tag = document.createElement('script');
  tag.src = '//www.youtube.com/iframe_api';

  // onYouTubeIframeAPIReady will load the video after the script is loaded
  (window as any).onYouTubeIframeAPIReady = onLoadedCallback;

  const firstScriptTag = document.getElementsByTagName('script')[0];
  (firstScriptTag as any).parentNode.insertBefore(tag, firstScriptTag);
};

export const initPlayer = (
  onPlayerReady: (event: any) => void,
  onPlayerStateChange: (event: any) => void
) => {
  const playerProps = {
    height: '350',
    width: '100%',
    playerVars: {
      playsinline: 1,
      rel: 0,
      modestbranding: 1,
      autoplay: 0,
      controls: 0,
    },
    events: {
      onReady: onPlayerReady,
      onStateChange: onPlayerStateChange,
    },
  };

  return new (window as any).YT.Player('player', playerProps);
};
