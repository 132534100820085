import {Box, IconButton, styled, Typography, useMediaQuery, useTheme} from '@mui/material';
import React from 'react';
import { Phone, X } from '@phosphor-icons/react';
import { AppActionTypes, AppContext } from '@coverright/shared/contexts';

export default function ExpectCallNotification() {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('md'));
  const [{state}, dispatch, loaded] = React.useContext(AppContext);
  const [close, setClose] = React.useState(false)

  React.useEffect(() => {
    if (loaded && !state.greetingShown) {
      dispatch({
        type: AppActionTypes.SAVE_STATE,
        payload: {
          stateKey: 'greetingShown',
          value: true
        },
      });
   }
  }, [loaded])

  const show = React.useMemo(() => {
    return loaded && !state.greetingShown && !close
  }, [loaded, close])

  if (!show) {
    return null;
  }

  return <Container data-test={'expect-call-notification'}>
    <Box sx={{display: 'flex', gap: {md: 4}, flexDirection: {xs: 'column', md: 'row'}, flex: 1}}>
      <Box sx={{display: 'flex', gap: 1}}>
        <Box sx={{minWidth: 24, width: {xs: 24, md: 110}, height: {xs: 24, md: 110}, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', border: {xs: '2px solid #1C434F', md: '5px solid #1C434F'}}}>
          <Phone size={phone ? 17 : 80} color="#1c434f" weight="fill" />
        </Box>
        {!!phone && <Typography className={'semi-bold'}>Welcome to CoverRight</Typography>}
      </Box>
      <div>
        {!phone && <Typography className={'semi-bold'}>Welcome to CoverRight</Typography>}
        <Typography className={'mt-8'}>Expect a call from one of our licensed agents from (607-208-9177) within 10 minutes.<br />They are eager to assist you with any inquiries you may have!</Typography>
      </div>
    </Box>
    <div>
      <IconButton onClick={() => setClose(true)}>
        <X size={24} color="#000000" weight="regular" />
      </IconButton>
    </div>
  </Container>
}

const Container = styled(Box)({
  display: 'flex',
  gap: 32,
  background: '#FFFFFF',
  padding: 24,
  borderRadius: '16px',
  marginBottom: 32,
  border: '1px solid #1C434F'
})
