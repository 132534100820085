import React from 'react';
import { CRTooltip } from '@coverright/ui/inputs';
import { Typography } from '@mui/material';
import { MedigapPlanOutputWithDetails } from '@coverright/data-access/medigap';

export const Copays = ({plan}: {plan?: MedigapPlanOutputWithDetails}) => {
  if (!plan) return null;

  return <div>
    <CRTooltip placement={'top'} arrow
               title={'Coverage for medical services typically received in an outpatient facility or doctor\'s office, as well as emergency room and ambulance services.'}>
      <div>
        <Typography className={'bold fs-14 mb-8 lh-14'}>Copays/coinsurance:</Typography>
        <div className={'fs-12 lh-15 mb-10'}>{plan.partBServices}</div>
      </div>
    </CRTooltip>
  </div>;
}
