import {Box, Collapse, Typography} from "@mui/material";
import {PlusCircle, Question} from "@phosphor-icons/react";
import Markdown from "react-markdown";
import React from "react";
import {BenefitEntity} from "../../../entities";
import * as _ from 'lodash';
import { markdownBenefit } from '../lib/markdown-benefit';

interface BenefitProps {
  open: boolean,
  onClick: () => void,
  benefits: BenefitEntity[],
  title: string,
  id: string
}

export const Benefit = ({open, onClick, benefits, title, id}: BenefitProps) => {
  return <Box sx={{py: 2, borderBottom: '1px solid #B3B3B3', scrollMarginTop: '120px'}} id={id}>
    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1, cursor: 'pointer'}} onClick={onClick}>
      <Typography className={'fs-24 epilogue semi-bold'}>{title}</Typography>
      <Box sx={{transform: open ? 'rotate(45deg)' : 'rotate(0)', transition: 'all 0.3s'}}>
        <PlusCircle size={32} color={'#1C434F'} />
      </Box>
    </Box>
    <Collapse in={open}>
      <Box sx={{mt: 2}}>
        <Box sx={{display: {xs: 'none', md: 'flex'}, gap: 3, alignItems: 'center', py: 1, borderBottom: '1px solid #B3B3B3'}}>
          <Typography sx={{color: '#B3B3B3'}} className={'fs-14 semi-bold w-230'}>Benefits</Typography>
          <Typography sx={{color: '#B3B3B3'}} className={'fs-14 semi-bold'}>Coverage</Typography>
        </Box>

        {benefits.map(markdownBenefit).map((benefit, i) => (
          <Box key={id + benefit.name}
               id={id + '/' + _.snakeCase(benefit.name)}
               sx={{
                 display: 'flex',
                 flexDirection: {xs: 'column', md: 'row'},
                 gap: 3,
                 py: 4,
                 borderBottom: i < benefits.length - 1 ? '1px solid #B3B3B3' : '',
                 scrollMarginTop: '120px'
               }}>
            <Box sx={{width: {md: 230}}}>
              <Typography sx={{fontSize: {xs: 22, md: 18}}} className={'epilogue semi-bold'}>{benefit.name}</Typography>
            </Box>
            <Box flex={1}>
              <Typography className={'fs-18 epilogue semi-bold'}>Details</Typography>
              <Box sx={{'& *': {color: '#666'}, '& ul': {ml: 3, paddingInlineStart: 0}}}>
                {benefit.description || <Typography>Not covered</Typography>}
              </Box>
            </Box>
            {(!!benefit.description && benefit.howToActivate) && (
              <Box sx={{ ml: {md: 4}, width: {md: 235} }}>
                <Box sx={{
                  mb: 3,
                  display: 'flex',
                  width: 'fit-content',
                  gap: .5,
                  alignItems: 'center',
                  py: .5,
                  px: 1,
                  borderRadius: '9px',
                  background: '#F5F5F5'
                }}>
                  <Typography sx={{ color: '#1C434F' }} className={'fs-14 lg-24'}>How to activate</Typography>
                  <Question size={24} color={'#1C434F'}/>
                </Box>
                <Box sx={{ '& *': { color: '#666' }, '& ul': { ml: 3, paddingInlineStart: 0 } }}>
                  <Markdown>
                    {benefit.howToActivate}
                  </Markdown>
                </Box>
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </Collapse>
  </Box>
}
