import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query'
import {api as enrollmentApi} from "../shared/api/enrollment";
import {api as medigapApi} from "../shared/api/medigap";
import {api as medicareApi} from "../shared/api/medicare";
import {api as chatApi} from "../shared/api/chat";
import {rootReducer} from '../shared';
import {
  benefitReducer,
  maPlanReducer,
  maQuoteReducer,
  mgQuoteReducer,
  pdpPlanReducer,
  pdpQuoteReducer, profileReducer,
  videoCategoryReducer
} from '../entities';
import {comparisonReducer} from "../features";


export const store = configureStore({
  reducer: {
    root: rootReducer,
    maQuote: maQuoteReducer,
    mgQuote: mgQuoteReducer,
    pdpQuote: pdpQuoteReducer,
    maPlan: maPlanReducer,
    pdpPlan: pdpPlanReducer,
    profile: profileReducer,
    comparison: comparisonReducer,
    videoCategory: videoCategoryReducer,
    benefit: benefitReducer,
    [chatApi.reducerPath]: chatApi.reducer,
    [enrollmentApi.reducerPath]: enrollmentApi.reducer,
    [medicareApi.reducerPath]: medicareApi.reducer,
    [medigapApi.reducerPath]: medigapApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat([
        enrollmentApi.middleware,
        medicareApi.middleware,
        medigapApi.middleware,
        chatApi.middleware,
      ]),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch)
