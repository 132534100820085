import { CRModal } from '@coverright/ui/shared';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { CheckCircle, X } from '@phosphor-icons/react';
import * as React from 'react';
import { useRequestUrgentCall } from '@coverright/data-access/enrollment';
import { UrgentCallTypeDto } from '@coverright/data-access/types/enrollment';
import { useLogEvent } from '@coverright/shared/analytics';

interface UrgentCallModalProps {
  open: boolean,
  onClose: () => void
}

enum ModalMode {
  ReceivedRequest
}

export default function UrgentCallModal(props: UrgentCallModalProps) {
  const [requestUrgentCall] = useRequestUrgentCall();
  const [modalMode, setModalMode] = React.useState<ModalMode | undefined>()
  const logEvent = useLogEvent();

  React.useEffect(() => {
    if (logEvent && props.open) {
      logEvent('modal_open', {
        element_title: 'When can we call you?',
        element_id: 'urgent-call-modal'
      })
    }
  }, [logEvent, props.open])

  const onClick = (urgentCallType: UrgentCallTypeDto) => {
    requestUrgentCall({variables: {urgentCallType}}).then(() => {
      setModalMode(ModalMode.ReceivedRequest);
    })
  };

  return <CRModal BackdropProps={{
                    sx: {
                      background: 'rgba(0, 0, 0, 0.25)',
                    }
                  }} data-test={'urgent-call-modal'} width={480} paperPadding={'32px'} open={props.open} onClose={props.onClose}>
    <>
      <Box sx={{display: 'flex', width: 1, justifyContent: 'flex-end'}}>
        <IconButton
          size="small"
          edge="start"
          onClick={props.onClose}
          data-test={'modal-close-button'}
        >
          <X size={32} color="#000" />
        </IconButton>
      </Box>
      {modalMode !== ModalMode.ReceivedRequest && <>
        <Typography className={'epilogue fs-32 lh-40 semi-bold'} align={'center'}>
          When can we call you?
        </Typography>
        <Box sx={{display: 'flex', width: 1, justifyContent: 'center', mt: 3}}>
          <Button id={'urgent-call-modal-this-morning-button'} fullWidth variant={'outlined'} color={'primary'} onClick={() => onClick(UrgentCallTypeDto.ThisMorning)}>This morning</Button>
        </Box>
        <Box sx={{display: 'flex', width: 1, justifyContent: 'center', mt: 3}}>
          <Button id={'urgent-call-modal-this-afternoon-button'} fullWidth variant={'outlined'} color={'primary'} onClick={() => onClick(UrgentCallTypeDto.ThisAfternoon)}>This afternoon</Button>
        </Box>
        <Box sx={{display: 'flex', width: 1, justifyContent: 'center', my: 3}}>
          <Button id={'urgent-call-modal-soon-button'} fullWidth variant={'contained'} color={'primary'} onClick={() => onClick(UrgentCallTypeDto.AsSoonAsPossible)}>As Soon As Possible</Button>
        </Box>
        <Typography className={'fs-18'} align={'center'}>
          Or call us now <Box component={'a'} href={'tel:+18889697667'} sx={{color: '#1C434F', textDecoration: 'none'}}>(888) 969 7667 | TTY: 711</Box>
        </Typography>
      </>}
      {modalMode === ModalMode.ReceivedRequest && <>
        <Box sx={{display: 'flex', width: 1, justifyContent: 'center', my: 3}}>
          <CheckCircle size={56} color="#0B741C" weight="duotone" />
        </Box>
        <Typography className={'epilogue fs-24 lh-40 semi-bold'} align={'center'}>
          Thanks!<br />
          A licensed agent will reach out to you.
        </Typography>
      </>}
    </>
  </CRModal>
}
