import React from 'react';
import { UpdateZip } from '@coverright/widgets';
import { useAppSelector } from '../../../shared';
import { useSavePdpQuoteMutation } from '../../../shared/api/medicare';
import { pdpQuote } from '../../../entities';

interface Props {
  loading: boolean;
  plansTotal?: number;
}
export default function ({ loading, plansTotal }: Props) {
  const quote = useAppSelector(pdpQuote);
  const [saveQuote] = useSavePdpQuoteMutation();

  const onChange = (zip: string, countyName: string) => {
    saveQuote({
      input: {
        id: quote?.id,
        cId: quote?.cId,
        insulinSavings: quote?.insulinSavings,
        countyName,
        zip,
      },
    });
  };

  return (
    <UpdateZip
      loading={loading}
      zip={quote?.zip}
      county={quote?.countyName}
      onChange={onChange}
      plansTotal={plansTotal}
    />
  );
}
