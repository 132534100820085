import { createSlice } from '@reduxjs/toolkit';
import {api} from "../../../shared/api/enrollment";
import {UserProfileQuery} from "../../../shared/api/enrollment";


type ProfileSlice = {
  profile?: UserProfileQuery['userProfile']
}

const initialState: ProfileSlice = {

}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  selectors: {
    profile(state) {
      return state.profile;
    }
  },
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.userProfile.matchFulfilled,
      (state, {payload}) => {
        state.profile = payload.userProfile;
      }
    )
  }
})

export const profileReducer = profileSlice.reducer;

export const {profile} = profileSlice.selectors;




