export const options: {[key: string]: Option[]} = {
  'How would you prefer to pay for your healthcare costs?': [
    {
      label: 'Pay a monthly premium for comprehensive coverage, so I don’t have to worry about out-of-pocket costs when I use healthcare services',
      value: true
    },
    {
      label: 'Pay lower (or no) monthly premiums to save money. Share out-of-pocket costs with my insurance company as and when I use healthcare services',
      value: false
    },
  ],
  'How important is choice when it comes to visiting doctors?': [
    {
      label: 'I must be able to see doctors outside of a network',
      value: true
    },
    {
      label: 'I’m okay with using a network of healthcare providers if it lowers costs',
      value: false
    },
  ],
  'Are additional extra benefits important to you?': [
    {
      label: 'No — I’m indifferent to whether my plan includes any extra benefits. I’m happy to purchase this separately if I need it',
      value: false
    },
    {
      label: 'Yes — I’m looking for a plan that includes some coverage for extra benefits such as dental, vision and hearing',
      value: true
    },
  ],
  'Do you currently receive Medicaid benefits?': [
    {
      label: 'No — I do not receive any benefits from Medicaid',
      value: false
    },
    {
      label: 'Yes — I currently receive Medicaid benefits',
      value: true
    },
  ],
}

export interface Option {
  label: string,
  value: boolean
}
