import {Box, Typography} from "@mui/material";
import {WarningCircle} from "@phosphor-icons/react";
import React from "react";

const DsnpOnModalContent = () => <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} data-test={'dsnp-on-modal'}>
  <Typography className={'epilogue fs-24 lh-30 semi-bold mb-16'} align={'center'}>
    Are you sure you want to see D-SNP plans?
  </Typography>
  <WarningCircle size={80} color="#1C434F" className={'mb-16'} weight="fill" />
  <Typography className={'epilogue fs-20 lh-30 medium mb-8'} align={'center'}>
    You might not be eligible to enroll
  </Typography>
  <Typography align={'center'} className={'fs-18 lh-22'}>
    Dual-eligible Special Needs Plans (D-SNP) are special plans designed for Medicare-Medicaid ‘dual-eligible’ beneficiaries. <br /><br /> You can not enroll in a D-SNP plan unless you are eligible for both Medicare and Medicaid.
  </Typography>
</Box>

export default DsnpOnModalContent;
