import { useLocation } from 'react-router';
import * as React from 'react';
import QuizRouter from '../pages/quiz';
import DoctorsDrugs from '../pages/doctors-drugs';
import Dashboard from '../pages/dashboard';
import PlanFinder from '../pages/plan-finder';
import PlanDetails from '../pages/ma-plan-details';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import LearningCenter from '../pages/learning-center';
import { AccountSettings } from '../pages/account-settings';
import Video from '../pages/video/video';
import MAComparison from '../pages/ma-comparison';
import MgComparison from '../pages/mg-comparison';
import Doctors from '../pages/doctors';
import Drugs from '../pages/drugs';
import Benefits from '../pages/benefits';
import { MADrugCost, PDPDrugCost } from '../pages/drug-cost';
import { MagnifyingGlass, Notebook, Pill, ShieldPlus, Stethoscope, Video as VideoIcon } from '@phosphor-icons/react';



type RouteKeys =
  'toDoList' |
  'doctorsDrugs' |
  'planFinder' |
  'planFinderMA' |
  'planComparisonMA' |
  'planDetails' |
  'drugCostPage' |
  'drugCost' |
  'planFinderMG' |
  'planComparisonMG' |
  'quizzes' |
  'quiz' |
  'learn' |
  'videos' |
  'video' |
  'accountSettings' |
  'dashboard' |
  'benefits' |
  'doctors' |
  'drugs' |
  'plans' |
  'pdpDrugCostPage' |
  'pdpDrugCost' |
  'planFinderPDP'
  ;


export const List: any[] = [
  {
    label: 'Dashboard',
    routes: {
      dashboard: {value: '/dashboard', component: Dashboard},
    },
    icon: Notebook,
  },
  {
    label: 'Checklist',
    routes: {
    },
    icon: Notebook,
  },
  {
    label: 'Doctors & Drugs',
    routes: {
      doctorsDrugs: {value: '/doctors-drugs', component: DoctorsDrugs},
    },
    icon: Pill,
  },
  {
    label: 'Benefits',
    routes: {
      benefits: {value: '/benefits', component: Benefits},
    },
    icon: ShieldPlus,
  },
  {
    label: 'Doctors',
    routes: {
      doctors: {value: '/doctors', component: Doctors},
    },
    icon: Stethoscope,
  },
  {
    label: 'Drugs',
    routes: {
      drugs: {value: '/drugs', component: Drugs},
    },
    icon: Pill,
  },
  {
    label: 'Plans',
    routes: {
      plans: {value: '/plans', component: PlanFinder},
      planFinder: {value: '/plan-finder', component: PlanFinder},
      planFinderMA: {value: '/plan-finder-ma', component: PlanFinder},
      planComparisonMA: {value: '/plan-comparison-ma', component: MAComparison},
      planDetails: {value: '/plan-finder-ma/:id', component: PlanDetails},
      drugCostPage: {value: '/drug-cost-breakdown', component: MADrugCost},
      drugCost: {value: '/drug-cost-breakdown/:id', component: MADrugCost},

      pdpDrugCostPage: {value: '/pdp-drug-cost-breakdown', component: PDPDrugCost},
      pdpDrugCost: {value: '/pdp-drug-cost-breakdown/:id', component: PDPDrugCost,},

      planFinderPDP: {value: '/plan-finder-pdp', component: PlanFinder},

      planFinderMG: {value: '/plan-finder-mg', component: PlanFinder},
      planComparisonMG: {value: '/plan-comparison-mg', component: MgComparison},
      quizzes: {value: '/quiz', component: QuizRouter},
      quiz: {value: '/quiz/:question?', component: QuizRouter},
    },
    icon: MagnifyingGlass,
  },
  {
    label: 'Learn',
    routes: {
      learn: {value: '/learn', component: LearningCenter},
      videos: {value: '/video', component: Video},
      video: {value: '/video/:id', component: Video},
    },
    icon: VideoIcon,
  },
  {
    label: 'Settings',
    routes: {
      accountSettings: {value: '/account-settings', component: AccountSettings},
    },
    icon: VideoIcon,
  },
]

export default function Router() {
  const location = useLocation();

  return <TransitionGroup>
    <CSSTransition
      key={location.key}
      classNames={'page'}
      timeout={300}
      onEnter={() => {
        document.body.scrollIntoView({ behavior: 'auto' });
      }}
      onExiting={() => {
        window.scrollTo({ top: 0, left: 0 })
      }}
      onExited={() => {
      }}
    >
      {/*<Switch location={location}>
        {Object.values(Routes.getRoutes()).map(o => <Route
          key={o.value}
          exact
          path={o.value}
          component={o.component}
        />)}
        <Route exact path={'/'}>
          <Redirect to={Routes.getRouteUrl('toDoList')} />
        </Route>
        <Route>
          <Redirect to={Routes.getRouteUrl('toDoList')} />
        </Route>
      </Switch>*/}
      <div />
    </CSSTransition>
  </TransitionGroup>
}

const preEnrollmentItems = [
  'Checklist',
  'Doctors & Drugs',
  'Plans',
  'Learn'
]

const postEnrollmentItems = [
  'Dashboard',
  'Benefits',
  'Doctors',
  'Drugs',
  'Plans',
  'Learn'
]

export class Routes {
  private static list = List.filter(i => preEnrollmentItems.includes(i.label));

  public static setHasEnrollment(value: boolean) {
    if (value) {
      Routes.list = List.filter(i => postEnrollmentItems.includes(i.label));
    }
  }

  public static removeBenefitsFromList() {
    Routes.list = Routes.list.filter(i => i.label !== 'Benefits');
  }

  public static getItems() {
    return Routes.list;
  }

  public static getRouteUrl(key: RouteKeys) {
    return (Routes.getRoutes()[key] as any).value;
  }

  public static getRoutes() {
    return List.map(item => item.routes)
        .reduce((previousValue, currentValue) => Object.assign(previousValue, currentValue), {});
  }

  public static getRouteIndex(route: string) {
    if (route === '/account-settings') {
      return 7;
    }
    /*const result = Routes.list.findIndex((item: any) => (
      Object.values(item.routes).some(r => r.value === '/' + route.split('/').splice(1)[0])
    ));
    return result < 0 ? 0 : result;*/
    return  0
  }
}
