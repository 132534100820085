import {pdpSortBy, setPdpSortBy} from "../../../entities";
import {PdpField} from "@coverright/data-access/types/medicare";
import React from "react";
import {useAppDispatch, useAppSelector} from "../../../shared/hooks";
import { SortBySelect } from "../../../features";

export default function () {
  const sortBy = useAppSelector(pdpSortBy);
  const dispatch = useAppDispatch();

  return <SortBySelect sortBy={sortBy}
                       onChange={v => dispatch(setPdpSortBy(v))}
                       options={[
                         {label: 'Lower Cost First', value: PdpField.MonthlyCost},
                         {label: 'By Name', value: PdpField.Name}
                       ]}
  />
}
