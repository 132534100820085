import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  api,
  DrugsWithPackageQuery,
  MedicareQuoteQuery,
  PlansFilterInput,
} from '../../../shared/api/medicare';
import { ListItem, transformListItem } from '../../../shared';
import { Field } from '@coverright/data-access/types/medicare';
import { GetPlansYear } from '@coverright/utils';
import { PersistentState } from '../lib/types';
import { fetchPersistentState } from '../api/fetch-persistent-state';
import { savePersistentState } from '../api/save-persistent-state';
import { toPreferredDrugOutput } from '@coverright/widgets';

export enum CustomAnswerType {
  prepay = 'prepay',
  networkFlexibility = 'networkFlexibility',
  extraBenefits = 'extraBenefits',
  receiveMedicaid = 'receiveMedicaid',
}
type CustomAnswer = {
  value: boolean;
  answerText: string;
  questionText: string;
};

type MaQuoteSlice = {
  quote?: MedicareQuoteQuery['medicareQuote'];
  customAnswers: Partial<{ [key in CustomAnswerType]: CustomAnswer }>;
  filters?: PlansFilterInput;
  companies: ListItem[];
  planTypes: ListItem[];
  extraBenefits: ListItem[];
  SNPTypes: ListItem[];
  sortBy: Field;
  drugs?: DrugsWithPackageQuery['drugsWithPackage'];
  persistent?: { state: PersistentState; [key: string]: any };
};

const initialState: MaQuoteSlice = {
  companies: [],
  planTypes: [],
  extraBenefits: [],
  SNPTypes: [],
  sortBy: Field.MonthlyCost,
  customAnswers: {},
};

const slice = createSlice({
  name: 'maQuote',
  initialState,
  reducers: {
    setPersistentStateValue(
      state,
      { payload }: PayloadAction<PersistentState>
    ) {
      if (!state.persistent?.state) {
        state.persistent = {
          quote: state.quote,
          quoteId: state.quote?.id,
          state: {},
        };
      }
      state.persistent.state = Object.assign(state.persistent.state, payload);
      savePersistentState(state.persistent);
    },
    resetCustomAnswers(state) {
      state.customAnswers = {};
    },
    setMaFilter(
      state,
      { payload }: PayloadAction<{ key: keyof PlansFilterInput; value: any }>
    ) {
      if (state.filters) {
        state.filters[payload.key] = payload.value;
      }
    },
    setMaSortBy(state, { payload }: PayloadAction<Field>) {
      state.sortBy = payload;
    },
    setCustomAnswer(
      state,
      {
        payload,
      }: PayloadAction<{ type: CustomAnswerType; answer: CustomAnswer }>
    ) {
      state.customAnswers[payload.type] = payload.answer;
    },
  },
  selectors: {
    persistentState(state: MaQuoteSlice) {
      return state.persistent?.state;
    },
    medicareQuote(state) {
      return state.quote;
    },
    customAnswers(state) {
      return state.customAnswers;
    },
    preferredDoctors(state) {
      return state.quote?.preferredDoctors || [];
    },
    preferredDrugs(state) {
      return state.quote?.preferredDrugs?.map(toPreferredDrugOutput) || [];
    },
    detailedDrugs(state) {
      return (
        state.quote?.preferredDrugs.map((d) => ({
          ...d,
          ...(state.drugs?.find((det) => det.packRxcui === d.packRxcui) || {}),
        })) || []
      );
    },
    preferredPharmacies(state) {
      return state.quote?.preferredPharmacies || [];
    },
    drugDeliveryType(state) {
      return state.quote?.drugDeliveryType;
    },
    maCompanies(state) {
      return state.companies;
    },
    maPlanTypes(state) {
      return state.planTypes;
    },
    maExtraBenefits(state) {
      return state.extraBenefits;
    },
    maSnpTypes(state) {
      return state.SNPTypes;
    },
    maSortBy(state) {
      return state.sortBy;
    },
    maFilters(state) {
      return state.filters;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPersistentState.fulfilled, (state, { payload }) => {
      state.persistent = payload;
    });
    builder.addMatcher(
      api.endpoints.medicareQuote.matchFulfilled,
      (state, { payload }) => {
        const quote = payload.medicareQuote;
        state.quote = quote;
        if (!Object.keys(state.customAnswers).length) {
          state.customAnswers = quote?.customAnswers
            ? JSON.parse(quote?.customAnswers)
            : {};
        }
        if (!state.filters && quote?.filters) {
          state.filters = {
            ...quote.filters,
            zip: quote.zip,
            countyName: quote.county!,
            planYear: quote.planYear || GetPlansYear(),
            extraBenefits: quote.filters.extraBenefits as any,
          };
        }
      }
    );
    builder.addMatcher(
      api.endpoints.drugsWithPackage.matchFulfilled,
      (state, { payload }) => {
        state.drugs = payload.drugsWithPackage;
      }
    );
    builder.addMatcher(
      api.endpoints.maPlanFilter.matchFulfilled,
      (state, { payload }) => {
        state.companies = transformListItem(
          payload.companies.filter((c) => c.count)
        );
        state.planTypes = transformListItem(payload.planTypes);
        state.extraBenefits = transformListItem(payload.extraBenefits);
        state.SNPTypes = transformListItem(payload.SNPTypes);
      }
    );
  },
});

export const maQuoteReducer = slice.reducer;

export const {
  maCompanies,
  maPlanTypes,
  maExtraBenefits,
  maSnpTypes,
  maFilters,
  maSortBy,
  medicareQuote,
  preferredDoctors,
  preferredDrugs,
  detailedDrugs,
  preferredPharmacies,
  drugDeliveryType,
  customAnswers,
  persistentState,
} = slice.selectors;

export const {
  setMaFilter,
  setMaSortBy,
  resetCustomAnswers,
  setCustomAnswer,
  setPersistentStateValue,
} = slice.actions;
