import Message from '../ui/message';
import { Collapse } from '@mui/material';
import * as React from 'react';
import { useDebouncedEffect } from '@coverright/utils';
import { useState } from 'react';

export default function(props: {isFirstVisit: boolean, plansLength: number, totalPlans: number, isExtraBenefitsSelected: boolean}) {
  const {plansLength, isExtraBenefitsSelected, isFirstVisit, totalPlans} = props;
  const [open, setOpen] = useState(false);

  useDebouncedEffect(() => {
    setOpen(!isFirstVisit && plansLength < 3 && isExtraBenefitsSelected);
  }, 500, [plansLength, isExtraBenefitsSelected, isFirstVisit]);

  return <Collapse in={open}>
    <Message title={`It looks like there aren’t ${plansLength ? 'many' : 'any'} plan matches based on your extra benefit selections.`}
             description={<>There are <b>{totalPlans} plans</b> in your area overall, however, there {plansLength ? (plansLength > 1 ? 'are' : `is`) + ` only ${plansLength}` : 'no'} plan{plansLength > 1 ? 's' : ' that'} matches based on the extra benefits you have selected. To see more plans remove any extra benefit that are not crucial.</>}
             onClose={() => setOpen(false)}
             data-test={'changeBenefitsOpen'}
    />
  </Collapse>
}

