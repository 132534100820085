import { Box, Button, Card, Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import {Check, X} from "@phosphor-icons/react";

interface ListCardProps {
  list: {title: string, subtitle?: string, value?: number | string, tickIcon?: boolean, valueLabel?: string}[],
  title: string,
  noDataLabel: string,
  onEditClick?: () => void,
  buttonId?: string,
}
export default function ListCard(props: ListCardProps) {

  return <Card sx={{p: 4, flex: 1, width: 1, overflow: 'hidden'}}>
    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: 48}}>
      <Typography variant={'h4'} className={'fs-24 lh-30'}>{props.title}</Typography>
      {props.onEditClick && <Button variant={'text'}
                                    size={'small'}
                                    onClick={props.onEditClick}
                                    id={props.buttonId}
                                    sx={{ fontWeight: 500, fontSize: 16, p: 1 }}>Edit</Button>}
    </Box>
    <Divider sx={{borderColor: '#B3B3B3', mb: 2, mt: .5}} />
    {!props.list.length && <Typography className={'semi-bold'}>{props.noDataLabel}</Typography>}
    {!!props.list.length && <Stack spacing={2}>
      {props.list.map((row, i) => (
        <Box key={row.title + i} sx={{ display: 'flex', gap: '30px', justifyContent: 'space-between', whiteSpace: 'nowrap' }}>
          <Box sx={{ display: 'flex', gap: '8px', maxWidth: 'calc(100% - 94px)' }}>
            {row.tickIcon !== undefined && <Box sx={{mt: .5}}>
              {row.tickIcon && <Check size={20} color="#219653" weight="bold"/>}
              {!row.tickIcon && <X size={20} color="#D32C1C" weight="bold"/>}
            </Box>}
            <Box sx={{overflow: 'hidden', maxWidth: 230}}>
              <Typography className={'semi-bold'} sx={{textOverflow: 'ellipsis', overflow: 'hidden'}}>{row.title}</Typography>
              {!!row.subtitle && <Typography className={'fs-14'} sx={{ color: '#666' }}>{row.subtitle}</Typography>}
            </Box>
          </Box>
          {!!row.value && <div>
            <Typography className={'semi-bold'} align={'right'}>{row.value}</Typography>
            {!!row.valueLabel && <Typography className={'fs-14'} sx={{ color: '#666' }} align={'right'}>{row.valueLabel}</Typography>}
          </div>}
        </Box>
      ))}
    </Stack>}
  </Card>
}
