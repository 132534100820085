import { MedigapPlanOutputWithDetails } from '@coverright/data-access/medigap';
import { Typography } from '@mui/material';
import { MedigapPlanName } from '@coverright/data-access/types/medigap';
import { CRTooltip } from '@coverright/ui/inputs';
import React from 'react';
import { Benefit } from '@coverright/ui/marketplaces';
import { colors } from '@coverright/ui/themes';

export const Benefits = ({ plan }: { plan?: MedigapPlanOutputWithDetails }) => {
  if (!plan) return null;

  return <div>
    <Typography className={'bold fs-14 lh-14'}>Plan benefits:</Typography>
    <div className={'mt-8'}>
      <CRTooltip placement={'top'} arrow
                 title={'This is the amount you must pay out of pocket under Original Medicare for your hospital (Part A) costs before your plan coverage begins.  This amount covers your first 60 days regardless of how long your hospital stay is.  Medicare refers to this as the Part A (hospital) deductible.'}>
        <div>
          <Benefit inactive={false}
                   label={<Typography sx={{
                     mt: '2px',
                     color: plan?.features.partADeductible ? '#029094' : '#DD3D3D'
                   }}
                                      className={'fs-12 medium ml-8 no-wrap lh-13 self-color'}>Short term hospital <span
                     className={'fs-11 regular text-primary'}>(Part A deductible)</span></Typography>}
                   value={!!plan?.features.partADeductible} />
        </div>
      </CRTooltip>
    </div>
    <div className={'mt-8'}>
      <CRTooltip placement={'top'} arrow
                 title={'This is the amount you must pay out of pocket for an extended hospital stay (>60 days)'}>
        <div>
          <Benefit inactive={false}
                   label={<Typography sx={{ mt: '2px', color: plan?.features.partACopay ? '#029094' : '#DD3D3D' }}
                                      className={'fs-12 medium ml-8 no-wrap lh-13 self-color'}>
                     Long term hospital <span className={'fs-11 regular text-primary'}>(Part A copay)</span>
                   </Typography>}
                   value={!!plan?.features.partACopay} />
        </div>
      </CRTooltip>
    </div>
    <div className={'mt-8'}>
      <CRTooltip placement={'top'} arrow
                 title={'The amount you must pay out of pocket for your medical (Part B) costs before your plan coverage begins.'}>
        <div>
          <Benefit inactive={false}
                   label={<Typography sx={{
                     mt: '2px',
                     color: plan?.features.partBDeductible ? '#029094' : '#DD3D3D'
                   }}
                                      className={'fs-12 medium ml-8 no-wrap lh-13 self-color'}>Medical deductible <span
                     className={'fs-11 regular text-primary'}>(Part B deductible)</span></Typography>}
                   value={!!plan?.features.partBDeductible} />
        </div>
      </CRTooltip>
    </div>
    <div className={'mt-8'}>
      <CRTooltip placement={'top'} arrow
                 title={'This refers to the amount a doctor can charge over the Medicare-approved amount.  Providers may charge up to 15% over the standard Medicare rates.'}>
        <div>
          <Benefit inactive={false}
                   label={<Typography sx={{
                     mt: '2px',
                     color: plan?.features.partBExcessCharges ? '#029094' : '#DD3D3D'
                   }}
                                      className={'fs-12 medium ml-8 no-wrap lh-13 self-color'}>Medical excess charges <span
                     className={'fs-11 regular text-primary'}>(Part B excess)</span></Typography>}
                   value={!!plan?.features.partBExcessCharges} />
        </div>
      </CRTooltip>
    </div>
    <div className={'mt-8'}>
      <CRTooltip placement={'top'} arrow
                 title={'Skilled nursing facilities are Medicare-approved facilities that provide short-term extended care services following a hospital stay, at a lower level of care than provided in a hospital.'}>
        <div>
          <Benefit inactive={false}
                   label={<Typography sx={{
                     mt: '2px',
                     color: plan?.features.skilledNursingFacility ? '#029094' : '#DD3D3D'
                   }}
                                      className={'fs-12 medium ml-8 no-wrap lh-13 self-color'}>Skilled nursing
                     facility</Typography>}
                   value={!!plan?.features.skilledNursingFacility} />
        </div>
      </CRTooltip>
    </div>
    <div className={'mt-8'}>
      <CRTooltip placement={'top'} arrow
                 title={'Some Medigap plans cover foreign travel emergency care if the emergency begins during the first 60 days of your trip, and if Medicare doesn\'t otherwise cover foreign emergency care.'}>
        <div>
          <Benefit inactive={false}
                   labelClass={`thin color-primary`}
                   label={<Typography sx={{
                     mt: '2px',
                     color: plan?.features.foreignTravelEmergency ? '#029094' : '#DD3D3D'
                   }}
                                      className={'fs-12 medium ml-8 no-wrap lh-13 self-color'}>Foreign travel
                     emergency</Typography>}
                   value={!!plan?.features.foreignTravelEmergency} />
        </div>
      </CRTooltip>
    </div>
  </div>;
}
