import { useEffect, useMemo, useState } from 'react';
import { Conversation } from "@twilio/conversations";

export default function useParticipantSid(chatIdentityId?: string, conversation?: Conversation) {
  const [participantSid, setParticipantSid] = useState<string>()

  useEffect(() => {
    if (chatIdentityId && conversation) {
      conversation.getParticipantByIdentity(chatIdentityId)
        .then(res => setParticipantSid(res?.sid))
    }
  }, [chatIdentityId, conversation]);


  return participantSid;
}
