import {Box, Card, Typography} from "@mui/material";
import React, { forwardRef } from 'react';

const OffersBanner = forwardRef((_, ref) => {
  return <Card sx={{
    height: {sm: 138},
    py: 2.5,
    px: 3,
    boxSizing: 'border-box',
    backgroundColor: 'white',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    backgroundImage: {md: 'url("/plan-finder/assets/img/goodrx-backgroung.png")'}
  }}>
    <Box ref={ref} sx={{display: 'flex', gap: 1, alignItems: 'center', mb: 1}}>
      <Typography className={'bold fs-40'}>Your Offers</Typography>
      <img src={'/plan-finder/assets/img/party.png'} width={40} height={40} />
    </Box>
    <Typography className={'medium fs-22 lh-26'}
                sx={{maxWidth: 400}}
    >Add your drugs, and view offers and discounts from our partners.</Typography>
  </Card>
})

export default OffersBanner;
