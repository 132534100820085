import { createTheme, Theme } from '@mui/material';

const PlanFinderTheme = (theme: Theme) => createTheme({
  ...theme,
  components: {
    ...theme.components,
    MuiAppBar: {
      styleOverrides: {
        root: {
          minHeight: 88,
          maxHeight: 88,
          boxShadow: 'none',
          backgroundColor: '#1C434F',
        }
      },
    },
    MuiRating: {
      styleOverrides: {
        icon: {
          minWidth: '24px'
        }
      }
    },
    MuiCard: {
      ...theme.components?.MuiCard,
      styleOverrides: {
        root: {
          boxShadow: 'none',
          padding: '32px',
          borderRadius: '16px',
          overflow: 'inherit'
        }
      }
    },
    MuiButton: {
      ...theme.components?.MuiButton,
      variants: [
        ...theme.components?.MuiButton?.variants || [],
        {
          props: { variant: 'rounded', size: 'small' },
          style: {
            textTransform: 'none',
            border: `1px solid #000`,
            color: '#fff',
            boxShadow: '0px 4px 4px 0px #00000040 inset',
            padding: '8px 16px',
            fontSize: 12,
            fontWeight: 600,
            borderRadius: '8px',
          },
        },
        {
          props: { variant: 'rounded', size: 'small', color: 'success' },
          style: {
            background: '#5ECB9E',
            '&:hover': {
              background: '#5ECB9E',
            }
          },
        },
        {
          props: { variant: 'contained', color: 'info' },
          style: {
            fontSize: 16,
            lineHeight: '24px',
            fontWeight: 600,
            borderRadius: '8px',
            color: '#1C434F',
            backgroundColor: 'white',
            textTransform: 'none',
            padding: '16px',
            boxShadow: 'none',
            border: '1px solid white',
            '&:hover': {
              backgroundColor: 'white',
              boxShadow: 'none',
              border: '1px solid #1C434F',
            },/*
            '&:active': {
              backgroundColor: '#33798F',
              boxShadow: 'none',
            },
            '&.Mui-disabled': {
              backgroundColor: '#B3B3B3',
              boxShadow: 'none',
              color: 'white',
            }*/
          },
        },
      ]
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          zIndex: 1
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: '24px'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 0,
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: 500,
          fontSize: 16
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          borderBottom: '8px solid #B3B3B3',
        },

        indicator: {
          height: '8px',
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 14,
          borderRadius: '3px',
          backgroundColor: 'rgba(30, 149, 160, 0.10)',
        },
        bar1Determinate: {
          backgroundColor: '#1E95A0',
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 400,
          fontFamily: [
            'Epilogue', 'Roboto', 'Helvetica Neue', 'sans-serif'
          ].join(','),
          [theme.breakpoints.down('md')]: {
            fontSize: 14,
            padding: '12px 6px',
            minWidth: 'auto',
          },
          fontSize: 16,
          lineHeight: '16px',
          color: '#575142',
          '&.Mui-selected': {
            fontWeight: 600,
          },
        },
      }
    }
  },
});

export default PlanFinderTheme;
