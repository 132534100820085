import { Typography } from '@mui/material';
import { CRTooltip } from '@coverright/ui/inputs';
import { tooltips } from '@coverright/utils';
import React from 'react';

export const Prices = ({ planKey, getPrices }: { planKey: string, getPrices: (planKey: string) => string }) => <>
  <Typography sx={{ my: 1 }} className={'bold'}>Monthly Premium</Typography>
  <Typography sx={{ mb: 1.2 }}
              className={'fs-28 bold'}>{getPrices(planKey)}</Typography>
  <CRTooltip title={tooltips.standardPartBPremium} arrow>
    <Typography sx={{ color: '#1C434F' }} className={'fs-14 lh-18'}>
      Does not include<br />
      <span className={'underline'}>standard Part B Premium</span>
    </Typography>
  </CRTooltip>
</>;
