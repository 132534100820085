import * as React from "react";
import { useEffect, useState } from 'react';
import { conversationHasNewMessages } from '../lib';
import {Client as ConversationsClient, Conversation} from "@twilio/conversations";
import { useCreateConversationMutation } from '../../../shared/api/chat';
import useChatIdentityId from '../api/use-chat-identity-id';
import useChatAccessToken from '../api/use-chat-access-token';
import useParticipantSid from '../api/use-participant-sid';

interface IChatContext {
  participantSid?: string,
  conversation?: Conversation,
  connectionStatus: ConnectionStatus,
  hasNewMessages: boolean,
}

export const ChatContext = React.createContext<IChatContext>({
  connectionStatus: 'default',
  hasNewMessages: false,
});

export const ChatContextProvider = (props: any) => {
  const chatIdentityId = useChatIdentityId();
  const token = useChatAccessToken(chatIdentityId);

  const [hasNewMessages, setHasNewMessages] = useState(false)

  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>('default');

  const [conversationsClient, setConversationsClient] = useState<ConversationsClient>()
  const [conversation, setConversation] = useState<Conversation>()
  const [createConversation] = useCreateConversationMutation();

  const participantSid = useParticipantSid(chatIdentityId, conversation);

  useEffect(() => {
    if (token) {
      setConversationsClient(new ConversationsClient(token));
    }
  }, [token]);

  const updateHasNewMessages = () => setHasNewMessages(conversationHasNewMessages(conversation))

  useEffect(() => {
    if (conversation) {
      conversation.on('messageAdded', updateHasNewMessages);
      conversation.on('updated', updateHasNewMessages)
      conversation.getMessages().then(updateHasNewMessages)
    }

    return () => {
      conversation?.off('messageAdded', updateHasNewMessages)
      conversation?.off('updated', updateHasNewMessages)
    }
  }, [conversation]);

  const forceGetConversations = async (attempt = 1) => {
    if (conversationsClient && !conversation) {
      try {
        const conversations = await conversationsClient.getSubscribedConversations().then(res => res.items);
        if (conversations.length) {
          setConversation(conversations[0]);
        } else {
          await createConversation();
          await forceGetConversations();
        }
      } catch (err) {
        if (attempt < 5) {
          console.warn("Couldn't fetch messages, retry", err);
          setTimeout(() => {
            forceGetConversations(attempt + 1);
          }, 5000);
        } else {
          console.error("Couldn't fetch messages", err);
        }
      }
    }
  }

  useEffect(() => {
    if (conversationsClient) {
      conversationsClient.on("connectionStateChanged", (state) => {
        switch (state) {
          case "connected":
            setConnectionStatus('success');
            setTimeout(forceGetConversations, 5000);
            break;
          case "denied":
            setConnectionStatus('error');
            break;
          default:
            setConnectionStatus('default');
        }
      });
      conversationsClient.on("conversationJoined", (conversation) => {
        setConversation(conversation);
      });
    }
  }, [conversationsClient]);

  return <ChatContext.Provider value={{participantSid, connectionStatus, conversation, hasNewMessages}}>
    {props.children}
  </ChatContext.Provider>
}

type ConnectionStatus = "default" | "error" | "success";
