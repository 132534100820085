import { Navigate, Route, Routes, useLocation } from 'react-router';
import * as React from 'react';
import QuizRouter from '../pages/quiz';
import DoctorsDrugs from '../pages/doctors-drugs';
import {ToDoListPage} from '../pages/to-do-list';
import Dashboard from '../pages/dashboard';
import PlanFinder from '../pages/plan-finder';
import PlanDetails from '../pages/ma-plan-details';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import LearningCenter from '../pages/learning-center';
import { AccountSettings } from '../pages/account-settings';
import MAComparison from '../pages/ma-comparison';
import MgComparison from '../pages/mg-comparison';
import Doctors from '../pages/doctors';
import Drugs from '../pages/drugs';
import Benefits from '../pages/benefits';
import { MADrugCost, PDPDrugCost } from '../pages/drug-cost';
import MAMarketplace from '../pages/plan-finder-ma';
import MGMarketplace from '../pages/plan-finder-mg';
import PDPMarketplace from '../pages/plan-finder-pdp/plan-finder-pdp';
import Chat from "../pages/chat";
import Video from "../pages/video/video";
import Quizzes from '../pages/quiz/quizzes';
import QuizResults from '../pages/quiz/quiz-results';
import Q1 from '../pages/quiz/q1';

type RouteItem = {
  value: string,
  component: React.FC<any>,
  fullWidth?: boolean
}

export const routes: {[key: string]: RouteItem} = {
  dashboard: {value: '/dashboard', component: Dashboard},
  toDoList: { value: '/to-do-list', component: ToDoListPage},
  doctorsDrugs: {value: '/doctors-drugs', component: DoctorsDrugs},
  benefits: {value: '/benefits', component: Benefits},
  doctors: {value: '/doctors', component: Doctors},
  drugs: {value: '/drugs', component: Drugs},

  plans: {value: '/plans', component: PlanFinder},
  planFinder: {value: '/plan-finder', component: PlanFinder},

  planFinderMA: {value: '/plan-finder-ma', component: MAMarketplace},
  planComparisonMA: {value: '/plan-comparison-ma', component: MAComparison},
  planDetails: {value: '/plan-finder-ma/:id', component: PlanDetails, fullWidth: true},
  drugCostPage: {value: '/drug-cost-breakdown', component: MADrugCost},
  drugCost: {value: '/drug-cost-breakdown/:id', component: MADrugCost},

  planFinderPDP: {value: '/plan-finder-pdp', component: PDPMarketplace},
  drugCostPDP: {value: '/pdp-drug-cost-breakdown/:id', component: PDPDrugCost, fullWidth: true},

  planFinderMG: {value: '/plan-finder-mg', component: MGMarketplace},
  planComparisonMG: {value: '/plan-comparison-mg', component: MgComparison},

  quizzes: {value: '/quiz', component: Quizzes},
  quizResults: {value: '/quiz/quiz-results', component: QuizResults},
  quiz: {value: '/quiz/:question', component: Q1},

  learn: {value: '/learn', component: LearningCenter},
  videos: {value: '/video', component: Video},
  video: {value: '/video/:id', component: Video},

  accountSettings: {value: '/account-settings', component: AccountSettings},

  chat: {value: '/chat', component: Chat},
}

export default function Router() {
  const location = useLocation();

  return <TransitionGroup>
    <CSSTransition
      key={location.key}
      classNames={'page'}
      timeout={300}
      onEnter={() => {
        document.body.scrollIntoView({ behavior: 'auto' });
      }}
      onExiting={() => {
        window.scrollTo({ top: 0, left: 0 })
      }}
    >
      <Routes>
        {Object.values(routes).map(o => {
          const Component = o.component;
          return (
            <Route
              key={o.value}
              path={o.value}
              element={<Component />}
            />
          )
        })}
        <Route path={'/'} element={<Navigate to={'/to-do-list'} />} />
        <Route path={'*'} element={<Navigate to={'/to-do-list'} />} />
      </Routes>
    </CSSTransition>
  </TransitionGroup>
}
