import {createSlice} from "@reduxjs/toolkit";
import {api, MaPlanListQuery} from '../../../shared/api/medicare';


type MaPlanSlice = {
  plans?: MaPlanListQuery['plans'],
  doctorsCheckBoxMessage?: string,
  drugsCheckBoxMessage?: string,
}

const initialState: MaPlanSlice = {

}

const slice = createSlice({
  name: 'maPlan',
  initialState,
  reducers: {

  },
  selectors: {
    doctorsCheckBoxMessage(state) {
      return state.doctorsCheckBoxMessage;
    },
    drugsCheckBoxMessage(state) {
      return state.drugsCheckBoxMessage;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.maPlanList.matchFulfilled,
      (state, {payload}) => {
        state.doctorsCheckBoxMessage = payload.plans.doctorsCheckBoxMessage || '';
        state.drugsCheckBoxMessage = payload.plans.drugsCheckBoxMessage || '';
      }
    )
  }
})

export const maPlanReducer = slice.reducer;

export const {
  doctorsCheckBoxMessage,
  drugsCheckBoxMessage,
} = slice.selectors;


