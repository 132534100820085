import { Box, Typography } from '@mui/material';
import { Check, Info, X } from '@phosphor-icons/react';
import React from 'react';
import { CRTooltip } from '@coverright/ui/inputs';

const Benefit = (props: {label: string, subtitle?: string, tooltip?: string | JSX.Element, value: boolean}) => <Box sx={{display: 'flex', alignItems: 'center', gap: 1, cursor: 'default'}}>
  <Box sx={{mt: '4px'}}>
    {props.value && <Check size={20} color="#0B741C" weight="regular" />}
    {!props.value && <X size={20} color="#D32C1C" weight="regular" />}
  </Box>
  <CRTooltip arrow title={props.tooltip || ''}>
    <div>
      <Typography className={'lh-18'} sx={{color: props.value ? "#0B741C" : "#D32C1C"}}>
        {props.label}
        {props.tooltip && <sup>&nbsp;i</sup>}
      </Typography>
      {props.subtitle && <Typography className={'fs-14 lh-18'} sx={{mt: .5, color: '#666' }}>
        {props.subtitle}
      </Typography>}
    </div>
  </CRTooltip>
</Box>

export default Benefit;
