import React from "react";
import {CRTooltip} from "@coverright/ui/inputs";
import {Box, styled, Typography} from "@mui/material";
import {Check} from "@phosphor-icons/react";

export const EnrollmentDate = (props: { tooltip: React.ReactNode, title: string, active?: boolean }) => {
  const background = props.active === undefined ? '#e1f8fd' : props.active ? '#F1FEF3' : '#fdf9e1';
  return <CRTooltip sx={{'& .MuiTooltip-tooltip': {maxWidth: 500}}} arrow title={props.tooltip || ''}>
    <Container sx={{background}}>
      <Box className={'w-16'}>
        {props.active && <Check size={16} color="#1C434F" weight="bold"/>}
      </Box>
      <Typography>{props.title}</Typography>
    </Container>
  </CRTooltip>
}

const Container = styled(Box)({
  display: 'flex',
  gap: 8,
  alignItems: 'center',
  padding: 8,
  borderRadius: '8px',
  width: 'fit-content'
})
