import React from 'react';
import { Box } from '@mui/material';
import * as _ from 'lodash';

const QuizProgress = React.memo((props: {total: number, filled: number}) => <Box sx={{display: 'flex', gap: 2, mb: 5, justifyContent: 'center'}}>
    {_.fill(Array(props.total), 1).map((v, i) => i < props.filled).map((v, i) => <Box key={i} sx={{width: 64, height: 8, borderRadius: '8px', backgroundColor: v ? '#1C434F' : '#D9D9D9'}} />)}
  </Box>
)

export default QuizProgress;
