import {createSlice} from "@reduxjs/toolkit";
import { api, PdpQuoteQuery } from '../../../shared/api/medicare';

type PdpQuoteSlice = {
  quote?: PdpQuoteQuery['pdpQuote']
}

const initialState: PdpQuoteSlice = {

}

const slice = createSlice({
  name: 'pdpQuote',
  initialState,
  reducers: {
  },
  selectors: {
    pdpQuote(state) {
      return state.quote;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.pdpQuote.matchFulfilled,
      (state, {payload}) => {
        state.quote = payload.pdpQuote;
      }
    )
  }
})

export const pdpQuoteReducer = slice.reducer;

export const {
  pdpQuote,
} = slice.selectors;

