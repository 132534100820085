import { CRModal } from '@coverright/ui/shared';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { X } from '@phosphor-icons/react';
import * as React from 'react';
import { useCalendlyModal } from '@coverright/features';

interface SelectPlanModalProps {
  open: boolean;
  onClose: () => void;
}

export default function SelectPlanModal(props: SelectPlanModalProps) {
  const openCalendly = useCalendlyModal();

  return (
    <CRModal
      BackdropProps={{
        sx: {
          background: 'rgba(0, 0, 0, 0.25)',
        },
      }}
      width={480}
      paperPadding={'32px'}
      open={props.open}
      onClose={props.onClose}
    >
      <>
        <Box sx={{ display: 'flex', width: 1, justifyContent: 'flex-end' }}>
          <IconButton size="small" edge="start" onClick={props.onClose}>
            <X size={32} color="#000" weight="fill" />
          </IconButton>
        </Box>

        <Typography
          className={'epilogue fs-32 lh-48 mt-16 semi-bold'}
          align={'center'}
        >
          Continue with your licensed agent
        </Typography>
        <Button
          fullWidth
          sx={{ my: 3 }}
          id={'select-plan-schedule-a-call-button'}
          variant={'contained'}
          color={'primary'}
          onClick={() => {
            openCalendly();
            props.onClose();
          }}
        >
          Schedule a Free Call
        </Button>
        <Typography className={'fs-16 lh-24 mb-24'} align={'center'}>
          For Free Medicare support and information dial:
          <br />
          <span className={'medium'}>888-969-7667</span> | TTY: 771
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
            p: 3,
            background: '#F0F7FA',
            borderRadius: '16px',
          }}
        >
          <img
            src={'/plan-finder/assets/img/emoji_events.svg'}
            width={24}
            height={24}
          />
          <Typography className={'fs-18 lh-24'}>
            <Box sx={{ color: '#19605D', fontWeight: 600 }} component={'span'}>
              4,598
            </Box>{' '}
            Happy CoverRight Customers
          </Typography>
        </Box>
      </>
    </CRModal>
  );
}
