import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Check, X } from '@phosphor-icons/react';
import { useNavigate } from 'react-router';

import QuizVideoModalCard from '../../components/quiz/quiz-video-modal-card';
import { TakeQuizButton } from '../../features';
import { MarketplaceType, setMarketplaceType, SideLayout, useAppDispatch } from '../../shared';
import useMedigapPremiumRanges from './api/use-medigap-premium-ranges';
import { routes } from '../../app/router-new';

export default function(props: any) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const medigapPremiumRanges = useMedigapPremiumRanges();

  const onSeePlanClick = (type: MarketplaceType) => {
    dispatch(setMarketplaceType(type));
    navigate(routes.planFinder.value)
  }

  return <SideLayout title={'Personalize your Medicare Options'}
                     slots={{
                       side: <QuizVideoModalCard />
                     }}
  >
    <Box sx={{background: '#ffffff', p: 4, borderRadius: '16px'}}>
      <Typography variant={'h4'} className={'fs-24 lh-40 mb-32'}>Medicare Plan Comparison</Typography>

      <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: 2, width: 1, mb: 3}}>
        <Box sx={{width: 1, display: 'flex', flexDirection: 'column', borderRadius: '16px', cursor: 'pointer'}} onClick={() => onSeePlanClick('PDP')}>
          <Box sx={{py: 2.5, background: '#D08B7E', borderRadius: '16px 16px 0px 0px'}}>
            <Typography sx={{fontSize: 24, lineHeight: '28px', fontWeight: 600, fontFamily: 'Epilogue', color: 'white'}} align={'center'}>Basic</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, p: 3, background: '#F8F6F2', borderRadius: '0px 0px 16px 16px'}}>
            <Box sx={{width: 1, minHeight: 87, display: 'flex', flexDirection: 'column', mb: 3, gap: 1.2}}>
              <Typography sx={{fontSize: 21, lineHeight: '21px', whiteSpace: 'nowrap', fontWeight: 700, fontFamily: 'Epilogue'}} align={'center'}>Original Medicare</Typography>
              <Typography sx={{fontSize: 18, lineHeight: '18px', whiteSpace: 'nowrap', fontFamily: 'Epilogue'}} align={'center'}>+ Prescription Drug Plan</Typography>
            </Box>

            <Stack sx={{flex: 1, mb: 3}} spacing={4}>
              <CheckTile value={true} title={'No networks'} description={'Any doctor that accepts Medicare'} />
              <CheckTile value={false} sup title={'No limit on out-of-pocket costs'} description={<>Higher expenses<sup>5</sup> and are responsible for all copays/coinsurances unless you have federal or state assistance</>} />
              <CheckTile value={false} title={'No additional benefits'} description={'Need to purchase dental, vision and other benefits separately'} />
            </Stack>
          </Box>
        </Box>

        <Box sx={{width: 1, display: 'flex', flexDirection: 'column', borderRadius: '16px', cursor: 'pointer'}} onClick={() => onSeePlanClick('MG')}>
          <Box sx={{py: 2.5, background: '#0B741C', borderRadius: '16px 16px 0px 0px'}}>
            <Typography sx={{fontSize: 24, lineHeight: '28px', fontWeight: 600, fontFamily: 'Epilogue', color: 'white'}} align={'center'}>Comprehensive</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, p: 3, background: '#F8F6F2', borderRadius: '0px 0px 16px 16px'}}>
            <Box sx={{width: 1, minHeight: 87, display: 'flex', flexDirection: 'column', mb: 3, gap: 1.2}}>
              <Typography sx={{fontSize: 21, lineHeight: '21px', whiteSpace: 'nowrap', fontWeight: 700, fontFamily: 'Epilogue'}} align={'center'}>Original Medicare</Typography>
              <Typography sx={{fontSize: 18, lineHeight: '18px', whiteSpace: 'nowrap', fontFamily: 'Epilogue'}} align={'center'}>+ Prescription Drug Plan<sup>3</sup></Typography>
              <Typography sx={{fontSize: 18, lineHeight: '18px', whiteSpace: 'nowrap', fontWeight: 700, fontFamily: 'Epilogue'}} align={'center'}>+ Medicare Supplement<sup>4</sup></Typography>
            </Box>
            <Stack sx={{flex: 1, mb: 3}} spacing={4}>
              <CheckTile value={true} title={'No networks'} description={'Any doctor that accepts Medicare'} />
              <CheckTile value={true} sup title={<>Limited out-of-pocket costs<sup>4</sup></>} description={'Your supplement plan helps pay for most of your out-of-pocket costs'} />
              <CheckTile value={false} title={'No additional benefits'} description={'Need to purchase dental, vision and other benefits separately'} />
              <CheckTile value={false} sup title={<>Higher Premiums<sup>6</sup></>} description={medigapPremiumRanges} />
            </Stack>
          </Box>
        </Box>

        <Box sx={{width: 1, display: 'flex', flexDirection: 'column', borderRadius: '16px', cursor: 'pointer'}} onClick={() => onSeePlanClick('MA')}>
          <Box sx={{py: 2.5, px: 1, background: '#1E95A0', borderRadius: '16px 16px 0px 0px'}}>
            <Typography sx={{fontSize: 24, lineHeight: '28px', fontWeight: 600, fontFamily: 'Epilogue', color: 'white'}} align={'center'}>Most Popular</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, p: 3, background: '#F8F6F2', borderRadius: '0px 0px 16px 16px'}}>
            <Box sx={{width: 1, minHeight: 87, display: 'flex', flexDirection: 'column', mb: 3, gap: 1.2}}>
              <Typography sx={{fontSize: 21, lineHeight: '21px', whiteSpace: 'nowrap', fontWeight: 700, fontFamily: 'Epilogue'}} align={'center'}>Medicare Advantage</Typography>
              <Typography sx={{fontSize: 18, lineHeight: '18px', whiteSpace: 'nowrap', fontFamily: 'Epilogue'}} align={'center'}>or ‘Part C’</Typography>
            </Box>
            <Stack sx={{flex: 1, mb: 5}} spacing={4}>
              <CheckTile value={false} title={'Doctor networks'} description={<>Need to use in-network providers to achieve lowest cost share<sup>7</sup></>} />
              <CheckTile value={false} title={'Pay-As-You-Go'} description={'Pay copays/coinsurances until maximum out-of-pocket'} />
              <CheckTile value={true} title={'Additional benefits'} description={<>Drug coverage, dental, vision and hearing typically included<sup>9</sup></>} />
              <CheckTile value={true} title={'Lower Premiums'} description={<>66% of plans have $0 premium<sup>8</sup></>} />
            </Stack>
          </Box>
        </Box>
      </Box>

      <Box sx={{display: 'flex', width: 1, mt: 2, gap: 2, justifyContent: 'flex-end'}}>
        <Button variant={'contained'}
                id={'quiz-start-quiz-button'}
                sx={{px: 2, fontWeight: 500}}
                onClick={() => navigate('/quiz/q1')}>Start Quiz</Button>
        <Button id={'quiz-see-all-plans-button'} variant={'outlined'} color={'primary'} onClick={() => onSeePlanClick('MA')}>Skip and View Plans</Button>
      </Box>

    </Box>

    <Box sx={{background: '#ffffff', p: 4, borderRadius: '16px'}}>
      <Typography sx={{fontSize: 14, lineHeight: '24px', color: '#666'}}>
        1. These plan combinations reflect the more common combinations of coverage for Medicare beneficiaries that do not have any other retiree health insurance coverage such as employer-sponsored group health insurance program (including Federal Employee Retirement Health Benefits (FEHB)), VA, CHAMPVA or TRICARE coverage<br />
        2. Based on research by the Kaiser Family Foundation which found that the 48% of all Medicare beneficiaries are enrolled in a Medicare Advantage plan relative to 25% of all Medicare beneficiaries on traditional Fee-For-Service Medicare with a Medicare Supplement (based on data from AHIP Center for Policy and Research)<br />
        3. Medicare requires beneficiaries to have ‘creditable’ Part D prescription drug coverage in order to avoid late enrollment penalties. A standalone Part D prescription drug plan is required to avoid penalties if you do not have any other ‘creditable’ coverage such drug coverage from a current or former employer or union, VA, CHAMPVA or TRICARE coverage, or individual health insurance coverage<br />
        4. Assumes you purchase one of the most popular types of Medicare Supplement plans (Plan F, G or N) which represent 83% of all Medicare Supplement enrollments based on data from the AHIP Center for Policy and Research. If you live in Massachusetts, Minnesota or Wisconsin, Medicare Supplement policies are standardized in a different way to other states<br />
        5. Independent research conducted by the Better Medicare Alliance identified that beneficiaries on Original Medicare report higher out of-pocket spending than those on Medicare Advantage (almost $1,598 higher on average)<br />
        6. Ranges based on Medicare Supplement price indexes managed by the American Association for Medicare Supplement Insurance on Medicare Supplement pricing across the country<br />
        7. In many cases, you’ll need to only use doctors and other providers who are in the plan’s network (for non-emergency care). Some plans offer non-emergency coverage out of network, but typically at a higher cost.<br />
        8. All Medicare Advantage plans have a Maximum Out of Pocket Limit (MOOP). You continue to pay copays and coinsurances during a calendar year until you hit the MOOP. In 2023, the MOOP limit for all Medicare Advantage plans for in-networks services.<br />
        9. Based on research by the Kaiser Family Foundation which found that 66% of Medicare Advantage plans have no premium<br />
        10. Based on research by the Kaiser Family Foundation which found that 89% of Medicare Advantage plans include Part D prescription drug coverage and 97% or more individual plans offer some vision, fitness, telehealth, hearing or dental benefits     <br />
      </Typography>
    </Box>

  </SideLayout>
}

const CheckTile = (props: {value: boolean, title: JSX.Element | string, description: JSX.Element | string, sup?: boolean}) => <Box sx={{display: 'flex', gap: 1}}>
  <Box sx={{minWidth: 24}}>
    {!!props.value && <Check weight={'bold'} size={24} color={'#0B741C'} />}
    {!props.value && <X weight={'bold'} size={24} color={'#D32C1C'} />}
  </Box>
  <div>
    <Typography sx={{fontSize: 18, lineHeight: '24px', fontWeight: 600, mb: '4px', mt: props.sup ? '-5px': undefined}}>{props.title}</Typography>
    <Typography sx={{fontSize: 16, lineHeight: '24px', color: '#666666'}}>{props.description}</Typography>
  </div>
</Box>

