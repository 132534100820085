import Message from '../ui/message';
import { Button, Collapse } from '@mui/material';
import * as React from 'react';
import { useDebouncedEffect } from '@coverright/utils';
import { useState } from 'react';
import { CRTooltip } from '@coverright/ui/inputs';


interface Props {
  isTherePlanWithFullDrugCoverage: boolean,
  preferredDrugsLength: number,
}
export default function(props: Props) {
  const {preferredDrugsLength, isTherePlanWithFullDrugCoverage} = props;
  const [open, setOpen] = useState(false);

  useDebouncedEffect(() => {
    setOpen(!!preferredDrugsLength && !isTherePlanWithFullDrugCoverage);
  }, 500, [isTherePlanWithFullDrugCoverage, preferredDrugsLength]);

  return <Collapse in={open}>
    <Message title={'No plan in your area covers all your drugs'}
             description={<>There are no plans in your area that cover all the prescription drugs you have entered. Please reach out to us. Your dedicated advisor will discuss your situation and help you navigate next steps.</>}
             onClose={() => setOpen(false)}
             data-test={'notAllDrugsOpen'}
    />
  </Collapse>
}

