import { ComparisonPlan, removePlanFromComparison } from '../../../features';
import { useAppDispatch } from '../../../shared/hooks';

export default function useClearComparisonPlans(list: ComparisonPlan[]) {
  const dispatch = useAppDispatch()

  return (data: any) => {
    Object.values(data).forEach((p, i) => {
      if (!p) {
        dispatch(removePlanFromComparison({type: 'MA', plan: list[i]}))
      }
    })
  }
}
