import { Box, Card, Typography } from '@mui/material';
import React from 'react';
import {useAppSelector} from "../../shared";
import { medigapQuote } from '../../entities';
import {profile as profileSelector} from "../../entities/profile";
import usePlanList from './api/use-plan-list';
import UpdateZip from './ui/update-zip';
import { V3Preloader } from '@coverright/ui/shared';
import { Footer } from '@coverright/ui/marketplaces';
import { FilterBadges, Paginator, PlansCountRepresentDisclamer, usePageSize } from '../../features';
import useFilterBadges from './lib/use-filter-badges';
import * as _ from 'lodash';
import { PlanTable } from './ui/plan-table';

export function MgPlanList() {
  const quote = useAppSelector(medigapQuote);
  const profile = useAppSelector(profileSelector);
  const {plans, total, isLoading, isSuccess} = usePlanList();
  const badges = useFilterBadges();
  const [page, setPage] = React.useState(0);
  const pageSize = usePageSize();

  const totalColumns = Object.keys(plans).length

  return <>
    <Card sx={{ p: 4 }} id={'mg-info'}>
      <UpdateZip loading={isLoading} plansTotal={total}/>
      <PlansCountRepresentDisclamer zip={quote?.medigapFilterState.zip}
                                    county={quote?.medigapFilterState.county}
                                    state={profile?.state} type={'MA'}/>
      <FilterBadges badges={badges}/>
    </Card>

    <Box sx={{p: 0, flex: 1, display: 'flex', flexDirection: 'column', gap: 3}}>
      {(!!total && isSuccess) && <>
        <Card sx={{p: '16px 0'}}>
          <Paginator onChange={setPage}
                     page={page}
                     pageSize={pageSize}
                     loading={isLoading}
                     total={totalColumns} />
        </Card>
        <PlanTable plans={_.pick(plans, Object.keys(plans).slice(page * pageSize, page * pageSize + pageSize))} />
        {(!isLoading && !!totalColumns) && <Card sx={{p: '16px 0'}}>
          <Paginator onChange={setPage}
                     page={page}
                     pageSize={pageSize}
                     loading={isLoading}
                     total={totalColumns}
                     scrollTo={document.getElementById("mg-info")}
          />
        </Card>}
      </>}
      {isLoading && <V3Preloader sx={{mt: 4, mb: 6}} />}
      <Footer sx={{maxWidth: 'initial'}} zip={quote?.medigapFilterState.zip}>
        <Typography className={'fs-12'}>
          PLEASE NOTE: Medicare Supplement insurance is available to those age 65 and older enrolled in Medicare Parts A and B and, in some states, to those under age 65 eligible for Medicare due to disability or End-Stage Renal disease.
        </Typography>
        <Typography className={'fs-12'}>
          The purpose of this communication is the solicitation of insurance.
        </Typography>
        <Typography className={'fs-12'}>
          Contact will be made by an insurance agent/producer or insurance company. Medicare Supplement insurance plans are not connected with or endorsed by the U.S. government or the federal Medicare program.
        </Typography>
      </Footer>
    </Box>
  </>
}


