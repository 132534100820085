import {Box, FormControlLabel, Switch, Typography} from "@mui/material";
import {PlanYear} from "@coverright/data-access/types/medicare";
import React from "react";
import {UserTestContext} from "@coverright/shared/contexts";
import { SxProps } from '@mui/material';

interface Props {
  checked: boolean,
  onChange: (value: PlanYear) => void,
  sx?: SxProps,
}

export function YearToYearSwitch({checked, onChange, sx}: Props) {
  const {config} = React.useContext(UserTestContext);

  if (!config.compare_year_to_year) {
    return null
  }

  return <Box sx={{display: 'flex', alignItems: 'center', ...sx}}>
    <Typography className={'mr-12'}>2023</Typography>
    <FormControlLabel control={<Switch checked={checked}
                                       onChange={e => onChange(e.target.checked ? PlanYear.Year2024 : PlanYear.Year2023)}  />}
                      label={'2024'} />
  </Box>
}
