import React from 'react';
import { Box, Typography } from '@mui/material';
import { PharmaciesSelection } from '@coverright/features';
import { V3InfoPanel } from '@coverright/ui/shared';
import {
  DrugDeliveryTypeDto,
  PharmacyLocationDistanceOutput,
} from '@coverright/data-access/types/medicare';
import { useLogEvent } from '@coverright/shared/analytics';
import { useAppSelector } from '../shared';
import { medicareQuote } from '../entities';

type DoctorProps = {
  value: PharmacyLocationDistanceOutput[];
  orderType?: DrugDeliveryTypeDto | null;
  onChange: (
    pharmacy: PharmacyLocationDistanceOutput[],
    orderType: DrugDeliveryTypeDto
  ) => void;
};

const PharmacyTab = (props: DoctorProps) => {
  const quote = useAppSelector(medicareQuote);
  const logEvent = useLogEvent();

  if (!quote) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, p: 1 }}>
      <Box>
        <Typography variant={'h3'} align={'center'}>
          Add your pharmacy.
        </Typography>
        <Typography
          className={'fs-21 lh-32'}
          sx={{ color: '#333333' }}
          align={'center'}
        >
          This will help find the best price for your drugs.
        </Typography>
      </Box>

      <PharmaciesSelection
        zip={quote.zip}
        value={props.value}
        onChange={(v) => {
          logEvent('Add pharmacy', {
            pharmacy: v,
          });
          if (props.orderType) {
            props.onChange(v, props.orderType);
          }
        }}
        orderType={props.orderType || undefined}
        onOrderTypeChange={(v) => {
          logEvent('Order type change', {
            orderType: v,
          });
          if (v) {
            props.onChange(props.value, v);
          }
        }}
      />

      <V3InfoPanel
        title={'Why do we need this?'}
        text={
          'Choosing a pharmacy helps us more accurately estimate drug costs. Well find your drug cost-shares depending on your pharmacy type selection.'
        }
      />
    </Box>
  );
};

export { PharmacyTab };
