import * as React from 'react';
import { getSelectedMgCategoriesByPlans, isAllMgCategoriesSelected, useMgFilters } from '../../../features';
import * as _ from 'lodash';

export default function useFilterBadges(): { label: string, onDelete: () => void }[] {
  const { filters, buildHandler } = useMgFilters();

  return React.useMemo(() => {
    let result: any[] = [];

    if (!isAllMgCategoriesSelected(filters?.planNames)) {
      const categories = getSelectedMgCategoriesByPlans(filters?.planNames);

      result = categories.map(v => ({
        label: `${v.name} (${v.plans.join(', ')})`,
        onDelete: () => buildHandler('planNames')(filters?.planNames?.filter(c => !v.plans.includes(c)) || [])
      }))
    }

    if (filters?.companies) {
      result = result.concat(filters?.companies?.map(label => ({
        label,
        onDelete: () => buildHandler('companies')(_.without(filters?.companies, label) || [])
      })))
    }

    return result;

  }, [filters]);
}
