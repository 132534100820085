import {Box, Stack, styled, Typography} from '@mui/material';
import React from 'react';
import {CaretRight, CheckCircle} from '@phosphor-icons/react';
import {ToDoListItem} from '../../../shared';

export default function ToDoList({items}: { items: ToDoListItem[] }) {
  return <Stack>
    {items.map((item, i) => (
      <ListItem label={item.label}
                completedButtonLabel={item.completedButtonLabel}
                buttonLabel={item.buttonLabel}
                onClick={item.onClick}
                key={item.label + i}
                completed={item.completed}/>
    ))}
  </Stack>
}

const ListItem = (props: ToDoListItem) => {
  return <Container id={props.id}
                    sx={{borderBottomColor: props.completed ? "#0B741C" : "#B3B3B3"}}
                    onClick={props.onClick}>
    <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
      <CheckCircle size={24} color={props.completed ? "#0B741C" : "#B3B3B3"} weight="fill" className={'w-24'}/>
      <Typography variant={'body2'}
                  sx={{fontWeight: 500, color: props.completed ? '#0B741C' : undefined}}>{props.label}</Typography>
    </Box>
    {!props.completed && <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
      <Typography variant={'body2'} sx={{color: '#1C434F'}} className={'semi-bold'}>{props.buttonLabel}</Typography>
      <CaretRight size={24} color="#1C434F" weight="bold"/>
    </Box>}
    {props.completed && <Typography variant={'body2'} sx={{color: '#0B741C'}}
                                      className={'semi-bold'}>{props.completedButtonLabel || 'View'}</Typography>}
  </Container>
}

const Container = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 8,
  width: '100%',
  paddingBottom: 16,
  paddingTop: 16,
  cursor: 'pointer',
  borderBottom: '1px dotted'
})
