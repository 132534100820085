import { Box, Divider as MuiDivider } from '@mui/material';
import React, { useState } from 'react';
import * as _ from 'lodash';
import { SxProps } from '@mui/material';
import { PlansDictionary } from '../../lib/types';
import { Header } from './header';
import { Prices } from './prices';
import { Copays } from './copays';
import { Deductibles } from './deductibles';
import { Benefits } from './benefits';
import { PlanCard } from './plan-card';
import { SeeMore } from '@coverright/ui/marketplaces';
import { showRange } from '@coverright/utils';
import {CompareToggleButton} from "../../../../features";
import {getPlanName} from "../../lib/lib";

export function PlanTable({plans}: {plans: PlansDictionary}) {
  const [expanded, setExpanded] = useState<{ [key: keyof PlansDictionary]: boolean }>({});
  const [showAllPlans, setShowAllPlans] = useState<{ [key: keyof PlansDictionary]: boolean }>({});
  const toColumns = convertToColumns(Object.keys(plans));
  const planOptionsMax= _.max(Object.keys(plans).map(key => plans[key].length)) || 0;
  const columnsCount = Object.keys(plans).length;

  return <>
    <Box component={'table'} sx={{
      ...tableStyles,
      '& td:nth-of-type(odd)': {
        background: '#fff',
        padding: '0 16px',
        '& p:not(.self-color)': {color: '#1C434F'},
        verticalAlign: 'top',
        width: {xs: '100%', sm: 100 / columnsCount - 1 + '%'}
      },
      width: {xs: '100%', sm: 50 * columnsCount + '%', md: (33 * columnsCount + 1) + '%'}
    }}
         cellSpacing={0}
         cellPadding={0}>
      <tbody>
      <tr>
        {toColumns(planKey => (
          <Header planKey={planKey}/>
        ))}
      </tr>
      <tr>
        {toColumns(planKey => (
          <Prices planKey={planKey} getPrices={calcPrices(plans)}/>
        ))}
      </tr>
      <tr>
        {toColumns(planKey => (
          <Divider />
        ))}
      </tr>
      <tr>
        {toColumns(planKey => (
          <Copays plan={plans[planKey][0]}/>
        ))}
      </tr>
      <tr>
        {toColumns(planKey => (
          <Deductibles plan={plans[planKey][0]}/>
        ))}
      </tr>
      <tr>
        {toColumns(planKey => (
          <Divider/>
        ))}
      </tr>
      <tr>
        {toColumns(planKey => (
          <Benefits plan={plans[planKey][0]}/>
        ))}
      </tr>

      <tr>
        {toColumns(planKey => (
          <Box sx={{mt: 2}}>
            <CompareToggleButton type={'MG'} plan={{name: getPlanName(planKey), id: planKey}}/>
          </Box>
        ))}
      </tr>

      <tr>
        {toColumns(planKey => (
          <SeeMore expanded={!expanded[planKey]}
                   sx={{justifyContent: 'center', mt: 1, '& p': {fontSize: 12, fontWeight: 500}}}
                   getTitle={exp => `${exp ? 'Hide' : 'Show'} plan options`}
                   onToggle={() => setExpanded(prev => ({...prev, [planKey]: !prev[planKey]}))}/>
        ))}
      </tr>

      {_.fill(Array(planOptionsMax), 1).map((v, i) => <tr key={'plan_row_' + i}>
        {toColumns(planKey => (
          // Show plan if it exists and plans on this type should be expanded and if see more not clicked show only 2 plans
          !expanded[planKey] && (i < 2 || showAllPlans[planKey]) && plans[planKey][i] ? <>
            <Divider/>
            <PlanCard plan={plans[planKey][i]}/>
            {/*Show 'See more options' if there is more than 2 plans of current type and if not expanded then after second plan otherwise after last plan*/}
            {plans[planKey].length > 2 && (i === 1 && !showAllPlans[planKey] || plans[planKey].length - 1 === i) && (
              <SeeMore expanded={showAllPlans[planKey]}
                       sx={{justifyContent: 'center', mt: 3, '& p': {fontSize: 12, fontWeight: 500}}}
                       getTitle={exp => `${exp ? 'Hide' : 'See'} more options`}
                       onToggle={() => setShowAllPlans(prev => ({...prev, [planKey]: !prev[planKey]}))}/>
            )}
          </> : null
        ))}
      </tr>)}

      </tbody>
    </Box>
  </>
}

const calcPrices = (plans: PlansDictionary) => (planKey: string) => {
  const prices = plans[planKey].map(p => parseFloat(p.monthlyPremium?.replace('$', '') as string));
  return showRange(`$${_.min(prices)?.toFixed(0)}`, `$${_.max(prices)?.toFixed(0)}`);
};


const Divider = () => <MuiDivider sx={{ my: 2, borderColor: '#E8ECED' }} />;

const convertToColumns = (arr: string[]) => (contentFunc: (key: string) => React.JSX.Element | null) => (
  arr.map((key, i, array) => <React.Fragment key={key}>
    <td>
      {contentFunc(key)}
    </td>
      {i < array.length - 1 && <td></td>}
    </React.Fragment>
  )
);


const tableStyles: SxProps = {
  tableLayout: 'fixed',
  '& tbody tr:first-of-type td': {
    paddingTop: '20px',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    height: '1px',
  },
  '& tbody tr:last-of-type td': {
    paddingBottom: '20px',
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
}


