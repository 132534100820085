import { Sort } from '@coverright/data-access/types/medicare';
import { useDebouncedEffect } from '@coverright/utils';
import { useSnackbar } from 'notistack';
import { useAppSelector } from '../../../shared';
import { medigapQuote, mgFilters, mgSortBy } from '../../../entities';
import { useIsMAState, useIsWIState } from '@coverright/data-access/medicare';
import { useLazyMgPlanListQuery } from '../../../shared/api/medigap';
import { useMemo, useState } from 'react';
import { addDetailsToMGPlanList } from '@coverright/data-access/medigap';
import * as _ from 'lodash';
import { MedigapPlanName, MedigapPlansField } from '@coverright/data-access/types/medigap';
import { PlansDictionary } from '../lib/types';

export default function usePlanList() {
  const quote = useAppSelector(medigapQuote);
  const sortBy = useAppSelector(mgSortBy);
  const filters = useAppSelector(mgFilters);
  const isMassachusetts = useIsMAState(quote?.medigapFilterState.zip);
  const isWisconsin = useIsWIState(quote?.medigapFilterState.zip);
  const [plans, setPlans] = useState<PlansDictionary>({});

  const [getPlans, { data, isFetching, isUninitialized , isSuccess}] = useLazyMgPlanListQuery();
  const { enqueueSnackbar } = useSnackbar();

  const loadPlans = async () =>  {
    if (filters && quote?.medigapFilterState) {
      try {
        const {planCategory, id, ...quoteFilters} = quote.medigapFilterState
        const response = await getPlans({
          filter: {
            ...quoteFilters,
            companies: filters.companies || [],
            monthlyPlanPremiumRanges: [],
            planNames: isMassachusetts || isWisconsin ?
                [] :
                filters.planNames || [],
          },
          page: {
            page: 0,
            size: 1000
          },
          sort: [{
            direction: Sort.Asc,
            field: sortBy
          }]
        });

        if (response.data?.medigapPlans.data) {
          const data = response.data.medigapPlans.data;

          const detailed = addDetailsToMGPlanList(data);

          if (data) {
            const result = _.groupBy(detailed, 'planName');
            let order: string[] = [];
            if (sortBy === MedigapPlansField.PlanName) {
              order = Object.keys(result).sort();
            } else {
              order = _.orderBy(Object.keys(result), key => _.min(result[key].map(p => parseFloat(p.monthlyPremium?.replace('$', '') as string))));
            }
            return _.fromPairs(order.map(o => [o, result[o]]));
          }
          return {} as PlansDictionary;
        } else {
          throw Error('Mg plan list data is wrong');
        }
      } catch (e) {
        console.error(e);
        enqueueSnackbar('Error loading plans', {variant: 'error'});
      }

    } else {
      console.warn('No filters in Mg list')
    }
    return {};
  }

  useDebouncedEffect(() => {
    loadPlans().then(setPlans)
  }, 600, [filters, sortBy])

  const total = useMemo(() => {
    return +data?.medigapPlans.totalElements || 0
  }, [data]);

  return {plans, total, isLoading: isFetching || isUninitialized, isSuccess}
}

