import React from 'react';
import SelectPlanModal from './ui/select-plan-modal';
import { Button } from '@mui/material';
import { ButtonTypeMap } from '@mui/material/Button/Button';


export const SelectPlanButton = (props: ButtonTypeMap<{id?: string, className?: string}>['props']) => {
  const [selectModalOpen, setSelectModalOpen] = React.useState(false);

  return <>
    <SelectPlanModal open={selectModalOpen} onClose={() => setSelectModalOpen(false)}/>
    <Button onClick={() => setSelectModalOpen(true)}
            fullWidth
            variant={'contained'}
            {...props}
    >{props.children}</Button>
  </>
}
