import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import { ComparisonPlan, maComparisonPlans, removePlanFromComparison } from '../model/slice';
import ComparisonPlanList from './comparison-plan-list';
import { useNavigate } from 'react-router';
import { routes } from '../../../app/router-new';

export function MaComparisonCard() {
  const plans = useAppSelector(maComparisonPlans);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const removeFromCompare = (plan: ComparisonPlan) => (
    dispatch(removePlanFromComparison({type: 'MA', plan}))
  )

  if (!plans.length) {
    return null
  }

  return <ComparisonPlanList onCompareClick={() => navigate(routes.planComparisonMA.value)}
                             onRemoveClick={removeFromCompare}
                             plans={plans} />
}
