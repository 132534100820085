import { Button } from '@mui/material';
import React from 'react';

export function LoadMorePlansButton({onClick, disabled, id}: {id: string, disabled: boolean, onClick: () => void}) {
  return <Button id={id}
                 sx={{minWidth: 250}}
                 variant={'outlined'}
                 disabled={disabled}
                 onClick={onClick}>
    Load More Plans
  </Button>
}
