import React from 'react';
import { useAppSelector } from '../../../shared/hooks';
import { maSnpTypes } from '../../../entities';
import { FilterProps, ListItem } from '../../../shared';
import { useDsnpOffConfirm, useDsnpOnConfirm, PlanFilter } from '../../../features';

export function SnpTypesFilter({ value, planTypes, onChange }: FilterProps & {planTypes: ListItem[]}) {
  const dsnpOnConfirm = useDsnpOnConfirm();
  const dsnpOffConfirm = useDsnpOffConfirm();

  const handleChange = (value: string[]) => {
    if (value.length) {
      dsnpOnConfirm().then(val => val && onChange(value))
    } else {
      dsnpOffConfirm().then(val => val && onChange(value))
    }
  }

  return <PlanFilter value={value}
                     onChange={handleChange}
                     label={'Special Needs'}
                     data-test={'special-needs-filter'}
                     options={planTypes.map(t => (
                       {...t, checked: value?.includes(t.value)}
                     ))}
                     multiple
  />
}

export default function (props: FilterProps) {
  const snpTypes = useAppSelector(maSnpTypes);
  return <SnpTypesFilter {...props} planTypes={snpTypes} />
}
