import { useAppSelector } from '../../../shared';
import { profile as profileSelector } from '../../../entities/profile';
import { useCreateMedigapQuoteFromMedicareQuoteMutation, useLazyMedigapQuoteQuery } from '../../../shared/api/medigap';
import { useDebouncedEffect } from '@coverright/utils';

export default function useCreateMgQuoteIfNotExists() {
  const profile = useAppSelector(profileSelector);
  const [createQuote, {isLoading}] = useCreateMedigapQuoteFromMedicareQuoteMutation();
  const [getMgQuote] = useLazyMedigapQuoteQuery();

  const callback = (quoteId: string) => {
    if (!isLoading) {
      createQuote({
        medicareQuoteId: quoteId,
      }).unwrap().then(res => (
        setTimeout(() => getMgQuote({id: res.createMedigapQuoteFromMedicareQuote.mgQuoteId}), 1000)
      ))
    }
  }

  useDebouncedEffect(() => {
    if (profile?.quoteId && !profile?.medigapQuoteId) {
      callback(profile.quoteId)
    }
  }, 300, [profile]);
}
