import React from 'react';
import { UpdateZip } from '@coverright/widgets';
import { useAppSelector } from '../../../shared';
import { medigapQuote } from '../../../entities';
import { useSaveMedigapQuoteMutation } from '../../../shared/api/medigap';

interface Props {
  loading: boolean;
  plansTotal?: number;
}
export default function ({ loading, plansTotal }: Props) {
  const quote = useAppSelector(medigapQuote);
  const [saveQuote] = useSaveMedigapQuoteMutation();

  const onChange = (zip: string, county: string) => {
    if (quote) {
      saveQuote({
        input: {
          medigapFilterState: {
            ...quote.medigapFilterState,
            zip,
            county,
          },
          cId: quote.cId,
          id: quote.id,
        },
      });
    }
  };

  return (
    <UpdateZip
      loading={loading}
      zip={quote?.medigapFilterState.zip}
      county={quote?.medigapFilterState.county}
      onChange={onChange}
      plansTotal={plansTotal}
    />
  );
}
