import moment from "moment";
import {daysTillStartAEP, getAEPStartDate} from "../../../../../utils";
import CountdownCard from "./countdown-card";
import React from "react";
import AOECard from "./aoe-card";
import ChatCard from "./chat-card";

export default function SideCta({hasMaPlan, hasPdpPlan}: {hasMaPlan: boolean, hasPdpPlan: boolean}) {

  return <>
    <ChatCard />
    {((hasMaPlan || hasPdpPlan) && moment().isBefore(getAEPStartDate())) && (
      <CountdownCard daysBeforeAEP={daysTillStartAEP()}
                     renewalDate={moment().add({year: 1}).startOf('year').format('MM/DD/YY')}/>
    )}
    {moment().isSameOrAfter(getAEPStartDate()) && <AOECard />}

  </>
}
