import ListCard from './list-card';
import { useNavigate } from 'react-router';
import {routes} from "../../../../app/router-new";
import {useAppSelector} from "../../../../shared";
import {preferredDoctors} from "../../../../entities";
import {useMemo} from "react";
import usePlan from "../../api/use-plan";

export default function DoctorsListCard() {
  const quoteDoctors = useAppSelector(preferredDoctors);
  const {plan} = usePlan();

  const navigate = useNavigate();

  const doctors = useMemo(() => {
    return (quoteDoctors || []).map(d => ({
      title: d.name,
      subtitle: d.addresses[0]?.specialty,
      tickIcon: !!plan && 'doctorsCoverage' in plan ? plan?.doctorsCoverage.find(dc => dc.npi === d.npi)?.coverage || undefined : undefined,
    }))
  }, [quoteDoctors, plan]);

  return <ListCard list={doctors}
                   onEditClick={() => navigate(routes.doctors.value)}
                   noDataLabel={'No doctors have been added yet.'}
                   title={'Doctors'} />
}
