import React from 'react';
import { Box, Button, Card, Stack, Typography } from '@mui/material';
import { PencilSimple } from '@phosphor-icons/react';
import { formatToPhoneNumber } from '@coverright/ui/inputs';
import { useAppSelector } from '../../shared';
import { profile as profileSelector } from '../../entities';
import { AccountSettingsForm } from './ui/account-settings-form';

export function AccountSettings() {
  const profile = useAppSelector(profileSelector);
  const [edit, setEdit] = React.useState(false);

  if (!profile) {
    return null;
  }

  return <div>
    <Typography sx={{mb: 3}} variant={'h3'}>Account Settings</Typography>

    <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: 3}}>
      <Card sx={{p: 3, flex: 1}}>
        <Typography variant={'body2'} className={'epilogue semi-bold mb-24'}>Contact Settings</Typography>

        {!edit && <Stack spacing={3}>
          <Param label={'First Name'} value={profile.firstName} />
          <Param label={'Last Name'} value={profile.lastName} />
          <Param label={'Phone Number'} value={formatToPhoneNumber(profile.phoneNumber)} />
          <Param label={'Email Address'} value={profile.email} />
          <div>
            <Button variant={'contained'} onClick={() => setEdit(true)}
                    startIcon={<PencilSimple size={24} color="#FFFFFF" weight="fill" />}>Edit</Button>
          </div>
        </Stack>}
        {edit && <AccountSettingsForm profile={profile} onSave={() => setEdit(false)} onCancel={() => setEdit(false)} />}
      </Card>


      <Box flex={1}>
        {/*<Card sx={{p: 3}}>
          <Typography variant={'body2'} className={'epilogue semi-bold mb-16'}>Password Settings</Typography>

          <Typography>Current Password</Typography>
          <Typography>**********</Typography>

          <Button variant={'contained'} onClick={() => document.location.href = keycloak.createAccountUrl()} className={'mt-24 pv-8 lh-24 fs-16'} color={'primary'}>Update Password</Button>
        </Card>*/}


        <Card sx={{p: 3}}>
          <Typography variant={'body2'} className={'epilogue semi-bold mb-8'}>Delete Account</Typography>

          <Typography>
            Have an issue with your account?
            <Box component={'a'} sx={{color: '#1C434F'}} className={'ml-8 semi-bold'} href="mailto:info@coverright.com?subject=DeleteAccount">
              Contact us
            </Box>
          </Typography>

        </Card>

      </Box>
    </Box>
  </div>
}


interface ParamProps {
  label: string,
  value?: string | null,
}

const Param = (props: ParamProps) => {
  return <Box>
    <Typography>{props.label}</Typography>
    <Typography sx={{color: '#1C434F'}} className={'semi-bold mt-4'}>{props.value}</Typography>
  </Box>
}

