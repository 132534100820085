import * as React from 'react';
import { getIsAdmin, KeycloakContext } from '@coverright/shared/keycloak';
import {
  EnrollmentListItemOutput,
  EnrollmentType,
  useHasEnrollmentsLazyQuery
} from '@coverright/data-access/types/enrollment';
import { enrollmentClient } from '@coverright/data-access/apollo-clients';
import { useEffect, useState } from 'react';
import { EnrolledPlan, PlanType } from '@coverright/data-access/medicare';
import useGetMyEnrollments from '../hooks/use-get-my-enrollments';
import { MAPlanSelector, PDPPlanSelector } from './plan-selector';
import { AppActionTypes, AppContext } from '@coverright/shared/contexts';

type IPostEnrollmentContext = {
  hasEnrollmentLoading: boolean,
  hasEnrollment: boolean,
  enrolledPlans: EnrolledPlan[],
  enrollments: Partial<EnrollmentListItemOutput>[],
}

const initial: IPostEnrollmentContext = {
  hasEnrollmentLoading: true,
  hasEnrollment: false,
  enrollments: [],
  enrolledPlans: []
}

export const PostEnrollmentContext = React.createContext<IPostEnrollmentContext>(initial);

export function PostEnrollmentProvider(props: React.PropsWithChildren<any>) {
  const {keycloak} = React.useContext(KeycloakContext);
  const [{state}, dispatch] = React.useContext(AppContext);

  const [getHasEnrollment, hasEnrollmentData ] = useHasEnrollmentsLazyQuery({client: enrollmentClient})
  const [hasEnrollment, setHasEnrollment] = React.useState(false);
  const [hasEnrollmentLoading, setHasEnrollmentLoading] = useState(true);

  const [pdpPlanSelectorOpen, setPdpPlanSelectorOpen] = useState(false);
  const [maPlanSelectorOpen, setMaPlanSelectorOpen] = useState(false);
  const {enrolledPlans, enrollments, plansLoaded, refresh: myEnrollmentsRefresh} = useGetMyEnrollments(hasEnrollment);

  useEffect(() => {
    if (keycloak?.authenticated) {
      if (!getIsAdmin(keycloak)) {
        getHasEnrollment().then(res => setHasEnrollment(!!res.data?.hasEnrollments))
      } else {
        setHasEnrollmentLoading(false)
      }
    }
  }, [keycloak?.authenticated]);

  useEffect(() => {
    setHasEnrollmentLoading(!hasEnrollmentData.called || hasEnrollmentData.loading)
  }, [hasEnrollmentData.loading, hasEnrollmentData.called]);


  useEffect(() => {
    if (plansLoaded) {
      setMaPlanSelectorOpen(enrollments.some(e => e.type === EnrollmentType.Ma) && !enrolledPlans.some(p => p.type === PlanType.Ma))
    }
  }, [enrolledPlans, enrollments, plansLoaded]);

  useEffect(() => {
    if (plansLoaded) {
      setPdpPlanSelectorOpen(enrollments.some(e => e.type === EnrollmentType.Pdp) && !enrolledPlans.some(p => p.type === PlanType.Pdp))
    }
  }, [enrolledPlans, enrollments, plansLoaded]);


   useEffect(() => {
     if (localStorage.getItem('resetStoredEnrollmentPlans') === 'true') {
       setTimeout(() => {
         dispatch({
           type: AppActionTypes.SAVE_STATE,
           payload: [{
             stateKey: 'overridePDPEnrollment',
             value: undefined,
           }, {
             stateKey: 'overrideMAEnrollment',
             value: undefined,
           }]
         })
         localStorage.setItem('resetStoredEnrollmentPlans', 'false')
         setTimeout(() => {
           window.location.reload();
         }, 2000)
       }, 6000)
     }
  }, []);



  return <PostEnrollmentContext.Provider value={{hasEnrollment, hasEnrollmentLoading, enrollments, enrolledPlans}}>
    <PDPPlanSelector open={pdpPlanSelectorOpen}
                     onClose={myEnrollmentsRefresh}
                     zip={enrollments.find(e => e.type === EnrollmentType.Pdp)?.zip}
                     county={enrollments.find(e => e.type === EnrollmentType.Pdp)?.county}
    />
    <MAPlanSelector open={maPlanSelectorOpen}
                    onClose={myEnrollmentsRefresh}
                    zip={enrollments.find(e => e.type === EnrollmentType.Ma)?.zip}
                    county={enrollments.find(e => e.type === EnrollmentType.Ma)?.county}
    />

    {props.children}
  </PostEnrollmentContext.Provider>
}

