import {Collapse, Typography} from "@mui/material";
import {convertBold} from "@coverright/utils";
import React from "react";

export const Description = ({value, open}: {value: CategoryDescription, open: boolean}) => {
  return <Collapse in={open}>
    <Typography className={'bold'}>{value.title}</Typography>
    <Typography dangerouslySetInnerHTML={{__html: convertBold(value.subTitle)}} />
    <ul>
      {value.keyPoints.map((point, i) => <li dangerouslySetInnerHTML={{__html: convertBold(point)}} key={i} />)}
    </ul>
  </Collapse>
}

interface CategoryDescription {
  title: string,
  subTitle: string,
  keyPoints: string[],
}
