import { environment } from '../../../../environments/environment';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { VideoCategoryWithVideos } from '..';
import * as _ from 'lodash';

export const fetchVideoCategories = createAsyncThunk(
  'videoCategory/fetchVideoCategories',
  async (__, {dispatch}): Promise<VideoCategoryWithVideos[]> => {
    try {
      const result = await fetch(
        environment.strapiUrl + `/api/video-categories?populate[videos][populate][0]=picture&pagination[pageSize]=100&publicationState=live`,
        {
          headers: {
            "content-type": "application/json",
            'Authorization': 'Bearer ' + process.env.NX_STRAPI_TOKEN
          },
          cache: 'no-cache',
        }
      ).then(res => res.json());

      if (result) {
        return _.sortBy(result.data, 'sortingOrder').map((item: any) => ({
          id: item.id,
          ...item.attributes,
          videos: _.sortBy(item.attributes.videos.data, 'sortingOrder').map((v: any) => ({
            id: v.id,
            ...v.attributes,
            picture: {
              url: v.attributes.picture.data.attributes.url,
              formats: {
                large: v.attributes.picture.data.attributes.formats.large.url,
                small: v.attributes.picture.data.attributes.formats.small.url,
                medium: v.attributes.picture.data.attributes.formats.medium.url,
                thumbnail: v.attributes.picture.data.attributes.formats.thumbnail.url,
              }
            }
          }))
        }))
      } else {
        return []
      }
    } catch (e) {
      return []
    }
  }
)
