import { Button } from '@mui/material';
import React from 'react';
import {  useAppSelector } from '../../shared';
import { useNavigate } from 'react-router';
import RestartQuizButton from './ui/restart-quiz-button';

export function TakeQuizButton({label}: {label?: string}) {
  const navigate = useNavigate();
  const quizCompleted = true; //useAppSelector(quizCompletedSelector)

  return <>
    {quizCompleted && <RestartQuizButton />}
    {!quizCompleted && <Button onClick={() => navigate('/quiz/q1')}
                               variant={'text'}
                               size={'small'}
                               sx={{ color: '#1C434F', cursor: 'pointer', width: 'fit-content' }}>
      {label || 'Take our Medicare quiz'}
    </Button>}
  </>
}

