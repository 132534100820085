import React from 'react';
import { Card, Typography } from '@mui/material';
import { DoctorSelection } from '@coverright/widgets';
import { useAppSelector } from '../shared';
import { medicareQuote, preferredDoctors } from '../entities';
import { useSaveDoctors } from '../entities';
import { V3Preloader } from '@coverright/ui/shared';
import { SideLayout } from '../shared';

export default function () {
  const doctors = useAppSelector(preferredDoctors);
  const quote = useAppSelector(medicareQuote);
  const { save } = useSaveDoctors();

  if (!quote) {
    return <V3Preloader sx={{ my: 6 }} />;
  }

  return (
    <SideLayout
      title={'Doctors in-network'}
      subtitle={
        'Search for the doctors within your network and add them to your list of preferred doctors.'
      }
    >
      <Card sx={{ p: 3 }}>
        <Typography variant={'h4'} className={'fs-24 lh-24 mb-24'}>
          Search doctor
        </Typography>
        <DoctorSelection
          zip={quote.zip}
          inputPosition={'top'}
          value={doctors}
          onChange={save}
        />
      </Card>
    </SideLayout>
  );
}
