import React, {useState} from 'react';
import { Box, Collapse, Tabs } from '@mui/material';
import LinkTab from './link-tab';
import useNavigationItems from "../api/use-navigation-items";
import {NavigationItem} from "../model/types";
import {findRouteIndex, findRouteInItemSets} from '../lib/lib';
import {setPostEnrollmentNavIndex, useAppDispatch} from "../../../shared";

type Props = {
  pathname: string,
  onTabClick: (link: string) => void,
  onNavIndexChange?: (index: number) => void
}

export function Navigation({pathname, onTabClick, itemSets, onNavIndexChange}: Props & { itemSets: NavigationItem[][] }) {
  const [value, setValue] = React.useState<number | false>(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  React.useEffect(() => {
    if (itemSets.length) {
      const items = itemSets[currentIndex];
      const index = findRouteIndex(items, pathname);

      if (~index) {
        setValue(~index ? index : false);
      } else {
        const [itemSetIndex, routeIndex] = findRouteInItemSets(itemSets, pathname);
        if (~routeIndex) {
          if (onNavIndexChange) {
            onNavIndexChange(itemSetIndex);
          }
          setCurrentIndex(itemSetIndex);
          setValue(routeIndex)
        } else {
          setValue(false)
        }
      }

    }
  }, [pathname, itemSets, currentIndex])

  return (
    <Collapse sx={{mb: {sm: 4}}} in={!!itemSets.length}>
      <Box sx={{ display: {xs: 'none', sm: 'block'}, width: '100%' }}>
        {itemSets.map((items, i) => (
          <Collapse in={currentIndex === i} key={i}>
              <Tabs value={value}
                    variant="fullWidth"
                    data-test={`nav-tabs-${i}`}
                    className={currentIndex === i ? 'active' : undefined}>
                {items.map((item, i) => (
                  <LinkTab onClick={() => onTabClick(Object.values(item.routes)[0])}
                           active={value === i}
                           label={item.label}
                           href={Object.values(item.routes)[0]}
                           key={Object.values(item.routes)[0]}
                           badge={item.badge}
                           Icon={item.icon} />
                ))}
              </Tabs>
          </Collapse>
        ))}
      </Box>
    </Collapse>
  );
}

export default function (props: Props) {
  const dispatch = useAppDispatch();
  return <Navigation {...props}
                     onNavIndexChange={index => dispatch(setPostEnrollmentNavIndex(index))}
                     itemSets={useNavigationItems()} />
}


