import { PdpPlanListQuery } from '../../../shared/api/medicare';
import React from 'react';
import { areComponentPropsEqual } from '@coverright/utils';
import { PdpPlanCard } from '../../../entities';

interface MemoizedListProps {
  plans: PdpPlanListQuery['PdpPlans']['data'],
}

const MemoizedList = React.memo(({plans}: MemoizedListProps) => {
  return <>
    {plans.map(plan => <PdpPlanCard plan={plan as any}
                                       key={plan.bidId} />)}
  </>
}, areComponentPropsEqual)

export default MemoizedList;
