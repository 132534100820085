import {useAppSelector} from "../../../shared";
import { useSaveMedigapQuoteMutation } from '../../../shared/api/medigap';
import { medigapQuote } from '../../../entities';
import { MedigapPlansFilterInput } from '@coverright/data-access/types/medigap';

export default function useMaSaveFilters() {
  const [save] = useSaveMedigapQuoteMutation();
  const quote = useAppSelector(medigapQuote);

  const saveFilters = (filters?: MedigapPlansFilterInput) => {
    if (filters) {
      save({
        input: {
          medigapFilterState: {
            ...quote?.medigapFilterState,
            ...filters,
          },
          cId: quote?.cId,
          id: quote?.id,
        }
      })
    }
  }

  return saveFilters;
}
