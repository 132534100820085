import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {api, MyEnrollmentsQuery} from "../api/enrollment";
import {EnrolledPlan, PlanType, PlanYear} from "@coverright/data-access/medicare";
import {PdpPlan, Plan} from "@coverright/data-access/types/medicare";
import {EnrollmentType} from "@coverright/data-access/types/enrollment";
import chooseEnrolledPlanFromMany from "./lib/choose-enrolled-plan-from-many";
import {ABTestType, fetchABTesting} from "./api/fetch-a-b-testing";

type Index = {
  hasEnrollments?: boolean,
  myEnrollments?: MyEnrollmentsQuery['myEnrollments'],
  enrolledPlans: EnrolledPlan[],
  overrideMAEnrollment?: Plan,
  overridePDPEnrollment?: PdpPlan,
  callScheduled: boolean,
  marketplaceType: MarketplaceType,
  postEnrollmentNavIndex: number,
  abTesting: {[key in ABTestType]?: boolean},

}

const initialState: Index = {
  enrolledPlans: [],
  callScheduled: false,
  marketplaceType: 'MA',
  postEnrollmentNavIndex: 0,
  abTesting: {},
}

const rootSlice = createSlice({
  name: 'root',
  initialState,
  selectors: {
    abTesting(state) {
      return state.abTesting;
    },
    postEnrollmentNavIndex(state) {
      return state.postEnrollmentNavIndex;
    },
    hasEnrollments(state) {
      return state.hasEnrollments;
    },
    hasMaEnrollment(state) {
      return state.myEnrollments?.some(e => e.type === EnrollmentType.Ma);
    },
    hasPdpEnrollment(state) {
      return state.enrolledPlans?.some(e => e.type === PlanType.Pdp);
    },
    enrolledPlans(state) {
      return state.enrolledPlans;
    },
    enrolledPlan(state, type?: PlanType) {
      return chooseEnrolledPlanFromMany(state.enrolledPlans, type);
    },
    callScheduled(state) {
      return state.callScheduled;
    },
    marketplaceType(state) {
      return state.marketplaceType;
    },
  },
  reducers: {
    setPostEnrollmentNavIndex(state, {payload}: PayloadAction<number>) {
      state.postEnrollmentNavIndex = payload;
    },
    setMarketplaceType(state, {payload}: PayloadAction<MarketplaceType>) {
      state.marketplaceType = payload;
    },
    setEnrolledPlans(state, action: PayloadAction<EnrolledPlan[]>) {
      state.enrolledPlans = action.payload

      if (!state.myEnrollments) {
        state.enrolledPlans = action.payload;
        return
      }

      const result: EnrolledPlan[] = [...action.payload];

      for (const enrollment of state.myEnrollments) {
        if (!result.map(p => p.bidId).includes(String(enrollment.bidId))) {
          let overrided;
          if (enrollment.type === EnrollmentType.Ma && state.overrideMAEnrollment) {
            overrided = state.overrideMAEnrollment;
          }
          if (enrollment.type === EnrollmentType.Pdp && state.overridePDPEnrollment) {
            overrided = state.overridePDPEnrollment;
          }
          if (overrided) {
            result.push({
              county: enrollment.county!,
              enrollmentId: enrollment.id,
              flexpaIntegrationError: '',
              isFlexpaIntegrated: false,
              year: enrollment.planYear as PlanYear,
              zip: enrollment.zip!,
              bidId: overrided.bidId!,
              orgName: overrided.orgName!,
              parentOrgName: overrided.parentOrgName!,
              planName: overrided.planName!,
              premium: overrided.monthlyPremium!,
              renewalDate: '-',
              effectiveDate: '-',
              type: PlanType.Ma,
            })
          }

        } else {
          if (enrollment.type === EnrollmentType.Ma && state.overrideMAEnrollment) {
            state.overrideMAEnrollment = undefined;
          }
          if (enrollment.type === EnrollmentType.Pdp && state.overridePDPEnrollment) {
            state.overridePDPEnrollment = undefined;
          }
        }
      }
      state.enrolledPlans = result;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchABTesting.fulfilled, (state, {payload}) => {
      payload.forEach(t => state.abTesting[t.key] = t.active)
    })
    builder.addMatcher(
      api.endpoints.hasEnrollments.matchFulfilled,
      (state, {payload}) => {
        state.hasEnrollments = payload.hasEnrollments;
      }
    )
    builder.addMatcher(
      api.endpoints.myEnrollments.matchFulfilled,
      (state, {payload}) => {
        state.myEnrollments = payload.myEnrollments;
      }
    )
  }
})



export const rootReducer = rootSlice.reducer;

export const {
  setEnrolledPlans,
  setMarketplaceType,
  setPostEnrollmentNavIndex,
} = rootSlice.actions;

export const {
  hasEnrollments,
  hasMaEnrollment,
  enrolledPlans,
  enrolledPlan,
  hasPdpEnrollment,
  callScheduled,
  marketplaceType,
  postEnrollmentNavIndex,
} = rootSlice.selectors;

export type MarketplaceType = 'MA' | 'MG' | 'PDP';

export * from './api/fetch-a-b-testing';
