import React from 'react';
import {useNavigate, useLocation} from 'react-router';
import {routes} from "../app/router-new";
import { marketplaceType, useAppSelector } from '../shared';

export default function() {
  const navigate = useNavigate();
  const location = useLocation();
  const type = useAppSelector(marketplaceType);

  React.useEffect(() => {
    if (type) {
      if (type === 'MG' && location.pathname !== routes.planFinderMG.value) {
        navigate(routes.planFinderMG.value, { replace: true })
      } else if (type === 'MA' && location.pathname !== routes.planFinderMA.value) {
        navigate(routes.planFinderMA.value, { replace: true })
      } else if (type === 'PDP' && location.pathname !== routes.planFinderPDP.value) {
        navigate(routes.planFinderPDP.value, { replace: true })
      }
    } else {
      navigate('/quiz', { replace: true })
    }
  }, [type, location.pathname])

  return null;
}
