import {Box,  Button} from "@mui/material";
import {PlusCircle} from "@phosphor-icons/react";
import React from "react";

interface Props {
  onClick: () => void,
  opened: boolean
}

export default function ToggleAllButton({onClick, opened}: Props) {
  return <Button variant={'text'}
                 onClick={onClick}
                 endIcon={<Box sx={{pt: .8, transform: opened ? 'rotate(45deg)' : 'rotate(0)', transition: 'all 0.3s'}}>
                   <PlusCircle size={24} color={'#1C434F'} />
                 </Box>}
                 size={'small'}>
    {opened ? 'Close' : 'Open'} all the categories
  </Button>
}
