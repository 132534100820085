import {
  useLazyMaPlanDiscountsQuery,
  useLazyPdpPlanDiscountsQuery
} from "../../../shared/api/medicare";
import {useEffect, useMemo} from "react";
import {EnrolledPlan, PlanType} from "@coverright/data-access/medicare";


export default function useHasDiscounts(plan: EnrolledPlan, quoteId?: string, clientId?: string) {
  const [maRequest, {data: maData, isLoading: maLoading, startedTimeStamp: maStarted}] = useLazyMaPlanDiscountsQuery();
  const [pdpRequest, {data: pdpData, isLoading: pdpLoading, startedTimeStamp: pdpStarted}] = useLazyPdpPlanDiscountsQuery();


  useEffect(() => {
    if (plan.zip && plan.county) {
      if (plan.type === PlanType.Ma) {
        maRequest({
          countyName: plan.county,
          planYear: plan.year,
          zip: plan.zip,
          bidId: plan.bidId,
          quoteId,
        });
      } else if (plan.type === PlanType.Pdp) {
        pdpRequest({
          bidId: plan.bidId,
          clientId,
          countyName: plan.county,
          planYear: plan.year,
          zip: plan.zip
        });
      }
    }
  }, [plan, quoteId, clientId]);

  const discounts = useMemo(() => {
    if (maData?.maPlanDiscounts?.discountedDrugs?.length) {
      return maData?.maPlanDiscounts?.discountedDrugs?.map(d => +d.discount)
    }
    if (pdpData?.pdpPlanDiscounts?.discountedDrugs?.length) {
      return pdpData?.pdpPlanDiscounts?.discountedDrugs?.map(d => +d.discount)
    }
    return []
  }, [maData?.maPlanDiscounts, pdpData?.pdpPlanDiscounts]);

  return {hasDiscounts: discounts.some(v => v > 0), loading: (!maStarted && !pdpStarted) || maLoading || pdpLoading};
}
