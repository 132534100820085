import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Card,
  Collapse,
  Divider,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { CRTooltip } from '@coverright/ui/inputs';
import {
  Benefit,
  CRSubtitle,
  DoctorCoverageList,
  DrugCoverageList,
  PlanParam,
  SeeMore,
  TooltipedTitle
} from '@coverright/ui/marketplaces';
import { CompanyLogo, CRPreloader, StarRating } from '@coverright/ui/shared';
import {
  areComponentPropsEqual, convertBold, convertList, getCompanyLogo,
  getInitialTooltipByTier,
  GetPlansYear,
  getQuoteId,
  mapDeliveryType
} from '@coverright/utils';
import {
  useMedicarePlan
} from '@coverright/data-access/medicare';
import { DrugOutput, Plan, PlanYear } from '@coverright/data-access/types/medicare';
import { routes } from '../../../app/router-new';
import { SxProps } from '@mui/material';
import { ArrowRight } from '@phosphor-icons/react';
import { CompareToggleButton, SelectPlanButton } from '../../../features';

const planStyles = makeStyles((theme: Theme) =>
  createStyles({
    white: {
      color: 'white',
      fontWeight: 700
    },
    wrapper: {
      minHeight: 717,
      [theme.breakpoints.up('sm')]: {
        minHeight: 540
      }
    },
    logo: {
      [theme.breakpoints.up('sm')]: {
        height: 33,
        width: 119,
        minWidth: 119,
        marginTop: '4px',
        backgroundPosition: 'right'
      },
      backgroundPosition: 'left',
      height: 50,
      width: 180,
      minWidth: 180,
      marginTop: 0,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain'
    },
    title: {
      textAlign: 'center',
      fontWeight: 500,
      maxWidth: 650
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 0,
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)'
    },
    infoWrapper: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      marginBottom: '10px',

    },
    mobileBenefitLabel: {
      width: '50%'
    },
    link: {
      fontSize: 12,
      color: 'rgba(28, 67, 79, 1)'
    },
    enrollSection: {
      padding: '24px 16px 20px',
      margin: '16px 0',
      [theme.breakpoints.up('md')]: {
        maxWidth: 248,
        padding: '32px 24px',
        margin: 0
      },
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      background: 'rgba(28,67,79,0.06)',
      borderRadius: 10,
      position: 'relative'
    },
    bold: {
      fontWeight: 700
    },
    divider: {
      color: '#B3B3B3'
    },
    medium: {
      fontWeight: 500
    },
    price: {
      fontWeight: 700,
      fontSize: 36,
      marginRight: 20,
      color: 'rgba(28, 67, 79, 1)'
    },
    thinTitle: {
      fontFamily: 'Sailec',
      fontSize: 14
    },
    checkbox: {
      background: 'transparent',
      padding: 0,
      marginLeft: -8
    },
    checkboxLabel: {
      marginLeft: 3,
      fontWeight: 500,
      fontSize: 14
    },
    summaryButton: {
      // border: '1px solid #778E95',
      padding: 4,
      whiteSpace: 'nowrap',
      width: '100%',
      borderRadius: 4,
      '& .MuiButton-label': {
        fontSize: '12px !important'
      }
    },
    enrollButton: {
      width: '100%',
      fontWeight: 500,
    },
    detailsEnrollButton: {
      padding: '11px 44px',
      whiteSpace: 'nowrap'
    },
    icon: {
      marginLeft: 5,
      transform: 'rotate(0)',
      transition: 'all 0.3s'
    },
    iconOpen: {
      transform: 'rotate(-180deg) !important'
    },
    snp: {
      padding: '5px 0 7px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(2, 144, 148, 1)',
      marginBottom: -6,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      borderBottom: '6px solid white'
    },
    green: {
      color: '#029094',
      opacity: 1
    },
    red: {
      color: '#DD3D3D',
      opacity: 1
    }
  })
);

type PlanCardProps = {
  plan: Plan,
  zip: string,
  countyName: string,
  hideEnroll?: boolean,
  onAddClick?: () => void,
  onDetailsToggle?: (value: boolean) => void,
  open?: boolean,
  planYear: PlanYear,
  visible?: boolean,
  preferredDrugs: DrugOutput[],
  favorites: string[],
  toggleFavorite: (bidId: string, zip: string) => void,
}

const tooltipImgProps = {
  width: 12,
  height: 12,
  className: 'display-none',
  src: '/plan-finder/assets/img/info.svg'
};

function PlanCard({ plan, zip, countyName, hideEnroll, preferredDrugs, onDetailsToggle, open: outerOpen, planYear, favorites, toggleFavorite }: PlanCardProps) {
  const classes = planStyles();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(outerOpen || false);
  const [getDetails, { data, loading }] = useMedicarePlan();
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('md'));

  const planDetails = React.useMemo(() => {
    return data?.plan;
  }, [data]);

  React.useEffect(() => {
    if (open || outerOpen) {
      if (outerOpen && open !== outerOpen) {
        setOpen(outerOpen);
      }
      getDetails({
        variables: {
          quoteId: getQuoteId(),
          zip,
          countyName,
          bidId: plan.bidId
        }
      });
    }
  }, [open, outerOpen]);

  return <div data-test={'ma_plan_card'} className={`${classes.wrapper}`}>
    {!!plan.SNPType && <div className={classes.snp}><TooltipedTitle title={'Special Needs Plan (SNP)'}
                                                                    labelSx={{ color: 'white', fontWeight: 700 }}
                                                                    tooltip={'Special Needs Plans (SNPs) are a type of Medicare Advantage plan that cater to beneficiaries with specific special diseases or characteristics. SNPs tailor their plan benefits, provider networks and drug formularies to meet the needs of the specific groups they serve. There are three different types of SNP plans. Dual-SNPs (D-SNP) serve beneficiaries that are eligible for both Medicare and Medicaid. Chronic-SNPs (C-SNP) serve beneficiaries with certain chronic illnesses. Institutional-SNPs (I-SNP) serve beneficiaries who reside in an institution, like a nursing home.'}
                                                                    imgSrc={'/plan-finder/assets/img/info-white.svg'}
                                                                    imgProps={tooltipImgProps}/></div>}
    <Card className={classes.card} sx={{p: {xs: 2, md: 4}}}>
      <Box sx={{display: 'flex', gap: 3}}>
        <div className={classes.infoWrapper}>
          <PlanTitle plan={plan}
                     hideFavorites
                     titleHref={routes.planDetails.value.replace(':id', plan.bidId)}
                     inFavorites={favorites.indexOf(plan.bidId) >= 0}
                     favoritesClick={() => toggleFavorite(plan.bidId, zip)}/>
          {phone ? <EnrollSection planYear={planYear}
                                  zip={zip}
                                  countyName={countyName}
                                  plan={plan}
                                  phone
                                  hideEnroll={!!hideEnroll}
                                  preferredDrugs={preferredDrugs}
                                  onBreakdownClick={() => navigate(routes.drugCost.value.replace(':id', plan.bidId))}
          /> : <Divider sx={{ my: 2 }}/>}
          <DetailSection zip={zip} countyName={countyName} plan={plan} phone={phone} planYear={planYear}/>
        </div>
        {!phone && <EnrollSection planYear={planYear}
                                  zip={zip}
                                  countyName={countyName}
                                  plan={plan}
                                  hideEnroll={!!hideEnroll}
                                  preferredDrugs={preferredDrugs}
                                  onBreakdownClick={() => navigate(routes.drugCost.value.replace(':id', plan.bidId))}
        />}
      </Box>
      {!phone && <>
        <Divider sx={{ my: 2 }}/>
        <div className={'pointer flex-column flex-align-center'} onClick={() => setOpen(prev => {
          if (onDetailsToggle) {
            onDetailsToggle(!prev);
          }
          return !prev;
        })}>
          <Box sx={{ pb: '6px' }} className={'flex'}>
            <Typography className={'fs-12 lh-12 '}>{open ? 'Hide' : 'Show'} additional details</Typography>
            <img src={'/plan-finder/assets/img/green-arrow-small.svg'}
                 className={clsx({ [classes.icon]: true, [classes.iconOpen]: open })}/>
          </Box>
        </div>
        <Collapse in={open} mountOnEnter>
          {loading && <CRPreloader/>}
          {planDetails && <>
            <div className={'h-16'}/>
            <CRSubtitle sx={{ mx: 1, width: 'calc(100% - 16px)', borderRadius: '10px', p: '7px 16px' }}
                        titleClassName={'fs-20'} title={'Additional Details'}
                        icon={'/plan-finder/assets/img/medical-record-1.svg'}/>
            <Box p={'18px 26px 16px'}>
              <SimplePlanDetail title={'Plan Type'}
                                tooltip={'Plan types include Preferred Provider Organizations (PPO) and Health Maintenance Organization (HMO) plans. PPO plans typically have a wider availability of healthcare providers, but often have higher doctor visit copays. HMO plans require you to select a Primary Care Physician, and you will need a referral to see a specialist. However, copays are typically lower than a PPO plan.'}
                                content={`Medicare Advantage (${planDetails.planType})`}/>
              {!!plan.partBGiveBack && <SimplePlanDetail title={'Part B Giveback'}
                                                         tooltip={'A "Part B Give Back" is a feature in some Medicare Advantage plans that may help reduce some or all of the cost of a Medicare recipient\'s Part B premium each month.'}
                                                         content={<Benefit label={`${plan.partBAmount} per month`}
                                                                           value={!!plan.partBGiveBack}/>}/>}
              <ListPlanDetail title={'Inpatient Hospital Care'}
                              tooltip={'Includes services related to staying in a hospital for scheduled procedures, accidents, or medical emergencies.'}
                              content={planDetails?.hospitalBenefits || []}/>
              {(!!planDetails?.drugDetails && !!planDetails.extraBenefits.drugCoverage) && <>
                <SimplePlanDetail
                  hideDivider
                  title={'Prescription drug coverage'}
                  tooltip={'Part D (Prescription drug coverage) includes coverage for your prescription drugs. Part D plans can be purchased separately to accompany Original Medicare and Medigap plans. Part D plans are also often bundled with Medicare Advantage plans.'}
                  content={<div>
                    {planDetails?.quoteDrugsTiers?.pharmacyName && <Box display={'flex'} flexGrow={2} mb={'15px'}>
                      <Benefit
                        label={planDetails?.quoteDrugsTiers?.pharmacyName + ` (${mapDeliveryType(planDetails?.quoteDrugsTiers?.deliveryType)})`}
                        value={true} labelClass={'regular color-primary'}/>
                    </Box>}
                    <Button variant={'outlined'} size={'small'}
                            id={'ma-plan-card-change-pharmacy-button'}
                            onClick={() => {
                              navigate(routes.doctorsDrugs.value + '?tab=pharmacy')
                            }}>{planDetails?.quoteDrugsTiers?.pharmacyName ? 'Change' : 'Add'} Pharmacy</Button>
                  </div>}
                />
                <div className={'ph-40'}>
                  <ListPlanDetail title={'Drug Deductible'}
                                  titleClassName={'fs-12'}
                                  imgClassName={'w-15 h-15'}
                                  tooltip={'The amount you must pay out of pocket for your prescription drugs before your coverage begins to pay.'}
                                  content={[planDetails?.drugDetails?.mrxAltDedAmount || '', planDetails?.drugDetails?.mrxAltNoDedTier || '']}
                                  hideDivider/>
                  {!!planDetails?.quoteDrugsTiers?.initialCoverageDrugTiers.length && <>
                    <Typography className={'bold fs-12 mb-16'}>Preferred retail pharmacy cost sharing for 30 day
                      supply</Typography>
                  </>}
                  {planDetails?.quoteDrugsTiers?.initialCoverageDrugTiers.map((item: any) => <SimplePlanDetail
                    key={item.tierName} title={item.tierName}
                    titleClassName={'fs-12'}
                    imgClassName={'w-15 h-15'}
                    tooltip={getInitialTooltipByTier(item.tier)}
                    hideDivider
                    content={item.coverage} thin/>)}
                </div>
                <Divider sx={{ my: 3 }}/>
              </>}
              <Box mb={2}>
                <Typography className={'fs-16 bold'}><strong>Dental, Vision and Hearing Additional Benefits
                  details</strong></Typography>
              </Box>
              {!!planDetails.optionalSupplementalBenefitsItems?.length &&
                <ListPlanDetail title={'Optional supplemental benefits'}
                                tooltip={'An added benefit option, available with select plans, that a member may choose to elect in addition to the medical benefits covered by their Medicare Advantage plan.'}
                                content={planDetails.optionalSupplementalBenefitsItems}/>}
              <ListPlanDetail title={'Dental Services'}
                              tooltip={'Medicare Advantage plans often provide dental benefits at no extra cost. For example: coverage for routine dental exams, cleaning and fluoride treatment. '}
                              content={planDetails?.dental?.benefits || []}/>

              <ListPlanDetail title={'Vision Services'}
                              tooltip={'Medicare Advantage plans often provide vision benefits at no extra cost. For example: coverage for routine eye exams, contact lenses and eyeglasses.'}
                              content={planDetails?.vision?.benefits || []}/>

              <ListPlanDetail title={'Hearing Services'}
                              tooltip={'Medicare Advantage plans often provide built-in hearing benefits for no additional premium. For example: coverage for routine hearing exams and hearing aids.'}
                              content={planDetails?.hearing?.benefits || []}/>
              <Box display={'flex'} alignItems={'center'} mt={'24px'} justifyContent={'space-between'}>
                <CompareToggleButton type={'MA'} plan={{ id: plan.bidId, name: plan.planName || plan.bidId }}/>
                <Box display={'flex'} alignItems={'center'}>
                  <Link to={routes.planDetails.value.replace(':id', plan.bidId)} style={{ textDecoration: 'none' }}>
                    <Button id={'ma-plan-card-plan-details-button'} className={'w-165'} variant={'outlined'}>Plan details</Button>
                  </Link>
                  <div className={'w-16'}/>
                  <SelectPlanButton id={'ma-plan-card-select-this-plan-button'}
                                    className={'w-165'}
                                    data-test={'ma_select_plan_button'}>
                    Select this plan
                  </SelectPlanButton>
                </Box>
              </Box>
            </Box>
          </>}
        </Collapse>
      </>}
    </Card>
  </div>;
}

export default React.memo(PlanCard);

interface EnrollSectionProps {
  phone?: boolean,
  hideEnroll: boolean,
  plan: Plan,
  zip: string,
  planYear: PlanYear,
  preferredDrugs: DrugOutput[],
  countyName: string,
  onBreakdownClick: any,
}
const EnrollSection = React.memo(({phone, plan, preferredDrugs, hideEnroll, onBreakdownClick}: EnrollSectionProps) => {
  const classes = planStyles();

  const showDrugCostButton = typeof plan.monthlyCost === 'string' && !!preferredDrugs?.length && !plan.missingFormularyInAep

  if (!phone) {
    return <div className={classes.enrollSection}>
      <div className={'flex-column drug-calculation'}>
        <CRTooltip placement={'top'} arrow
                   title={'The average total cost per month is equal to the monthly plan premium, plus the estimated average monthly drug cost (based on the drug list you have entered), plus the average cost per month to see primary care and specialist doctors. See the Medicare Disclaimers at the bottom of this page for a full description of the breakdown of these costs.'}>
          <div>
            <Typography className={'bold fs-40 lh-48 mb-8'} align={'center'}>{plan.monthlyCost || 'N/A'}</Typography>
            <Box display={'flex'} alignItems={'center'}>
              <Typography className={'fs-14 lh-24 semi-bold'}>Est. Avg. total cost /mo</Typography>
              <img className={'w-15 h-15 ml-4'} src={'/plan-finder/assets/img/info-gray.svg'}/>
            </Box>
          </div>
        </CRTooltip>
        <Box className={'mt-24 flex-space-between'} alignItems={'flex-end'}>
          <Typography className={'fs-14 lh-24 medium'}>Monthly premium:</Typography>
          <Typography className={'fs-14 lh-24 bold'}>{plan.monthlyPremium}</Typography>
        </Box>
        <div className={'mt-8 flex-space-between'}>
          <Typography className={'fs-14 lh-24 medium'}>Est. drug costs:</Typography>
          <Typography className={'fs-14 lh-24 bold'}>{plan.drugsCost || 'N/A'}</Typography>
        </div>
        <div className={'mt-8 flex-space-between'}>
          <Typography className={'fs-14 lh-24 medium'}>Est. doctor costs:</Typography>
          <Typography className={'fs-14 lh-24 bold'}>{plan.doctorsCost}</Typography>
        </div>
        <div className={'mv-24'}>
          <CRTooltip placement={'top'} arrow
                     title={'The Part B premium is what most people pay for the Part B Medical coverage provided under Original Medicare. You still pay the Part B premium, even if you\'re enrolled in a Medicare Advantage plan. The premium amount you pay may be dependent on your income.'}>
            <Typography className={`fs-14 lh-24`}>Excl. standard Part B premium<br />Excl. optional benefits</Typography>
          </CRTooltip>
          {plan.optionalSupplementalBenefits && <CRTooltip placement={'top'} arrow
                                                           title={'This plan includes optional benefits you can add to your coverage at an additional cost. See Plan Details for more information.'}>
            <Typography  className={`fs-16 lh-26 mt-5`}>Excl. optional
              benefits</Typography>
          </CRTooltip>}
        </div>
        {showDrugCostButton && (
          <Button sx={{border: '1px solid #1C434F'}} className={'fs-16 p-16 medium'} fullWidth variant={'outlined'}
                  onClick={onBreakdownClick}
                  id={'ma-plan-drug-cost-breakdown-button'}
                  color={'primary'}>
            Drug Cost Breakdown
          </Button>
        )}

      </div>

      <div className={'mb-24'}>
        <Link to={routes.planDetails.value.replace(':id', plan.bidId)} style={{ textDecoration: 'none' }}>
          <Button id={'ma-plan-card-plan-details-button'} className={classes.enrollButton} variant={'outlined'}>Plan details</Button>
        </Link>
      </div>
      {!hideEnroll && <SelectPlanButton id={'ma-plan-card-select-this-plan-button'}
                                        data-test={'ma_select_plan_button'}>
        Select this plan
      </SelectPlanButton>}
      <div className={'mt-24'}>
        <CompareToggleButton type={'MA'} plan={{ id: plan.bidId, name: plan.planName || plan.bidId }}/>
      </div>
    </div>;
  } else {
    return <div className={classes.enrollSection}>
      <div className={'flex-column drug-calculation'}>
        <CRTooltip placement={'top'} arrow
                   title={'The average total cost per month is equal to the monthly plan premium, plus the estimated average monthly drug cost (based on the drug list you have entered), plus the average cost per month to see primary care and specialist doctors. See the Medicare Disclaimers at the bottom of this page for a full description of the breakdown of these costs.'}>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <Typography className={'fs-40 lh-48 bold'}>{plan.monthlyCost || 'N/A'}</Typography>
            <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'} flex={1}>
              <Typography color={'textPrimary'} className={'fs-14 lh-15 semi-bold'} align={'right'}>Est. Avg. total cost /mo</Typography>
              <img className={'w-15 h-15 ml-4'} src={'/plan-finder/assets/img/info-gray.svg'}/>
            </Box>
          </Box>
        </CRTooltip>
        <div className={'mt-8 flex-space-between'}>
          <Typography className={'fs-14 lh-24 medium'}>Monthly Premium:</Typography>
          <Typography className={'fs-14 lh-24 bold'}>{plan.monthlyPremium}</Typography>
        </div>
        <div className={'mt-5 flex-space-between'}>
          <Typography className={'fs-14 lh-24 medium'}>Drug costs:</Typography>
          <Typography className={'fs-14 lh-24 bold'}>{plan.drugsCost || 'N/A'}</Typography>
        </div>
        <div className={'mt-5 flex-space-between'}>
          <Typography className={'fs-14 lh-24 medium'}>Doctor costs:</Typography>
          <Typography className={'fs-14 lh-24 bold'}>{plan.doctorsCost}</Typography>
        </div>
        <Box my={2} display={'flex'} flex={1} flexDirection={'column'}>
          <CRTooltip placement={'top'} arrow
                     title={'The Part B premium is what most people pay for the Part B Medical coverage provided under Original Medicare. You still pay the Part B premium, even if you\'re enrolled in a Medicare Advantage plan. The premium amount you pay may be dependent on your income.'}>
            <Typography className={`fs-14 lh-24 no-wrap`}>Excl. standard Part B
              premium</Typography>
          </CRTooltip>
          {plan.optionalSupplementalBenefits &&
            <Typography  className={`fs-14 lh-24 no-wrap mt-5`}>Excl. optional
              benefits</Typography>}
        </Box>
        <Box display={'flex'} flex={1}>
          {showDrugCostButton && (
            <Box sx={{display: 'flex', alignItems: 'center', gap: .5}}>
              <Typography sx={{color: '#1C434F'}} onClick={onBreakdownClick} className={'pointer fs-14 lh-24'}>Drug cost breakdown</Typography>
              <ArrowRight size={16} color="#1C434F" weight="regular" />
            </Box>
          )}
        </Box>
      </div>
    </div>;
  }
}, areComponentPropsEqual);


interface DetailSectionProps {
  phone: boolean,
  plan: Plan,
  zip: string,
  planYear: PlanYear,
  countyName: string,
}

const DetailSection = React.memo(({phone, plan, zip}: DetailSectionProps) => {
  const classes = planStyles();
  const navigate = useNavigate();
  const [doctorCostsExpanded, setDoctorCostsExpanded] = React.useState(false);

  if (!phone) {
    return <div className={'flex'}>
      <div className={'flex-column'}>
        <TooltipedTitle title={<Typography className={'semi-bold'}>Monthly Plan Premium <sup>i</sup></Typography>}
                        tooltip={'This is the fixed monthly amount charged by the insurance company to maintain insurance coverage through the plan. Many Medicare Advantage plans have a $0 monthly premium.'}
                        imgProps={tooltipImgProps}/>
        <PlanParam label={''} className={'mt-5'} valueClassName={'fs-14'} value={plan.monthlyPremium || '$0'}/>
        <Divider sx={{ my: 2 }}/>
        <TooltipedTitle title={<Typography className={'semi-bold'}>Deductibles <sup>i</sup></Typography>}
                        tooltip={'The amount you must pay out of pocket for your health care before your coverage begins to pay. For example, in Original Medicare, you pay a new deductible for each benefit period for Part A, and each year for Part B. These amounts can change every year.'}
                        imgProps={tooltipImgProps}/>
        <PlanParam label={'Medical'} labelClassName={'fs-14 mt-8 semi-bold'} valueClassName={'fs-14'} value={`${plan?.inNetworkDeductableAmount || '$0'}`}/>
        <PlanParam label={'Drug'} labelClassName={'fs-14 semi-bold'} valueClassName={'fs-14'} value={`${plan?.drugDetails?.mrxAltDedAmountShort || 'N/A'}`}/>
        <div className={'mt-24'}>
          <TooltipedTitle title={<Typography className={'semi-bold'}>Max out-of-pocket <sup>i</sup></Typography>}
                          tooltip={'The maximum out-of-pocket costs that you can expect to pay for your healthcare services in each plan period. This includes copays, coinsurance amounts, hospital visits and other healthcare-related services. Once you reach this limit, the health plan pays 100% of your healthcare costs. The limit does not apply to premiums, balance-billed charges from out of network providers, or other services that are not covered by the plan.'}
                          imgProps={tooltipImgProps}/>
        </div>
        <PlanParam label={'In network'} labelClassName={'fs-14 mt-8 semi-bold'} valueClassName={'fs-14'} value={`${plan.outOfPocketAmount}`}/>
        {!!plan.maxEnrollmentAmount &&
          <PlanParam label={'Out-of-network'} labelClassName={'fs-14 semi-bold'} valueClassName={'fs-14'} value={`${plan.maxEnrollmentAmount}`}/>
        }
        <Divider sx={{ my: 2 }}/>
        <TooltipedTitle title={<Typography className={'semi-bold no-wrap'}>Doctor & Hospital Costs <sup>i</sup></Typography>}
                        tooltip={'Primary and Specialist doctor copays are the amount you can expect to pay for a regular visit to your primary care physician or a medical specialist (such as an orthopedist, dermatologist or cardiologist), as outlined in your policy details. Hospital cost is the total amount you will pay under this plan for a 7 day inpatient hospital stay.'}
                        labelSx={{ whiteSpace: 'nowrap' }}
                        imgProps={tooltipImgProps}/>
        <PlanParam label={'Primary doctor (In-network)'} labelClassName={'fs-14 mt-8 semi-bold'} valueClassName={'fs-14'}
                   value={plan?.primaryCarePhysicianShort || 'N/A'}/>
        <PlanParam label={'Specialist (In-network)'} labelClassName={'fs-14 semi-bold'} valueClassName={'fs-14'} className={'mv-8'}
                   value={plan.physicianSpecialistShort.join(',')}/>
        <PlanParam label={'Hospital (In-network)'} labelClassName={'fs-14 semi-bold'} valueClassName={'fs-14'} value={plan.firstWeekOfStay || 'N/A'}/>
      </div>
      <div className={'flex-column ml-20'}>
        <TooltipedTitle title={<Typography className={'semi-bold no-wrap'}>Your Doctors <sup>i</sup></Typography>}
                        tooltip={'Coverage of your doctors under this plan. In-network and out-of network (if applicable) doctor costs will be subject to the copays or coinsurance outlined as per this plan\'s policy.'}
                        imgProps={tooltipImgProps}/>
        {!!plan.doctorsCoverage.length &&
          <DoctorCoverageList titleClassName={'fs-14 semi-bold'} labelClassName={'fs-14 mb-8'} isPPO={plan.planType === 'PPO'} coverage={plan.doctorsCoverage}/>}
        {!plan.doctorsCoverage.length && <div>
          <Button variant={'outlined'} sx={{ mt: 1, px: '24px!important' }} size={'small'}
                  id={'ma-plan-card-add-doctor-button'}
                  onClick={() => {navigate(routes.doctorsDrugs.value)}}>
            Add doctors
          </Button>
        </div>}
        <Divider sx={{ my: 3 }}/>
        <div onClick={() => window.open(document.location.origin + '/summary/' + zip + '/' + plan.bidId)}>
          <TooltipedTitle title={<Typography className={'semi-bold no-wrap'}>Your Drugs <sup>i</sup></Typography>}
                          tooltip={'Coverage of your prescription drugs under this plan. Covered drugs will be subject to payments of the retail cost up until you meet your deductible, and then copayments or coinsurance based on the drug\'s tier according to plan policy details.'}
                          imgProps={tooltipImgProps}/>
        </div>
        {plan.missingFormularyInAep &&
          <Typography className={'fs-12 card-title'} color={'textPrimary'}>Speak to an Agent for 2023 drug details for
            this plan</Typography>}
        {!plan.missingFormularyInAep && <>
          {!!plan.drugsCoverage.length && <DrugCoverageList titleClassName={'fs-14 semi-bold'} labelClassName={'fs-14 mb-8'} coverage={plan.drugsCoverage}/>}
          {!plan.drugsCoverage.length && <div>
            <Button variant={'outlined'} sx={{ mt: 1, px: '24px!important' }} size={'small'}
                    id={'ma-plan-card-add-drugs-button'}
                    onClick={() => {navigate(routes.doctorsDrugs.value + '?tab=pharmacy')}}>
              Add drugs
            </Button>
          </div>}
        </>}
      </div>
      <div className={'flex-column ml-20'}>
        <TooltipedTitle title={<Typography className={'semi-bold no-wrap'}>Prescription coverage <sup>i</sup></Typography>}
                        tooltip={'Indicates whether this plan also includes bundled prescription drug coverage.'}
                        imgProps={tooltipImgProps}/>
        <div className={'mv-5'}><Benefit label={plan.extraBenefits.drugCoverage ? 'Yes' : 'No'}
                                         labelClass={'fs-14'}
                                         value={plan.extraBenefits.drugCoverage}/></div>
        <TooltipedTitle title={<Typography className={'semi-bold mt-16'}>Part B Premium Reduction <sup>i</sup></Typography>}
                        tooltip={'A Part B premium reduction benefit is offered by some Medicare Part C (Medicare Advantage) plans.\n\nIf you enroll in a Medicare Advantage plan with this benefit, the insurance company will help pay some or all of your Part B monthly premium. The amount covered can range from 10 cents to the full Part B premium cost.'}
                        imgProps={tooltipImgProps}/>
        <div className={'mt-5'}><Benefit label={plan.partBGiveBack ? `${plan.partBAmount}` : 'No'}
                                         labelClass={'fs-14'}
                                         value={!!plan.partBGiveBack}/></div>

        <Divider sx={{ my: 2 }}/>
        <TooltipedTitle title={<Typography className={'semi-bold'}>Extra benefits <sup>i</sup></Typography>}
                        tooltip={'Many Medicare Advantage plans offer additional vision, hearing and dental benefits at no extra cost, such as eye exams and eye glasses, hearing aids and dentures.'}
                        imgProps={tooltipImgProps}/>
        <div className={'mt-8'}><Benefit labelClass={`${plan.extraBenefits.dental ? classes.green : classes.red} fs-14`}
                                         label={'Dental'}
                                         value={plan.extraBenefits.dental}/></div>
        <div className={'mt-8'}><Benefit labelClass={`${plan.extraBenefits.vision ? classes.green : classes.red} fs-14`}
                                         label={'Vision'}
                                         value={plan.extraBenefits.vision}/></div>
        <div className={'mt-8'}><Benefit labelClass={`${plan.extraBenefits.hearing ? classes.green : classes.red} fs-14`}
                                         label={'Hearing'}
                                         value={plan.extraBenefits.hearing}/></div>
        <div className={'mt-8'}><Benefit labelClass={`${plan.extraBenefits.fitness ? classes.green : classes.red} fs-14`}
                                         label={'Fitness'}
                                         value={plan.extraBenefits.fitness}/></div>
        <div className={'mt-8'}><Benefit labelClass={`${plan.extraBenefits.insulinSavings ? classes.green : classes.red} fs-14`}
                                         label={'Insulin savings'}
                                         value={plan.extraBenefits.insulinSavings}/></div>
        <div className={'mt-8'}><Benefit labelClass={`${plan.extraBenefits.overTheCounter ? classes.green : classes.red} fs-14`}
                                         label={'OTC benefits'}
                                         value={plan.extraBenefits.overTheCounter}/></div>
        <div className={'mt-8'}><Benefit labelClass={`${plan.extraBenefits.telehealth ? classes.green : classes.red} fs-14`}
                                         label={'Telehealth'}
                                         value={plan.extraBenefits.telehealth}/></div>
        <div className={'mt-8'}><Benefit labelClass={`${plan.extraBenefits.transportation ? classes.green : classes.red} fs-14`}
                                         label={'Transportation'}
                                         value={plan.extraBenefits.transportation}/></div>
        {/*plan.otherDefinedSupplemental && (  WAITING FOR API
                  <Typography variant={'h5'}
                              color={'textSecondary'}
                              className={'mt-8'}><i>Some extra benefits require an additional premium (see Plan details for more information)</i></Typography>
                )*/}
      </div>
    </div>;
  } else {
    return <Box sx={{ px: '6px' }} className={'flex-column'}>
      <div className={'flex'}>
        <div className={'flex-column'}>
          <TooltipedTitle title={'Deductibles'}
                          tooltip={'The amount you must pay out of pocket for your health care before your coverage begins to pay. For example, in Original Medicare, you pay a new deductible for each benefit period for Part A, and each year for Part B. These amounts can change every year.'}
                          imgProps={tooltipImgProps}/>
          <PlanParam className={'mt-12'} label={'Medical'} value={`${plan?.inNetworkDeductableAmount || '$0'}`}/>
          <PlanParam className={'mt-12'} label={'Drug'} value={`${plan?.drugDetails?.mrxAltDedAmount || 'N/A'}`}/>
        </div>
        <div className={'flex-column'}>
          <TooltipedTitle title={'Max out-of-pocket'}
                          tooltip={'The maximum out-of-pocket costs that you can expect to pay for your healthcare services in each plan period. This includes copays, coinsurance amounts, hospital visits and other healthcare-related services. Once you reach this limit, the health plan pays 100% of your healthcare costs. The limit does not apply to premiums, balance-billed charges from out of network providers, or other services that are not covered by the plan.'}
                          imgProps={tooltipImgProps}/>
          <PlanParam className={'mt-12'} label={'In network'} value={`${plan.outOfPocketAmount} p.a.`}/>
          {!!plan.maxEnrollmentAmount &&
            <PlanParam className={'mt-12'} label={'Out-of-network'} value={`${plan.maxEnrollmentAmount} p.a.`}/>}
        </div>
      </div>
      <Divider sx={{ my: 2 }}/>
      <div className={'flex'}>
        <div className={'flex-column'}>
          <TooltipedTitle title={'Doctor & Hospital Costs'}
                          tooltip={'Primary and Specialist doctor copays are the amount you can expect to pay for a regular visit to your primary care physician or a medical specialist (such as an orthopedist, dermatologist or cardiologist), as outlined in your policy details. Hospital cost is the total amount you will pay under this plan for a 7 day inpatient hospital stay.'}
                          imgProps={tooltipImgProps}/>
          <PlanParam label={'Primary doctor (In-network)'} className={'mt-12'}
                     labelClassName={classes.mobileBenefitLabel} value={plan?.primaryCarePhysicianShort || 'N/A'}/>
          {doctorCostsExpanded && <>
            <PlanParam label={'Specialist (In-network)'} className={'mt-12'}
                       labelClassName={classes.mobileBenefitLabel} value={plan.physicianSpecialistShort.join(',')}/>
            <PlanParam label={'Hospital (In-network)'} className={'mt-12'} labelClassName={classes.mobileBenefitLabel}
                       value={plan.firstWeekOfStay || 'N/A'}/>
          </>}
          <SeeMore sx={{ mt: 1 }} expanded={doctorCostsExpanded} onToggle={value => setDoctorCostsExpanded(!value)}/>
        </div>
      </div>
      <Divider sx={{ my: '12px' }}/>
      <div className={'flex'}>
        <div className={'mb-4 flex-column'}>
          <TooltipedTitle title={'Your Doctors'}
                          tooltip={'Coverage of your doctors under this plan. In-network and out-of network (if applicable) doctor costs will be subject to the copays or coinsurance outlined as per this plan\'s policy.'}
                          imgProps={tooltipImgProps}/>
          {!!plan.doctorsCoverage.length &&
            <DoctorCoverageList isPPO={plan.planType === 'PPO'} coverage={plan.doctorsCoverage}/>}
          {!plan.doctorsCoverage.length && <div>
            <Button variant={'outlined'} sx={{ mt: 1, px: '24px!important' }} size={'small'}
                    id={'ma-plan-card-add-doctor-button'}
                    onClick={() => {navigate(routes.doctorsDrugs.value + '?tab=drugs')}}>
              Add doctors
            </Button>
          </div>}
        </div>
        <div className={'mb-4 flex-column'}>
          <div onClick={() => window.open(document.location.origin + '/summary/' + zip + '/' + plan.bidId)}>
            <TooltipedTitle title={'Your Drugs'}
                            tooltip={'Coverage of your prescription drugs under this plan. Covered drugs will be subject to payments of the retail cost up until you meet your deductible, and then copayments or coinsurance based on the drug\'s tier according to plan policy details.'}
                            imgProps={tooltipImgProps}/>
          </div>
          {plan.missingFormularyInAep &&
            <Typography className={'fs-12 card-title'} color={'textPrimary'}>Speak to an Agent for 2023 drug details for
              this plan</Typography>}
          {!plan.missingFormularyInAep && <>
            {!!plan.drugsCoverage.length && <DrugCoverageList coverage={plan.drugsCoverage}/>}
            {!plan.drugsCoverage.length && <div>
              <Button variant={'outlined'} sx={{ mt: 1, px: '24px!important' }} size={'small'}
                      id={'ma-plan-card-add-drugs-button'}
                      onClick={() => {navigate(routes.doctorsDrugs.value + '?tab=drugs')}}>
                Add drugs
              </Button>
            </div>}
          </>}
        </div>
      </div>
      <Divider sx={{ my: '12px' }}/>
      <TooltipedTitle title={'Prescription coverage'}
                      tooltip={'Indicates whether this plan also includes bundled prescription drug coverage.'}
                      imgProps={tooltipImgProps}/>
      <div className={'mv-5'}><Benefit label={plan.extraBenefits.drugCoverage ? 'Yes' : 'No'}
                                       value={plan.extraBenefits.drugCoverage}/></div>
      <div className={'h-12'}/>
      <TooltipedTitle title={'Part B Premium Reduction'}
                      tooltip={'A Part B premium reduction benefit is offered by some Medicare Part C (Medicare Advantage) plans.\n\nIf you enroll in a Medicare Advantage plan with this benefit, the insurance company will help pay some or all of your Part B monthly premium. The amount covered can range from 10 cents to the full Part B premium cost.'}
                      imgProps={tooltipImgProps}/>
      <div className={'mt-5'}><Benefit label={plan.partBGiveBack ? `${plan.partBAmount}` : 'No'}
                                       value={!!plan.partBGiveBack}/></div>

      <Divider sx={{ my: '12px' }}/>
      <TooltipedTitle title={'Extra benefits'}
                      tooltip={'Many Medicare Advantage plans offer additional vision, hearing and dental benefits at no extra cost, such as eye exams and eye glasses, hearing aids and dentures.'}
                      imgProps={tooltipImgProps}/>
      <div className={'flex'}>
        <div className={'flex-column'}>
          <div className={'mt-8'}><Benefit label={'Dental'}
                                           value={plan.extraBenefits.dental}/></div>
          <div className={'mt-8'}><Benefit label={'Vision'}
                                           value={plan.extraBenefits.vision}/></div>
          <div className={'mt-8'}><Benefit label={'Hearing'}
                                           value={plan.extraBenefits.hearing}/></div>
          <div className={'mt-8'}><Benefit label={'Fitness'}
                                           value={plan.extraBenefits.fitness}/></div>
        </div>
        <div className={'flex-column'}>
          <div className={'mt-8'}><Benefit label={'Insulin savings'}
                                           value={plan.extraBenefits.insulinSavings}/></div>
          <div className={'mt-8'}><Benefit label={'OTC benefits'}
                                           value={plan.extraBenefits.overTheCounter}/></div>
          <div className={'mt-8'}><Benefit label={'Telehealth'}
                                           value={plan.extraBenefits.telehealth}/></div>
          <div className={'mt-8'}><Benefit label={'Transportation'}
                                           value={plan.extraBenefits.transportation}/></div>
        </div>
        {/*plan.otherDefinedSupplemental && (  WAITING FOR API
                  <Typography variant={'h5'}
                              color={'textSecondary'}
                              className={'mt-8'}><i>Some extra benefits require an additional premium (see Plan details for more information)</i></Typography>
                )*/}
      </div>
      <Divider sx={{ my: 2 }}/>
      <div>
        <div className={'mb-8'}>
          <CompareToggleButton type={'MA'} plan={{ id: plan.bidId, name: plan.planName || plan.bidId }}/>
        </div>
        <Box sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'row'}, gap: 2, justifyContent: 'space-between'}}>
          <Link to={routes.planDetails.value.replace(':id', plan.bidId)} style={{ textDecoration: 'none' }}>
            <Button id={'ma-plan-card-plan-details-button'} className={classes.enrollButton} variant={'outlined'}>Plan details</Button>
          </Link>
          <div>
            <SelectPlanButton id={'ma-plan-card-select-this-plan-button'}
                              className={classes.enrollButton}
                              data-test={'ma_select_plan_button'}>
              Select this plan
            </SelectPlanButton>
          </div>

        </Box>
      </div>
    </Box>;
  }
}, areComponentPropsEqual);


interface PlanTitleProps {
  plan: Plan,
  showBid?: boolean,
  className?: string,
  inFavorites?: boolean,
  hideFavorites?: boolean,
  favoritesClick?: () => void,
  titleHref?: string,
}


function Func({plan, className, showBid, inFavorites, favoritesClick, hideFavorites, titleHref}: PlanTitleProps) {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  const Desktop = () => <Box sx={{mt: '14px'}} flex={1} className={clsx('flex', className)}>
    <div className={'flex-column'}>
      <div className={'flex-space-between'}>
        <Rating plan={plan} />
        {!hideFavorites && <div className={'flex flex-align-center pointer'} onClick={favoritesClick}>
          {!inFavorites && <>
            <Typography className={'fs-11 medium no-wrap'} color={'textPrimary'}>Add to favorites</Typography>
            <div className={'w-5'} />
            <img width={20} height={20} src={'/plan-finder/assets/img/heart.svg'}/>
          </>}
          {inFavorites && <>
            <Typography className={'fs-11 medium no-wrap'} color={'textPrimary'}>Added to favorites</Typography>
            <div className={'w-5'} />
            <img width={20} height={20} src={'/plan-finder/assets/img/heart-fill.svg'}/>
          </>}
        </div>}
      </div>
      <div className={'flex-space-between'}>
        <Box sx={{'& *': {textDecoration: 'none'}}} className={'mt-4'}>
          <Link to={titleHref || '#'} className={'fs-18 lh-24 black semi-bold pointer'}>{plan.planName}</Link>
        </Box>
        <div>
          <CompanyLogo url={getCompanyLogo(plan.parentOrgName, plan.orgName)} sx={{mt: .5}} />
        </div>
      </div>
    </div>
  </Box>

  const Mobile = () => <div className={'flex-column'}>
    <Box sx={{display: 'flex', justifyContent: 'space-between', flexDirection: {xs: 'column', sm: 'row'}, gap: 1, mb: 1}}>
      <CompanyLogo url={getCompanyLogo(plan.parentOrgName, plan.orgName)} />
      <div className={'flex-column'}>
        {!hideFavorites && <div className={'flex flex-align-center pointer'} onClick={favoritesClick}>
          {!inFavorites && <>
            <Typography className={'fs-11 medium no-wrap'}>Add to favorites</Typography>
            <div className={'w-5'} />
            <img width={20} height={20} src={'/plan-finder/assets/img/heart.svg'}/>
          </>}
          {inFavorites && <>
            <Typography className={'fs-11 medium no-wrap'}>Added to favorites</Typography>
            <div className={'w-5'} />
            <img width={20} height={20} src={'/plan-finder/assets/img/heart-fill.svg'}/>
          </>}
        </div>}
        <Rating plan={plan} />
      </div>
    </Box>
    <div className={'flex-space-between'}>
      <Box sx={{'& *': {textDecoration: 'none'}}} display={'flex'} flex={1} className={'mt-8'}>
        <Link to={titleHref || '#'} className={'fs-20 lh-25 text-primary bold pointer'}>{plan.planName}</Link>
      </Box>
      <div>

      </div>
    </div>
  </div>

  return <div className={clsx('flex', className)}>
    {phone ? <Mobile /> : <Desktop/>}
  </div>
}

const PlanTitle = React.memo(Func, areComponentPropsEqual)


export type ListPlanDetailProps = {
  title: string,
  titleClassName?: string,
  imgClassName?: string,
  tooltip?: string,
  content: (string | JSX.Element)[],
  dividerSx?: SxProps,
  thin?: boolean,
  hideDivider?: boolean,
  sx?: SxProps,
}

export function ListPlanDetail({title, content, thin, tooltip, dividerSx, hideDivider, titleClassName, imgClassName, sx}: ListPlanDetailProps) {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, my: {md: "10px"}, alignItems: "flex-start", ...sx}}>
        <Box sx={{minWidth: {md: '50%'}, width: {md: '50%'}}}>
          {!tooltip && <Typography variant={"h5"} color={'textPrimary'}>
            {!thin && <strong>{title}</strong>}
            {!!thin && title}
          </Typography>}
          {tooltip && <div className={`flex`}>
            <CRTooltip placement={'top'} arrow title={tooltip}>
              <div className={'flex flex-align-start'}>
                <img className={`mt-5 mr-8 ${imgClassName || ''}`} src={'/plan-finder/assets/img/info-gray.svg'}></img>
                <Typography sx={{
                  fontSize: '16px',
                  mt: '4px',
                  mr: '8px',
                  fontWeight: '700',
                }} className={`${!thin ? 'bold' : 'regular'} ${titleClassName || ''}`} >{title}</Typography>
              </div>
            </CRTooltip>
          </div>}
        </Box>
        <Box display={"flex"} mt={{xs: '5px', md: 0}}>
          <ListPlanDetailText content={content} className={phone ? 'ml-24' : undefined} />
        </Box>
      </Box>
      {!hideDivider && <Divider sx={{my: {xs: 2, md: 3}, ...dividerSx}}/>}
    </>
  )
}

export const ListPlanDetailText = ({content, className}: { content: (string | JSX.Element)[], className?: string }) => (
  <Box display={"flex"} flexGrow={2} flexDirection={"column"} className={className}>
    {( !content || content.length == 0 ) &&
      <Typography variant={"h5"} color={'textPrimary'}>Not covered</Typography>
    }
    {(!!content && content.length > 0 ) && content.map((line, i) =>
      <Box key={i} minHeight={'18px'}>
        <Typography className={'fs-12 lh-20'} color={'textPrimary'}>
          {typeof line === 'string' && <span dangerouslySetInnerHTML={{__html: convertList(convertBold(line))}}></span>}
          {typeof line !== 'string' && <>{line}</>}
        </Typography>
      </Box>
    )}
  </Box>
)


export type SimplePlanDetailProps = {
  title: string | JSX.Element,
  tooltip?: string,
  content?: string | null | JSX.Element,
  emptyString?: string,
  hideDivider?: boolean,
  thin?: boolean,
  dividerSx?: SxProps,
  sx?: SxProps,
  titleClassName?: string,
  imgClassName?: string,
}

function PlanDetail({title, content, tooltip, emptyString, thin, hideDivider, dividerSx, titleClassName, imgClassName, sx}: SimplePlanDetailProps) {
  return (
    <>
      <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, alignItems: "flex-start", ...sx}}>
        <Box sx={{minWidth: {md: '50%'}, width: {md: '50%'}}}>
          {!tooltip && <Typography sx={{
            fontSize: '16px',
            mt: '4px',
            mr: '8px',
          }} className={`${!thin ? 'bold' : 'regular'} ${titleClassName || ''}`} >{title}</Typography>}
          {tooltip && <div className={`flex`}>
            <CRTooltip placement={'top'} arrow title={tooltip}>
              <div className={'flex flex-align-start'}>
                <img className={`mt-5 mr-8 ${imgClassName || ''}`} src={'/plan-finder/assets/img/info-gray.svg'}></img>
                <Typography sx={{
                  fontSize: '16px',
                  mt: '4px',
                  mr: '8px',
                  fontWeight: '700',
                }} className={`${!thin ? 'bold' : 'regular'} ${titleClassName || ''}`} >{title}</Typography>
              </div>
            </CRTooltip>
          </div>}
        </Box>
        <Box display={"flex"} mt={{xs: '5px', md: 0}}>
          <Box display={"flex"} flexGrow={2} ml={{xs: 3, md: 0}}>
            {typeof content !== "string" && content}
            {typeof content === "string" &&
              <Typography className={'fs-12 lh-20'} color={"textPrimary"}>{content || emptyString}</Typography>}
          </Box>
        </Box>
      </Box>
      {!hideDivider && <Divider sx={{my: {xs: 2, md: 3}, ...dividerSx}}/>}
    </>
  )
}

const SimplePlanDetail = React.memo(PlanDetail);

const Rating = ({plan}: {plan?: Plan}) => {
  const planYear = React.useMemo(() => {
    if (plan?.planYear) {
      return plan.planYear.toString().replace('Year', '')
    }
    return GetPlansYear().toString().replace('Year', '');
  }, [plan])

  return <StarRating plan={plan}
                     labelClassName={'fs-12'}
                     endAdornment={<Box className={'flex'} sx={{gap: 1}}>
                       {!!plan?.rating && <Typography sx={{ml: .5, mt: .25}} className={'fs-14 lh-14'}>({planYear})</Typography>}
                       <img className={`w-16 h-16`} src={'/plan-finder/assets/img/info.svg'} />
                     </Box>}
                     size={18} />
}

