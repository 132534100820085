import { createApi } from '@reduxjs/toolkit/query/react'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import {
  BindChatIdentityIdDocument,
  BindChatIdentityIdMutation, BindChatIdentityIdMutationVariables,
  ChatAccessTokenDocument,
  ChatAccessTokenQuery, ChatAccessTokenQueryVariables,
  ChatIdentityIdDocument,
  ChatIdentityIdQuery,
  ChatIdentityIdQueryVariables
} from "./chat-rtk";
import { v4 } from 'uuid';
import {CHAT_TAGS} from "./tags";
import {getToken} from "@coverright/shared/keycloak";


// Define a service using a base URL and expected endpoints
export const api = createApi({
  reducerPath: 'chatApi',
  tagTypes: Object.values(CHAT_TAGS),
  baseQuery: graphqlRequestBaseQuery({
    url: process.env.NX_CHAT_GRAPHQL as string,
    prepareHeaders: async headers => {
      const token = await getToken().catch(() => {});

      return {
        authorization: token ? `Bearer ${token}` : "",
        ...headers
      }
    }
  }),
  endpoints: (build) => ({
    chatIdentityId: build.query<ChatIdentityIdQuery, ChatIdentityIdQueryVariables | void>({
      query: (variables) => ({ document: ChatIdentityIdDocument, variables }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (!data.chatIdentityId) {
            dispatch(api.endpoints.bindChatIdentityId.initiate({chatIdentityId: v4()}))
          } else {
            dispatch(api.endpoints.chatAccessToken.initiate({chatIdentityId: data.chatIdentityId}))
          }
        } catch (err) {
          throw Error('patchClientPersonalInfo error')
        }
      },
      providesTags: [CHAT_TAGS.IDENTITY_ID]
    }),
    bindChatIdentityId: build.mutation<BindChatIdentityIdMutation, BindChatIdentityIdMutationVariables>({
      query: (variables) => ({ document: BindChatIdentityIdDocument, variables }),
      invalidatesTags: [CHAT_TAGS.IDENTITY_ID]
    }),
    chatAccessToken: build.query<ChatAccessTokenQuery, ChatAccessTokenQueryVariables>({
      query: (variables) => ({ document: ChatAccessTokenDocument, variables })
    }),
  }),
})
