import {EnrolledPlan} from "@coverright/data-access/medicare";
import React from "react";
import {Collapse} from "@mui/material";
import HasDiscountsAlert from "./has-discounts-alert";
import HasNoDiscountsAlert from "./has-no-discounts-alert";
import useHasDiscounts from "../api/use-has-discounts";

interface Props {
  plan: EnrolledPlan,
  quoteId?: string,
  clientId?: string,
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export function DiscountAlert({plan, clientId, quoteId, dataProvider, onClick}: Props & {
  dataProvider: (plan: EnrolledPlan, quoteId?: string, clientId?: string) => {hasDiscounts?: boolean, loading: boolean}
}) {
  const {hasDiscounts, loading} = dataProvider(plan, quoteId, clientId);

  return <Collapse in={!loading}>
    {hasDiscounts && <HasDiscountsAlert onClick={onClick} />}
    {hasDiscounts === false && <HasNoDiscountsAlert />}
  </Collapse>
}

export default function (props: Props) {
  return <DiscountAlert {...props} dataProvider={useHasDiscounts} />
}
